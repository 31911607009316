import actionTypes from './actionTypes';

export const error = (code, message) => ({
  type: actionTypes.ERROR,
  payload: {
    code,
    message
  }
});

export const appFetch = () => ({
  type: actionTypes.APP_FETCH
});

export const appFetchSuccess = () => ({
  type: actionTypes.APP_FETCH_SUCCESS
});

export const appReset = () => ({
  type: actionTypes.APP_RESET
});

export const appValidDataAndPublish = () => ({
  type: actionTypes.APP_VALID_DATA_AND_PUBLISH
});

export const appValidData = () => ({
  type: actionTypes.APP_VALID_DATA
});

export const appValidDataFinished = () => ({
  type: actionTypes.APP_VALID_DATA_FINISHED
});

export const appTrackLocation = location => ({
  type: actionTypes.APP_TRACK_LOCATION,
  payload: {
    location
  }
});

export const appAction = (actionName, key, params = {}) => ({
  type: actionTypes.APP_ACTION,
  payload: {
    actionName,
    key,
    params
  }
});

export const appActionCompleted = (actionName, key) => ({
  type: actionTypes.APP_ACTION_COMPLETED,
  payload: {
    actionName,
    key
  }
});

export const appSetAnalytics = (value) => ({
  type: actionTypes.APP_SET_ANALYTICS,
  payload: {
    value
  }
})
