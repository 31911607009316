import {createSelector} from 'reselect';

import {selectAuthUser} from '../../../_store/auth/selectors';

const selectUserFormPageLoaded = state => state.getIn(["scenes", "userForm", "pageLoaded"]);
const selectUserFormIsSubmitting = state => state.getIn(["scenes", "userForm", "isSubmitting"]);
const selectUserFormIsSubmitted = state => state.getIn(["scenes", "userForm", "isSubmitted"]);
const selectUserFormUser = state => state.getIn(["scenes", "userForm", "resources", "users", "member"]);

export const selectUserForm = createSelector([
  selectUserFormPageLoaded,
  selectUserFormIsSubmitting,
  selectUserFormIsSubmitted,
  selectUserFormUser,
  selectAuthUser
], (pageLoaded, isSubmitting, isSubmitted, user, connectedUser) => ({
  pageLoaded,
  isSubmitting,
  isSubmitted,
  user,
  connectedUser
}));