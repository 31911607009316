import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import WebIcon from '@mui/icons-material/Web';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {appAction} from '../../../_store/app/actions';
import {selectAppAction} from '../../../_store/app/selectors';
import {getFormattedDate} from '../../../_helpers/date';
import {API_MANAGER} from '../../../_constants/api';
import {selectAuthUser} from '../../../_store/auth/selectors';
import ServiceWithMenu from './ServiceWithMenu';
import useAdminRedirect from '../../../_hooks/useAdminRedirect';
import useVisualizationRedirect from '../../../_hooks/useVisualizationRedirect';

const ordering = {};
const sortOrder = ["Application Baliz", "Application Funbox", "Gestionnaire de données (DATA)"];
for (let i = 0; i < sortOrder.length; i++) {
  ordering[sortOrder[i]] = i;
}

const getCardColors = articleId => {
  switch (articleId) {
    case 5:
      return {
        backgroundColor: "#b5c6d5",
        color: "rgb(33, 52, 70)"
      }
    case 6:
      return {
        backgroundColor: "#c0e1fa",
        color: "rgb(33, 150, 243)"
      }
    case 7:
      return {
        backgroundColor: "#bbcfcb",
        color: "rgb(20, 97, 82)"
      }
    default:
      return {
        backgroundColor: "#ededed"
      }
  }
}

const getCardHoverColor = articleId => {
  switch (articleId) {
    case 5:
      return {
        backgroundColor: "#a6b5c1"
      }
    case 6:
      return {
        backgroundColor: "#99d3ff"
      }
    case 7:
      return {
        backgroundColor: "#abbfbb"
      }
    default:
      return {
        backgroundColor: "#e3e3e3"
      }
  }
}

function ServiceCard({service}) {
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const handleAdminRedirect = useAdminRedirect();
  const handleVisualizationRedirect = useVisualizationRedirect();
  const [anchorEl, setAnchorEl] = useState(null);

  const disabled = useMemo(() => {
    let disabled = {
      view: false,
      edit: false
    };
    switch (service.getIn(["article", "id"])) {
      case 5:
        disabled = {
          view: !user.getIn(["rights", "baliz", "view"]),
          edit: !user.getIn(["rights", "baliz", "edit"])
        }
        break;
      case 6:
        disabled = {
          view: !user.getIn(["rights", "funbox", "view"]),
          edit: !user.getIn(["rights", "funbox", "edit"])
        }
        break;
      case 7:
        disabled = {
          view: !user.getIn(["rights", "data", "view"]),
          edit: !user.getIn(["rights", "data", "edit"])
        }
        break;
      default:
    }
    return disabled;
  }, [service, user]);

  return (
    <Grid item key={service.get("@id")} xs={12} sm={6} md={4} lg={2} sx={{display: "flex"}}>
      <Card
        sx={{
          ...getCardColors(service.getIn(["article", "id"])),
          display: "flex",
          flexDirection: "column",
          flex: "1 1"
        }}
      >
        <CardActionArea
          sx={{flex: "0 0", ":hover": {
            ...getCardHoverColor(service.getIn(["article", "id"]))
          }}}
          disableRipple
          onClick={() => navigate(`/services/${service.get("id")}`)}
          disabled={disabled.view}
        >
          <CardContent sx={{pb: 1}}>
            <Typography variant="h6" component="div" sx={{textDecoration: "underline"}}>
              <span>{service.get("name")}</span>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardContent sx={{flex: "1 1", py: 1}}>
          <Typography variant="p" component="div" sx={{fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)"}}>
            {`Nb. d'équipements associés : ${service.get("products").size}`}
          </Typography>
        </CardContent>
        <CardActions sx={{flex: "0 0"}}>
          <Grid container>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Back-office" arrow enterDelay={500} disableInteractive>
                <span>
                  <Button
                    size="small"
                    disableRipple
                    sx={{
                      backgroundColor: "#fdd835",
                      color: "rgba(0, 0, 0, 0.75)",
                      ":hover": {backgroundColor: "#fbc02d"},
                      ":disabled": {backgroundColor: "rgba(0, 0, 0, 0.12)"}
                    }}
                    disabled={![5, 6, 7, 14].includes(service.getIn(["article", "id"]))}
                    onClick={() => handleAdminRedirect(service)}
                  >
                    <WebIcon fontSize="small"/>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Visualiser" arrow enterDelay={500} disableInteractive>
                <span>
                  <Button
                    id={`service-${service.get("id")}-button`}
                    aria-controls={Boolean(anchorEl) ? `service-${service.get("id")}-menu` : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    size="small"
                    disableRipple
                    sx={{
                      backgroundColor: "#3f51b5",
                      color: "#fff",
                      transition: "none",
                      ":hover": {backgroundColor: "#2d3a81"},
                      ":disabled": {backgroundColor: "rgba(0, 0, 0, 0.12)"}
                    }}
                    onClick={e => {
                      if (service.getIn(["article", "id"]) === 8) {
                        navigate("/statistiques");
                      } else if (service.get("products").size > 1) {
                        setAnchorEl(e.currentTarget);
                      } else {
                        setAnchorEl(null);
                        setTimeout(() => handleVisualizationRedirect(service.getIn(["products", 0]), service), 200);
                      }
                    }}
                    disabled={![5, 6, 8].includes(service.getIn(["article", "id"])) || (service.get("products").size === 0 && service.getIn(["article", "id"]) !== 8)}
                  >
                    <RemoveRedEyeOutlinedIcon fontSize="small"/>
                  </Button>
                </span>
              </Tooltip>
              {service.get("products").size > 1 ? (
                <Menu
                  id={`service-${service.get("id")}-menu`}
                  aria-labelledby={`service-${service.get("id")}-button`}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  TransitionComponent={Fade}
                  sx={{
                    "& ul": {
                      padding: 0
                    }
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <Box sx={{
                    color: theme => theme.palette.primary.main,
                    padding: "6px 16px",
                    fontSize: "0.875rem",
                    fontWeight: "bold"
                  }}>
                    Produits
                  </Box>
                  {service.get("products").map((product, index) => {
                    return (
                      <MenuItem
                        key={`product-${index}`}
                        sx={{fontSize: "0.875rem"}}
                        onClick={() => {
                          setAnchorEl(null);
                          setTimeout(() => handleVisualizationRedirect(product, service), 200);
                        }}
                        disableRipple
                      >
                        {product.get("name")}
                      </MenuItem>
                    )
                  })}
                </Menu>
              ) : null}
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Fiche" arrow enterDelay={500} disableInteractive>
                <span>
                  <Button
                    size="small"
                    disableRipple
                    sx={{
                      backgroundColor: "#0288d1",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "#01579b"
                      },
                      ":disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)"
                      }
                    }}
                    onClick={() => navigate(`/services/${service.get("id")}`)}
                    disabled={disabled.view}
                  >
                    <ArticleOutlinedIcon fontSize="small"/>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Éditer" arrow enterDelay={500} disableInteractive>
                <span>
                  <Button
                    size="small"
                    disableRipple
                    sx={{
                      backgroundColor: "#ffa000",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "#ff8f00"
                      },
                      ":disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)"
                      }
                    }}
                    onClick={() => navigate(`/services/edition/${service.get("id")}`)}
                    disabled={disabled.edit}
                  >
                    <EditOutlinedIcon fontSize="small"/>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default ServiceCard;