import {object, string} from 'yup';

const validationSchema = () => {
  return (
    object().shape({
      fullName: string().required("Une dénomination est obligatoire"),
      email: string().email("Le format de l'adresse e-mail est incorrect").required("Une adresse e-mail est obligatoire")
    })
  )
};

export default validationSchema;