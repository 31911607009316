import actionTypes from './actionTypes';

export const productViewFetch = id => ({
  type: actionTypes.PRODUCT_VIEW_FETCH,
  payload: {
    id
  }
});

export const productViewFetchSuccess = () => ({
  type: actionTypes.PRODUCT_VIEW_FETCH_SUCCESS
});

export const productViewReset = () => ({
  type: actionTypes.PRODUCT_VIEW_RESET
});

export const productViewSetFilters = (name, filters) => ({
  type: actionTypes.PRODUCT_VIEW_SET_FILTERS,
  payload: {
    name,
    filters
  }
});

export const productViewFetchConnection = (id, startDate, endDate) => ({
  type: actionTypes.PRODUCT_VIEW_FETCH_CONNECTION,
  payload: {
    id,
    startDate,
    endDate
  }
});

export const productViewFetchConnectionSuccess = (id, startDate, endDate) => ({
  type: actionTypes.PRODUCT_VIEW_FETCH_CONNECTION_SUCCESS
});

export const productViewUpdateServices = params => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_SERVICES,
  payload: {
    params
  }
});

export const productViewUpdateServicesSuccess = payload => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_SERVICES_SUCCESS,
  payload
});

export const productViewUpdateTickets = params => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_TICKETS,
  payload: {
    params
  }
});

export const productViewUpdateTicketsSuccess = payload => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_TICKETS_SUCCESS,
  payload
});

export const productViewResetLogs = () => ({
  type: actionTypes.PRODUCT_VIEW_RESET_LOGS
});

export const productViewUpdateLogs = (filters, start, end) => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_LOGS,
  payload: {
    filters,
    start,
    end
  }
});

export const productViewUpdateLogsSuccess = () => ({
  type: actionTypes.PRODUCT_VIEW_UPDATE_LOGS_SUCCESS
});