const getCookie = cname => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

const setCookie = (cname, cvalue, date = null) => {
  const existingCookie = getCookie(cname);
  if (existingCookie) {
    removeCookie(cname);
  }
  let d = new Date();
  if (date) {
    d = new Date(date * 1000);
  } else {
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
  }
  let domain = window.location.hostname;
  const expires = "expires=" + d.toString() + ";";
  if (domain === "localhost" || isIPAddress(domain)) {
    document.cookie = `${cname}=${cvalue};${expires}path=/;SameSite=Lax;`;
  } else {
    domain = "domain=" + process.env.REACT_APP_DOMAIN + ";";
    document.cookie = `${cname}=${cvalue};${expires}${domain}path=/;SameSite=Lax;`;
  }
  return getCookie(cname);
};

const removeCookie = cname => {
  let domain = window.location.hostname;
  if (domain === "localhost" || isIPAddress(domain)) {
    document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Lax;`;
  } else {
    domain = "domain=" + process.env.REACT_APP_DOMAIN + ";";
    document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;${domain}path=/;SameSite=Lax;`;
  }
};

const isIPAddress = domain => {
  return /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/.test(domain);
};

export {getCookie, setCookie, removeCookie};
