import {takeLatest, put, call, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError, handleSuccess} from '../../../_helpers/api';
import {deleteItem, getAllItems} from '../../../_store/resources/sagas';
import {
  rightsGroupListDeleteRightsGroupSuccess,
  rightsGroupListFetchSuccess, rightsGroupListSetFilters,
  rightsGroupListUpdateSuccess
} from './actions';

const emitter = "RIGHTS_GROUP_LIST";

function* rightsGroupListFetch() {
  try {
    const user = yield select(state => state.getIn(["auth", "user"]));
    const filters = yield select(state => state.getIn(["scenes", "rightsGroupList", "filters"]));
    yield put(rightsGroupListSetFilters({
      ...filters.toJS(),
      organization: user.get("organization")
    }))
    yield put(rightsGroupListFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des groupes de droits");
  }
}

function* rightsGroupListUpdate(action) {
  try {
    const {params} = action.payload;

    yield call(getAllItems, {
      payload: {
        name: "groups",
        params,
        emitter,
        apiName: "auth"
      }
    });

    yield put(rightsGroupListUpdateSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des groupes de droits");
  }
}

function* rightsGroupListDelete(action) {
  try {
    const {payload} = action;

    yield call(deleteItem, {
      payload: {
        name: "groups",
        uri: payload.iri,
        emitter,
        apiName: "auth"
      }
    });

    yield call(handleSuccess, "Le groupe de droits a été supprimé avec succès");
    const filters = yield select(state => state.getIn(["scenes", "rightsGroupList", "filters"]));
    yield call(rightsGroupListUpdate, {
      payload: {
        params: filters.toJS()
      }
    });
    yield put(rightsGroupListDeleteRightsGroupSuccess());
  } catch (e) {
    yield call(handleError, 500, "Une erreur est survenue lors de la suppression du groupe de droits", true);
  }
}

export function* watchRightsGroupList() {
  yield takeLatest(actionTypes.RIGHTS_GROUP_LIST_FETCH, rightsGroupListFetch);
  yield takeLatest(actionTypes.RIGHTS_GROUP_LIST_UPDATE, rightsGroupListUpdate);
  yield takeLatest(actionTypes.RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP, rightsGroupListDelete);
}

export default watchRightsGroupList;
