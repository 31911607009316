import React from 'react';
import TextField from '@mui/material/TextField';
import {getIn} from 'formik';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

const TextType = ({field, form, icon = null, ...rest}) => {
  const error = getIn(form.errors, field.name) || false;
  const touched = getIn(form.touched, field.name) || false;

  return (
    <FormControl sx={{display: "flex", flexGrow: 1}}>
      <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
        {icon}
        <TextField
          id={field.name}
          sx={{width: "100%"}}
          error={error && touched}
          helperText={(error && touched && form.submitCount > 0) ? error : null}
          fullWidth
          size="small"
          // InputLabelProps={{
          //   shrink: true
          // }}
          {...field}
          {...rest}
        />
      </Box>
    </FormControl>

  )
};

export default TextType;
