import {object, ref, string} from 'yup';

const validationSchema = () => {
  return (
    object().shape({
      password: string().required("Saisissez un mot de passe").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!$\[\](){},?~@#\-_^*%/.+:;=])(?=.{8,})/,
        "Doit contenir minimum 8 caractères et au moins une majuscule, une minuscule, un chiffre et un caractère spécial"
      ),
      confirmPassword: string()
        .oneOf([ref("password"), null], "Les 2 mots de passe doivent être identiques")
    })
  )
};

export default validationSchema;