export const camelToSnake = str => {
  return str.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
    return "_" + y.toLowerCase();
  }).replace(/^_/, "")
};

export const pascalCase = str => {
  return str.trim().replace(/(\w)(\S*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
}
export const stringToSnake = str => {
  str = removeAccents(str);
  return str.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").toLowerCase().split(' ').join('_')
};
export const snakeToCamel = (str) => str.replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase()
    .replace('_', '')
);
export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

export const removeAccents = (str) => {
  const strAccents = str.split('');
  let strAccentsOut = [];
  const strAccentsLen = strAccents.length;
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else
      strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join('');
  return strAccentsOut;
};

export const getSceneNameFromEmitter = (emitter) => {
  const splittedEmitter = emitter.split("_");
  for (let i = 0; i < splittedEmitter.length; i++) {
    const lower = splittedEmitter[i].toLowerCase();
    if (i !== 0) {
      splittedEmitter[i] = lower[0].toUpperCase() + lower.substr(1);
    } else {
      splittedEmitter[i] = lower;
    }
  }
  return splittedEmitter.join("");
}

export const formattedAddress = (address) => {
  let formattedAddress = address.get("address1");
  formattedAddress += address.get("zip") ? `, ${address.get("zip")}` : "";
  formattedAddress += address.get("city") ? ` ${address.get("city").toUpperCase()}` : "";
  formattedAddress += address.get("country") ? ` ${address.get("country").toUpperCase()}` : "";
  return formattedAddress;
}
