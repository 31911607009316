import actionTypes from './actionTypes';

export const rightsGroupListFetch = () => ({
  type: actionTypes.RIGHTS_GROUP_LIST_FETCH
});

export const rightsGroupListFetchSuccess = () => ({
  type: actionTypes.RIGHTS_GROUP_LIST_FETCH_SUCCESS
});

export const rightsGroupListReset = () => ({
  type: actionTypes.RIGHTS_GROUP_LIST_RESET
});

export const rightsGroupListUpdate = params => ({
  type: actionTypes.RIGHTS_GROUP_LIST_UPDATE,
  payload: {
    params
  }
});

export const rightsGroupListUpdateSuccess = payload => ({
  type: actionTypes.RIGHTS_GROUP_LIST_UPDATE_SUCCESS,
  payload
});

export const rightsGroupListSetFilters = filters => ({
  type: actionTypes.RIGHTS_GROUP_LIST_SET_FILTERS,
  payload: {
    filters
  }
});

export const rightsGroupListDeleteRightsGroup = iri => ({
  type: actionTypes.RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP,
  payload: {
    iri
  }
});

export const rightsGroupListDeleteRightsGroupSuccess = () => ({
  type: actionTypes.RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP_SUCCESS
});