import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function FormSection({children}) {
  return (
    <Grid item xs={12}>
      <Box sx={{
        paddingBottom: 1,
        paddingTop: 1,
        paddingLeft: 1,
        marginTop: 2,
        background: "#f2f2f2",
        fontWeight: 700,
        borderRadius: "4px"
      }}>
        {children}
      </Box>
    </Grid>
  );
}

export default FormSection;