import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {fromJS} from 'immutable';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import {serviceViewFetch, serviceViewReset} from './_store/actions';
import {
  StyledServiceEditButton,
  StyledServiceHeader,
  StyledServiceImg,
  StyledServiceInfos,
  StyledStatus
} from './styled';
import {GStyledSectionTitle} from '../../styled';
import Loading from '../../_components/Loading/Loading';
import {getFormattedDate} from '../../_helpers/date';
import TableContainer from '../../_components/TableContainer/TableContainer';
import Products from './_components/Products';
import useCheckId from '../../_hooks/useCheckId';
import useAdminRedirect from '../../_hooks/useAdminRedirect';
import {appAction} from '../../_store/app/actions';
import {selectServiceView} from './_store/selectors';
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "@mui/material/Tooltip";
import {selectAppAction} from '../../_store/app/selectors';
import {selectAuthUser} from '../../_store/auth/selectors';

const StyledTabs = styled(Tabs)({
  backgroundColor: "#f3f0f9",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  '& .MuiTabs-indicator': {
    height: "3px"
  }
});

const StyledTab = styled(props => <Tab disableRipple {...props}/>)({
  color: "rgba(0, 0, 0, 0.87)",
  textTransform: "none",
  fontSize: "1rem"
});

function ServiceView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: paramsId} = useParams();
  const id = useCheckId(paramsId);
  const handleAdminRedirect = useAdminRedirect();

  const {pageLoaded, service, tickets} = useSelector(selectServiceView);
  const user = useSelector(selectAuthUser);
  const publishServiceOnNetwork = useSelector(state => selectAppAction(state, service ? ["publish_service_on_network", service.get("reference")] : null));
  const syncStats = useSelector(state => selectAppAction(state, service ? ["sync_stats", service.get("reference")] : null));
  const syncEMails = useSelector(state => selectAppAction(state, service ? ["sync_e-mails", service.get("reference")] : null));
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (id) {
      dispatch(serviceViewFetch(id));
    }
    return () => dispatch(serviceViewReset());
  }, [id]);

  const editDisabled = useMemo(() => {
    let disabled = false;
    if (service) {
      switch (service.getIn(["article", "id"])) {
        case 5:
          disabled = !user.getIn(["rights", "baliz", "edit"]);
          break;
        case 6:
          disabled = !user.getIn(["rights", "funbox", "edit"]);
          break;
        case 7:
          disabled = !user.getIn(["rights", "data", "edit"]);
          break;
        default:
      }
    }
    return disabled;
  }, [service, user]);

  // const tickets = fromJS({
  //   members: [
  //     {
  //       id: 1,
  //       "@id": "/tickets/1",
  //       name: "Pas de statistiques",
  //       author: "Doe John",
  //       ref: "#0789",
  //       status: "En cours",
  //       category: "Logiciel",
  //       date: "2023-10-26T09:45:59+00:00",
  //       lastActivity: "2023-10-26T16:12:00+00:00",
  //       nbComments: 8
  //     },
  //     {
  //       id: 2,
  //       "@id": "/tickets/2",
  //       name: "Problème de connexion",
  //       author: "Doe John",
  //       ref: "#0622",
  //       status: "Fermé",
  //       category: "Réseau",
  //       date: "2023-10-02T14:17:00+00:00",
  //       lastActivity: "2023-10-07T11:02:00+00:00",
  //       nbComments: 2
  //     }
  //   ],
  //   count: 2,
  //   indexByUri: {
  //     "/tickets/1": 0,
  //     "/tickets/2": 1
  //   },
  //   indexes: {},
  //   pageCount: 1,
  //   request: ""
  // });

  const columns = useMemo(() => {
    return [
      // {
      //   Header: "Référence",
      //   accessor: "ref",
      //   filterable: true,
      //   sortable: true,
      //   sortableName: "ref",
      //   fixWidth: 300,
      //   Cell: cell => cell.value
      // },
      {
        Header: "Statut",
        accessor: "status",
        filterable: true,
        sortable: true,
        sortableName: "status",
        Cell: cell => (
          <StyledStatus
            bgColor={cell.value === "En cours" ? "#3f51b5" : "#d9d9d9"}
            color={cell.value === "En cours" ? "#fff" : "rgba(0, 0, 0, 0.87)"}
          >
            {cell.value}
          </StyledStatus>
        )
      },
      {
        Header: "Titre",
        accessor: "name",
        filterable: true,
        sortable: true,
        sortableName: "name",
        Cell: cell => (
          <Link
            component={RouterLink}
            to={`/tickets/${cell.row.original.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            {cell.value}
          </Link>
        )
      },
      {
        Header: "Dernière activité",
        accessor: "lastActivity",
        filterable: true,
        sortable: true,
        sortableName: "lastActivity",
        Cell: cell => getFormattedDate(cell.value, false)
      },
      {
        Header: "Auteur",
        accessor: "author",
        filterable: true,
        sortable: true,
        sortableName: "author",
        Cell: cell => cell.value
      },
      {
        Header: "Catégorie",
        accessor: "category",
        filterable: true,
        sortable: true,
        sortableName: "category",
        Cell: cell => cell.value
      },
      {
        Header: "Nombre de commentaires",
        accessor: "nbComments",
        filterable: true,
        sortable: true,
        sortableName: "nbComments",
        Cell: cell => cell.value
      }
    ];
  }, [pageLoaded]);

  const filters = fromJS({
    "page": 1,
    "itemsPerPage": 20,
    "order[updatedAt]": "desc"
  });
  const tableLoading = false;

  const fetchData = useCallback(params => {
  }, [dispatch]);

  return (
    <>
      <Loading isLoading={!pageLoaded} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {pageLoaded && (
        <Grid container>
          <Grid item xs={12} style={{height: "3rem"}}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
            >
              <Typography
                key="2"
                color="inherit"
                sx={{color: "#1976d2", "&:hover": {cursor: "pointer", textDecoration: "underline"}}}
                onClick={() => navigate("/services")}
              >
                Services
              </Typography>
              <Typography key="3" color="text.primary">
                {service.get("name")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <StyledServiceHeader>
                  <StyledServiceImg path={service.get("featuredImage") ? service.getIn(["featuredImage", "publicPath"]) : null}/>
                  <StyledServiceInfos>
                    <div style={{fontSize: "1.4rem", color: "#673ab7", fontWeight: "bold"}}>
                      {service.get("name")}
                    </div>
                    <div><span style={{fontWeight: "bold"}}>Type : </span>{service.getIn(["article", "name"])}</div>
                    <div><span style={{fontWeight: "bold"}}>Référence : </span>{service.get("reference")}</div>
                  </StyledServiceInfos>
                  <StyledServiceEditButton>
                    <Tooltip title="Éditer" arrow>
                      <IconButton
                        aria-label="edit"
                        onClick={() => navigate(`/services/edition/${id}`)}
                        disabled={editDisabled}
                        sx={{
                          color: "#ffa000",
                          backgroundColor: "rgba(0, 0, 0, 0.04) !important"
                        }}
                      >
                        <EditOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </StyledServiceEditButton>
                </StyledServiceHeader>
                <StyledTabs
                  value={tab}
                  onChange={(e, newTab) => setTab(newTab)}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="service tabs"
                  sx={{marginBottom: "2rem"}}
                >
                  <StyledTab label="Général"/>
                  {/*<StyledTab*/}
                  {/*  label={*/}
                  {/*    <>*/}
                  {/*      Tickets*/}
                  {/*      {tickets.get("count") > 0 && (*/}
                  {/*        <span style={{*/}
                  {/*          position: "absolute",*/}
                  {/*          height: "20px",*/}
                  {/*          width: "20px",*/}
                  {/*          backgroundColor: "#663ab6",*/}
                  {/*          borderRadius: "50%",*/}
                  {/*          color: "#fff",*/}
                  {/*          fontSize: "0.75rem",*/}
                  {/*          right: "0",*/}
                  {/*          top: "0",*/}
                  {/*          display: "flex",*/}
                  {/*          alignItems: "center",*/}
                  {/*          justifyContent: "center"*/}
                  {/*        }}>*/}
                  {/*          {tickets.get("count")}*/}
                  {/*        </span>*/}
                  {/*      )}*/}
                  {/*    </>*/}
                  {/*  }*/}
                  {/*  sx={{*/}
                  {/*    position: "relative"*/}
                  {/*  }}*/}
                  {/*/>*/}
                </StyledTabs>
                {tab === 0 && (
                  <>
                    <Grid item xs={12} sx={{mb: 4}}>
                      {![8, 15].includes(service.getIn(["article", "id"])) ? (
                        <Button
                          variant="contained"
                          color="action"
                          disableRipple
                          sx={{mr: 2}}
                          onClick={() => handleAdminRedirect(service)}
                          disabled={editDisabled}
                        >
                          <div style={{marginTop: "1px", marginBottom: "-1px"}}>Back-office</div>
                        </Button>
                      ) : null}
                      {service.get("products").size > 0 ? (
                        service.getIn(["article", "id"]) === 8 ? (
                          <>
                            <Button
                              variant="contained"
                              color="action"
                              disableRipple
                              disabled={syncStats && syncStats.get("inProgress")}
                              sx={{mr: 2}}
                              onClick={() => dispatch(appAction("sync_stats", service.get("reference"), {
                                service
                              }))}
                            >
                              <div style={{marginTop: "1px", marginBottom: "-1px"}}>
                                Synchroniser les statistiques
                              </div>
                            </Button>
                            <Button
                              variant="contained"
                              color="action"
                              disableRipple
                              disabled={syncEMails && syncEMails.get("inProgress")}
                              onClick={() => dispatch(appAction("sync_e-mails", service.get("reference"), {
                                service
                              }))}
                            >
                              <div style={{marginTop: "1px", marginBottom: "-1px"}}>
                                Synchroniser les e-mails
                              </div>
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            color="action"
                            disableRipple
                            disabled={publishServiceOnNetwork && publishServiceOnNetwork.get("inProgress")}
                            onClick={() => dispatch(appAction("publish_service_on_network", service.get("reference"), {
                              service
                            }))}
                          >
                            <div style={{marginTop: "1px", marginBottom: "-1px"}}>
                              Publication sur le réseau
                            </div>
                          </Button>
                        )
                      ) : null}
                    </Grid>
                    <GStyledSectionTitle>Équipements rattachés</GStyledSectionTitle>
                    <Grid item xs={12} sx={{mb: -2, table: {borderTop: "none"}}}>
                      <Products/>
                    </Grid>
                  </>
                )}
                {tab === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
                      <Button
                        variant="contained"
                        color="add"
                        onClick={() => navigate("/tickets/ajout")}
                      >
                        Nouveau
                      </Button>
                    </Grid>
                    <Grid item xs={12} sx={{marginBottom: -2}}>
                      <TableContainer
                        id="table-container-service-tickets-list"
                        columns={columns}
                        data={tickets ? tickets.get("members").toJS() : []}
                        pageCount={tickets ? tickets.get("pageCount") : 1}
                        count={tickets ? tickets.get("count") : 0}
                        customPageSize={20}
                        fetchData={fetchData}
                        filters={filters.toJS()}
                        loading={tableLoading}
                        defaultSort={{sortDirection: "desc", accessor: "updatedAt"}}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ServiceView;