import React from 'react';
import {isEqual} from 'lodash';
import {getIn, useFormikContext} from 'formik';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {debounce} from '@mui/material/utils';

import usePrevious from '../../../_hooks/usePrevious';
import serviceAuth from '../../../_services/_auth/service';
import serviceManager from '../../../_services/_manager/service';

function AutoComplete({
  name,
  entityName,
  searchField,
  selectLabel,
  selectValue,
  label,
  condition = {},
  required = false,
  apiName,
  ...rest
}) {
  const {setFieldValue, values, errors, touched, setFieldTouched, getFieldProps} = useFormikContext();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const value = getIn(values, name);
  const error = getIn(errors, name) || false;
  const touchedField = getIn(touched, name) || false;
  const previousCondition = usePrevious(condition);
  const previousInputValue = usePrevious(inputValue);
  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        const params = {...request.condition};
        if (request.input) {
          params[searchField] = request.input;
        }
        const api = apiName === "manager" ? serviceManager() : serviceAuth();

        return api[entityName].getAll(params).then(response => {
          return callback(response.members.map((option) => {
            const label = selectLabel.split('.').reduce((o, i) => o[i], option);
            return {
              label,
              value: option[selectValue],
            }
          }));
        });
      }, 400),
    [],
  );
  React.useEffect(() => {
    if (!isEqual(previousCondition, condition)) {
      fetch({input: inputValue, condition}, (results) => {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      });
    }
  }, [inputValue, fetch, condition]);

  React.useEffect(() => {
    let active = true;
    fetch({input: inputValue, condition}, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);
  return (
    <MuiAutocomplete
      {...rest}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Aucune donnée trouvée"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      onChange={(event, newValue) => {
        setFieldValue(name, newValue);
        setInputValue(null);
      }}
      onClose={() => setFieldTouched(name, true)}
      onInputChange={(event, newInputValue) => {
        if (event) {
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} required={required} error={error && touchedField}
                   helperText={error && touchedField ? error?.value || error : null} label={label} fullWidth
                   size={"small"}/>
      )}
      isOptionEqualToValue={(option, value) => {
        return (option && value && option.value === value.value) || value === "";
      }
      }
    />
  );
}

export default AutoComplete;