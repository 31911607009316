export const getFormattedDate = (ISODate, withFirstWord = true, withTime = true) => {
    if(ISODate){
        const date = new Date(ISODate);
        const day = `0${date.getDate()}`.slice(-2);
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const year = `${date.getFullYear()}`;
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${withFirstWord ? 'Le ' : ''}${day}/${month}/${year} ${withTime ? `à ${hours}:${minutes}`: ""}`;
    }
    return "-"
};

export const dateDiff = (date1, date2) => {
    let diff = {}  ;                         // Initialisation du retour
    let tmp = date2 - date1;

    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes

    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes

    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures

    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;

    return diff;
};
