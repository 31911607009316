import actionTypes from './actionTypes';

export const userListFetch = () => ({
  type: actionTypes.USER_LIST_FETCH
});

export const userListFetchSuccess = () => ({
  type: actionTypes.USER_LIST_FETCH_SUCCESS
});

export const userListReset = () => ({
  type: actionTypes.USER_LIST_RESET
});

export const userListUpdate = params => ({
  type: actionTypes.USER_LIST_UPDATE,
  payload: {
    params
  }
});

export const userListUpdateSuccess = payload => ({
  type: actionTypes.USER_LIST_UPDATE_SUCCESS,
  payload
});

export const userListSetFilters = filters => ({
  type: actionTypes.USER_LIST_SET_FILTERS,
  payload: {
    filters
  }
});

export const userListDeleteUser = iri => ({
  type: actionTypes.USER_LIST_DELETE_USER,
  payload: {
    iri
  }
});

export const userListDeleteUserSuccess = () => ({
  type: actionTypes.USER_LIST_DELETE_USER_SUCCESS
});