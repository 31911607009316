import {styled} from '@mui/material/styles';
import {API_MANAGER} from '../../_constants/api';

export const StyledProductHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "1rem 1rem 0 1rem",
  backgroundColor: "#f3f0f9",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px"
});

export const StyledProductImg = styled("div")(({path}) => ({
  flex: "0 0 auto",
  width: path ? "150px" : 0,
  height: "150px",
  borderRadius: "8px",
  border: path ? "3px solid #e1d8f1" : "none",
  marginLeft: path ? 0 : "-1rem",
  backgroundColor: "#e1d8f1",
  backgroundImage: path ? `url(${API_MANAGER}/${path})` : "none",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat"
}));

export const StyledProductInfos = styled("div")({
  flex: "1 1 auto",
  padding: "0 1rem",
  lineHeight: "1.8"
});

export const StyledProductEditButton = styled("div")({
  flex: "0 0 auto",
  alignSelf: "flex-start"
});

export const StyledConnexionContainer = styled("div")({
  position: "relative",
  marginTop: "36px"
});

export const StyledHours = styled("div")({
  position: "absolute",
  left: "65px"
});

export const StyledHour = styled("div")(({left}) => ({
  position: "absolute",
  top: "-8px",
  left: `${left}px`,
  fontSize: "11px",
  fontWeight: 500,
  lineHeight: 1,
  color: "#676767",
  transform: "rotate(300deg)",
  transformOrigin: "top left"
}));

export const StyledDays = styled("div")(({totalDays}) => ({
  position: "relative",
  display: "flex",
  width: "1505px",
  maxHeight: "420px",
  overflow: "auto",
  paddingLeft: "65px",
  height: `${totalDays * 30}px`
}));

export const StyledDay = styled("div")({
  position: "absolute",
  transform: "translateX(calc(-100% - 4px))",
  lineHeight: "30px",
  fontSize: "11px",
  fontWeight: 500,
  color: "#676767"
});

export const StyledConnexion = styled("div")({
  position: "relative",
  backgroundColor: "#e2e2e6",
  width: "100%"
});

export const StyledStatus = styled("span")(({bgColor, color}) => ({
  backgroundColor: bgColor,
  color,
  borderRadius: "4px",
  padding: "2px 4px"
}));