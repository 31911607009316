import React from 'react';

function Error(props) {
  return (
    <div style={{
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "5rem",
      lineHeight: 1.1,
      fontWeight: 700
    }}>
      <span>Erreur</span>
      <span>404</span>
    </div>
  );
}

export default Error;