import {createSelector} from 'reselect';

export const selectProductViewPageLoaded = state => state.getIn(["scenes", "productView", "pageLoaded"]);
export const selectProductViewProduct = state => state.getIn(["scenes", "productView", "resources", "products", "member"]);
export const selectProductViewRangeConnexions = state => state.getIn(["scenes", "productView", "resources", "rangeConnexions"]);
export const selectProductViewTableConnexionLoading = state => state.getIn(["scenes", "productView", "tableConnexionLoading"]);
export const selectProductViewServices = state => state.getIn(["scenes", "productView", "resources", "services"]);
export const selectProductViewTaskQueues = state => state.getIn(["scenes", "productView", "resources", "taskQueues"]);
export const selectProductViewLastTasks = state => state.getIn(["scenes", "productView", "resources", "lastTasks"]);
export const selectProductViewServicesFilters = state => state.getIn(["scenes", "productView", "filters", "services"]);
export const selectProductViewServicesTableLoading = state => state.getIn(["scenes", "productView", "tableLoading", "services"]);
export const selectProductViewLogsInStore = state => state.getIn(["scenes", "productView", "resources", "logs"]);
export const selectProductViewLogsFilters = state => state.getIn(["scenes", "productView", "filters", "logs"]);
export const selectProductViewLogsTableLoading = state => state.getIn(["scenes", "productView", "tableLoading", "logs"]);
export const selectProductViewTicketsInStore = state => state.getIn(["scenes", "productView", "resources", "tickets"]);
export const selectProductViewTicketsFilters = state => state.getIn(["scenes", "productView", "filters", "tickets"]);
export const selectProductViewTicketsTableLoading = state => state.getIn(["scenes", "productView", "tableLoading", "tickets"]);

export const selectProductView = createSelector([
  selectProductViewPageLoaded,
  selectProductViewProduct,
  selectProductViewRangeConnexions,
  selectProductViewTableConnexionLoading,
  selectProductViewServices,
  selectProductViewTaskQueues,
  selectProductViewLastTasks,
  selectProductViewServicesFilters,
  selectProductViewServicesTableLoading
], (pageLoaded, product, rangeConnexions, tableConnexionLoading, services, taskQueues, lastTasks, filters, tableLoading) => ({
  pageLoaded,
  product,
  rangeConnexions,
  tableConnexionLoading,
  services,
  taskQueues,
  lastTasks,
  filters,
  tableLoading
}));

export const selectProductViewLogs = createSelector([
  selectProductViewLogsInStore,
  selectProductViewTaskQueues,
  selectProductViewLastTasks,
  selectProductViewLogsFilters,
  selectProductViewLogsTableLoading
], (logs, taskQueues, lastTasks, filters, tableLoading) => ({
  logs,
  taskQueues,
  lastTasks,
  filters,
  tableLoading
}));

export const selectProductViewTickets = createSelector([
  selectProductViewTicketsInStore,
  selectProductViewTicketsFilters,
  selectProductViewTicketsTableLoading
], (tickets, filters, tableLoading) => ({
  tickets,
  filters,
  tableLoading
}));