export default {
  TRY_AUTO_SIGN_IN: "TRY_AUTO_SIGN_IN",
  TRY_AUTO_SIGN_IN_SUCCESS: "TRY_AUTO_SIGN_IN_SUCCESS",
  TRY_AUTO_SIGN_IN_FAILURE: "TRY_AUTO_SIGN_IN_FAILURE",
  SIGN_IN: "SIGN_IN",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT: "SIGN_OUT",
  AUTH_STORE_REQUEST: "AUTH_STORE_REQUEST",
  FORGET_PASSWORD_RESET: "FORGET_PASSWORD_RESET",
  FORGET_PASSWORD_ASK_RESET: "FORGET_PASSWORD_ASK_RESET",
  FORGET_PASSWORD_ASK_RESET_SUCCESS: "FORGET_PASSWORD_ASK_RESET_SUCCESS",
  FORGET_PASSWORD_ASK_RESET_FAILURE: "FORGET_PASSWORD_ASK_RESET_FAILURE",
  RESET_PASSWORD_CHECK_RECOVER_TOKEN: "RESET_PASSWORD_CHECK_RECOVER_TOKEN",
  RESET_PASSWORD_CHECK_RECOVER_TOKEN_SUCCESS: "RESET_PASSWORD_CHECK_RECOVER_TOKEN_SUCCESS",
  RESET_PASSWORD_CHECK_RECOVER_TOKEN_FAILURE: "RESET_PASSWORD_CHECK_RECOVER_TOKEN_FAILURE",
  RESET_PASSWORD_SAVE: "RESET_PASSWORD_SAVE",
  RESET_PASSWORD_SAVE_SUCCESS: "RESET_PASSWORD_SAVE_SUCCESS",
  RESET_PASSWORD_SAVE_FAILURE: "RESET_PASSWORD_SAVE_FAILURE",
  ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN: "ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN",
  ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_SUCCESS: "ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_SUCCESS",
  ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_FAILURE: "ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_FAILURE",
  ACCOUNT_ACTIVATION_SAVE: "ACCOUNT_ACTIVATION_SAVE",
  ACCOUNT_ACTIVATION_SAVE_SUCCESS: "ACCOUNT_ACTIVATION_SAVE_SUCCESS",
  ACCOUNT_ACTIVATION_SAVE_FAILURE: "ACCOUNT_ACTIVATION_SAVE_FAILURE"
};