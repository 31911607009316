import {API_MANAGER} from '../_constants/api';
import {getServiceName} from './utils';

/**
 *
 * @param value : mixed
 * @param collection {{items, label, item} | null}
 * @param useId
 * @return {{label: string, value: *}|{label: string, value: null}}
 */
export const initSelect = (value, collection = null, useId = false) => {
  if (collection && collection.items) {
    if (Array.isArray(value)) {
      return value.map(val => {
        const item = useId ? collection.items.findInId(val) : collection.items.findIn(val);
        return {
          label: item.get(collection.label),
          value: val
        }
      })
    } else {
      const item = useId ? collection.items.findInId(value) : collection.items.findIn(value);
      if (item) {
        return {
          label: item.get(collection.label),
          value
        }
      } else {
        if (value) {
          return {
            label: value,
            value: ""
          }
        } else {
          return {
            label: "Selectionnez une valeur...",
            value: ""
          }
        }
      }
    }
  } else if (collection && collection.item) {
    return {
      label: collection.item.get(collection.label),
      value
    }
  } else {
    if (Array.isArray(value)) {
      return [];
    } else if (value) {
      return {
        label: value,
        value
      }
    } else {
      return {
        label: "Selectionnez une valeur...",
        value: ""
      }
    }
  }
};

export const getFileTypeByExtension = extension => {
  switch (extension) {
    case "jpeg":
    case "jpg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "ogm":
    case "wmv":
    case "mpg":
    case "webm":
    case "ogv":
    case "mov":
    case "asx":
    case "mpeg":
    case "mp4":
    case "m4v":
    case "avi":
      return `video/${extension}`;
    case "pdf":
      return "application/pdf";
    default:
      return "";
  }
};

// export const convertDocumentToFileUpload = (value, documents, isMulti = false) => {
//   if (value && documents) {
//     if (Array.isArray(value)) {
//       return value.map(uri => {
//         const document = documents.findIn(uri);
//         return {
//           "@id": uri,
//           name: document.get("publicName"),
//           preview: `${API_DATA}/${getServiceName()}/${document.get("publicPath")}`,
//           type: getFileTypeByExtension(document.get("extension"))
//         }
//       })
//     } else {
//       const document = documents.findIn(value);
//       if (document) {
//         return [{
//           "@id": value,
//           name: document.get("publicName"),
//           preview: `${API_DATA}/${getServiceName()}/${document.get("publicPath")}`,
//           type: getFileTypeByExtension(document.get("extension"))
//         }]
//       }
//     }
//   } else {
//     return isMulti ? [] : {};
//   }
// };

export const normalizeForm = values => {
  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === "boolean") {
      return values[key] = value;
    }
    return values[key] = !value ? "" : value;
  });
  return values;
};

export const getValidateUntilByType = (dataDocumentType, validateFrom) => {
  if (!dataDocumentType || dataDocumentType.get('validityUnit') === "permanent") {
    return null;
  } else {
    const initialDate = new Date(validateFrom);
    initialDate.setHours(0, 0, 0, 0);
    switch (dataDocumentType.get('validityUnit')) {
      case 'day':
        initialDate.setDate(initialDate.getDate() + dataDocumentType.get('validityNumber'));
        break;
      case 'week':
        initialDate.setDate(initialDate.getDate() + dataDocumentType.get('validityNumber') * 7);
        break;
      case 'month':
        initialDate.setMonth(initialDate.getMonth() + dataDocumentType.get('validityNumber'));
        break;
      case 'year':
        initialDate.setFullYear(initialDate.getFullYear() + dataDocumentType.get('validityNumber'));
        break;
    }
    return initialDate;
  }
}

export const initDocumentForm = (value, isMulti = false) => {
  if (value) {
    if (isMulti) {
      return value.map(document => {
        return {
          "@id": document.get("@id"),
          name: document.get("publicName"),
          preview: `${API_MANAGER}/${document.get("publicPath")}`,
          type: getFileTypeByExtension(document.get("extension"))
        }
      }).toJS()
    } else {
      return [{
        "@id": value.get("@id"),
        name: value.get("publicName"),
        preview: `${API_MANAGER}/${value.get("publicPath")}`,
        type: getFileTypeByExtension(value.get("extension"))
      }]
    }
  } else {
    return isMulti ? [] : {};
  }
}