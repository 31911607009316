import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function AccessDenied() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert severity="error" sx={{border: "1px solid #f1b3b3"}}>
          <AlertTitle>Accès refusé</AlertTitle>
          Vous ne disposez pas des droits pour accéder à cette page
        </Alert>
      </Grid>
    </Grid>
  );
}

export default AccessDenied;