import {all, takeLatest, put, call, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getAllItems} from '../../../_store/resources/sagas';
import {
  productListFetchSuccess,
  productListUpdateSuccess
} from './actions';
import {selectAuthUser} from '../../../_store/auth/selectors';

const emitter = "PRODUCT_LIST";

function* getProducts(params = {}) {
  const user = yield select(selectAuthUser);
  const [products1, products2] = yield all([
    call(getAllItems, {
      payload: {
        name: "products",
        params: {
          pagination: false,
          "organization.id": user.get("organization"),
          "not-like[articleStatus.id][]": [4, 18, 26],
          ...params
        },
        emitter,
        store: false
      }
    }),
    call(getAllItems, {
      payload: {
        name: "products",
        params: {
          pagination: false,
          "advertisingAgencies.id": user.get("organization"),
          "not-like[articleStatus.id][]": [4, 18, 26],
          ...params
        },
        emitter,
        store: false
      }
    })
  ]);
  const arr = [...products1.members, ...products2.members.map(product => ({...product, isAdvertisingAgency: true}))];
  arr.sort((a, b) => {
    if (a.isMonitored !== b.isMonitored) {
      return a.isMonitored ? -1 : 1;
    }
    if (a.isOnline !== b.isOnline) {
      return a.isOnline ? -1 : 1;
    }
    return a.name.localeCompare(b.name);
  });
  yield put({
    type: `${emitter}_STORE_REQUEST`,
    payload: {
      data: {
        members: arr,
        count: arr.length
      },
      name: "products"
    }
  });
}

function* productListFetch() {
  try {
    yield call(getProducts);
    yield put(productListFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des équipements");
  }
}

function* productListUpdate(action) {
  try {
    const {params} = action.payload;
    yield call(getProducts, params);
    yield put(productListUpdateSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des équipements");
  }
}

export function* watchProductList() {
  yield takeLatest(actionTypes.PRODUCT_LIST_FETCH, productListFetch);
  yield takeLatest(actionTypes.PRODUCT_LIST_UPDATE, productListUpdate);
}

export default watchProductList;
