import React from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import Box from '@mui/material/Box';
import {Paper} from '@mui/material';
import Typography from '@mui/material/Typography';

import Loading from '../../_components/Loading/Loading';

function Dashboard() {
  const pageLoaded = true;

  return (
    <>
      <Loading isLoading={!pageLoaded} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {pageLoaded && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{mb: 2}}>Tableau de bord</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{width: "100%"}}>
                <Paper variant="outlined" elevation={0} sx={{width: "100%"}}>
                  <Grid container sx={{p: 3}}>
                    <Grid item xs={12}>
                      <Typography variant="h4" align="center" sx={{
                        mb: 3,
                        color: "rgba(0, 0, 0, 0.7)",
                        fontWeight: "bold"
                      }}>
                        Bienvenue sur votre nouveau "Manager" Cartelmatic
                      </Typography>
                      <Typography variant="h5" align="center" sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                      }}>
                        {`Nous sommes ${moment().format("dddd DD MMMM YYYY")}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;