import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError, normalizeCollection} from '../../../_helpers/api';
import {getAllItems, getItemById} from '../../../_store/resources/sagas';
import {productViewFetchConnectionSuccess, productViewFetchSuccess, productViewUpdateLogsSuccess} from './actions';
import serviceMonitoring from '../../../_services/_monitoring/service';

const emitter = "PRODUCT_VIEW";

function* productViewFetch(action) {
  try {
    const {payload} = action;

    const servicesFilters = yield select(state => state.getIn(["scenes", "productView", "filters", "services"]));
    const ticketsFilters = yield select(state => state.getIn(["scenes", "productView", "filters", "tickets"]));

    // TODO "products.id" ne marche pas pour la récupération des services
    const [product, services] = yield all([
      call(getItemById, {
        payload: {
          name: "products",
          id: payload.id,
          emitter
        }
      }),
      call(getAllItems, {
        payload: {
          name: "webServices",
          params: {
            ...servicesFilters.toJS(),
            "order[name]": "asc",
            "products.id": payload.id
          },
          customName: "services",
          emitter
        }
      }),
      call(getAllItems, {
        payload: {
          name: "tickets",
          params: {
            ...ticketsFilters.toJS(),
            "products.id": payload.id
          },
          emitter
        }
      })
    ]);

    yield put(productViewFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération de l'équipement", true);
  }
}

function* productViewFetchConnection(action) {
  try {
    const {startDate, endDate, id} = action.payload;
    const apiMonitoring = serviceMonitoring();
    const result = yield call(apiMonitoring["validRange"].get, {
      "product": id,
      "startDate": `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(-2)}-${startDate.getDate()}`,
      "endDate": `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(-2)}-${endDate.getDate()}`,
      "itemsPerPage": 10000
    });
    yield put({
      type: `${emitter}_STORE_REQUEST`,
      payload: {
        data: normalizeCollection(result),
        name: "rangeConnexions"
      }
    });
    yield put(productViewFetchConnectionSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des informations de connexion", true);
  }
}

function* productViewUpdateLogs(action) {
  const {filters, start, end} = action.payload;
  const productId = yield select(state => state.getIn(["scenes", "productView", "resources", "products", "member", "id"]));
  console.log("filters", filters);
  console.log("start", start);
  console.log("end", end);
  console.log("productId", productId);

  try {
    yield call(getAllItems, {
      payload: {
        name: "logs",
        apiName: "monitoring",
        params: {
          ...filters,
          "createdAt[after]": start,
          "createdAt[before]": end,
          "task.product": productId
        },
        emitter
      }
    });
    yield put(productViewUpdateLogsSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération du journal d'activités", true);
  }
}

export function* watchProductView() {
  yield takeLatest(actionTypes.PRODUCT_VIEW_FETCH, productViewFetch);
  yield takeLatest(actionTypes.PRODUCT_VIEW_FETCH_CONNECTION, productViewFetchConnection);
  yield takeLatest(actionTypes.PRODUCT_VIEW_UPDATE_LOGS, productViewUpdateLogs);
}

export default watchProductView;