import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import {getIn} from 'formik';
import {Clear, PictureAsPdf, FileUpload} from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {FormHelperText, InputLabel, Button, Tooltip} from '@mui/material';
import {styled} from '@mui/material/styles';

const UploadFilesContainer = styled("div")(({files}) => ({
  display: "flex",
  flexDirection: files ? "row" : "column",
  alignItems: "center",
  justifyContent: files ? "left" : "center",
  height: 140,
  boxSizing: "border-box",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
  color: "rgba(0, 0, 0, 0.7)",
  border: "2px dashed rgba(0, 0, 0, 0.3)"
}));

function FileType(props) {
  const {
    field,
    form,
    label,
    maxSize = 5242880, // 5Mo par fichier (5 * 1048576)
    multiple = false,
    theme,
    accept,
    required
  } = props;
  let acceptedFiles = accept;
  if (!accept) {
    acceptedFiles = {"image/*": []};
  }
  const [dragOver, setDragOver] = useState(false);
  
  const error = getIn(form.errors, field.name) || false;
  const touched = getIn(form.touched, field.name) || false;
  
  
  const handleDrop = acceptedFiles => {
    setDragOver(false);
    if (acceptedFiles.length > 0) {
      const files = acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}));
      multiple ? form.setFieldValue(field.name, [...field.value, ...files]) : form.setFieldValue(field.name, [...files]);
      form.setTouched({...form.touched, [field.name]: true});
    }
  };
  
  const handleRemove = (e, file) => {
    e.stopPropagation();
    const newArray = [...field.value];
    const index = newArray.indexOf(file);
    if (index !== -1) {
      newArray.splice(index, 1);
    }
    form.setFieldValue(field.name, newArray);
  };
  
  let files = null;
  if (field.value.length > 0) {
    files = field.value.map((f, index) => (
      <Tooltip key={index} title={f.name} placement="bottom" arrow>
        <div style={{
          position: "relative",
          borderRadius: 4,
          marginLeft: 3,
          backgroundImage: `url(${f.preview})`, height: 130, width: 130,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundColor: "rgba(0, 0, 0, 0.15)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          {f.type.startsWith("video/") && (
            <video width="100%">
              <source src={f.preview} id="video_here"/>
              Your browser does not support HTML5 video.
            </video>
          )}
          {f.type.startsWith("application/pdf") && (
            <div>
              <PictureAsPdf sx={{fontSize: 40}}/>
            </div>
          )}
          <Button
            type="button"
            variant="contained"
            onClick={e => handleRemove(e, f)}
            color="delete"
            sx={{
              position: "absolute",
              top: 3,
              right: 3,
              height: 26,
              width: 26,
              minWidth: 26
            }}
          >
            <Clear fontSize="small"/>
          </Button>
        </div>
      </Tooltip>
    ))
  }
  return (
    <>
      <InputLabel
        htmlFor={field.name}
        style={{
          marginBottom: 5,
          color: error && touched ? "red" : "rgba(0, 0, 0, 0.87)"
        }}
      >
        {label}{required && " *"}
      </InputLabel>
      <Dropzone
        id={field.name}
        multiple={multiple}
        maxSize={maxSize}
        onDrop={handleDrop}
        onDragEnter={() => !dragOver && setDragOver(true)}
        onDragLeave={() => dragOver && setDragOver(false)}
        onFileDialogCancel={() => form.setTouched({...form.touched, [field.name]: true})}
        accept={acceptedFiles}
      >
        {({getRootProps, getInputProps}) => (
          <>
            <div style={{maxWidth: "600px"}}>
              <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <div>
                  <UploadFilesContainer files={files}>
                    {files ? files : (
                      <>
                        <p>
                          {multiple
                            ? "Faites glisser les fichiers ici ou cliquez pour les sélectionner."
                            : "Faites glisser le fichier ici ou cliquez pour le sélectionner."
                          }
                        </p>
                        <CloudUploadIcon sx={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontSize: 40,
                          margin: "-7px 0 7px 0"
                        }}/>
                      </>
                    )}
                  </UploadFilesContainer>
                </div>
              </div>
            </div>
            {error && touched && <FormHelperText error={true}>Un ou plusieurs fichiers sont requis</FormHelperText>}
          </>
        )}
      </Dropzone>
    </>
  );
}

export default FileType;
