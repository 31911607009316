import React, {useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {FastField, Form, Formik} from 'formik';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import validationSchema from './validationSchema';
import {selectAccountActivation} from '../../_store/auth/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import {accountActivationCheckConfirmationToken, accountActivationSave} from '../../_store/auth/actions';
import {Copyright} from '../SignIn/SignIn';
import useQuery from '../../_hooks/useQuery';

const theme = createTheme();

function AccountActivation() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  // noinspection JSUnresolvedFunction
  const {
    validate,
    validToken,
    tokenChecked,
    isSubmitting
  } = useSelector(selectAccountActivation);
  const query = useQuery();

  useEffect(() => {
    dispatch(accountActivationCheckConfirmationToken(query.get("token"), query.get("email")));
  }, [query]);

  const handleSubmit = values => {
    dispatch(accountActivationSave(query.get("token"), values, navigate));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <PasswordOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Création du mot de passe
          </Typography>
          <Formik initialValues={{
            password: "",
            confirmPassword: ""
          }} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({values, errors}) => {
              return (
                <Form id="activation" style={{width: "100%"}}>
                  <FastField
                    name="password"
                    fullWidth
                    required
                    margin="normal"
                    autoFocus
                    label="Mot de passe"
                    size="normal"
                    type="password"
                    component={TextType}
                  />
                  <FastField
                    name="confirmPassword"
                    fullWidth
                    required
                    margin="normal"
                    size="normal"
                    type="password"
                    label="Confirmation du mot de passe"
                    component={TextType}
                  />
                  <Button
                    type="submit"
                    form="activation"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{mt: 3, mb: 2}}
                  >
                    Valider
                  </Button>
                </Form>
              )
            }}
          </Formik>
          <Copyright sx={{mt: 8, mb: 4}}/>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AccountActivation;