export default {
  USER_LIST_FETCH: "USER_LIST_FETCH",
  USER_LIST_FETCH_SUCCESS: "USER_LIST_FETCH_SUCCESS",
  USER_LIST_RESET: "USER_LIST_RESET",
  USER_LIST_UPDATE: "USER_LIST_UPDATE",
  USER_LIST_UPDATE_SUCCESS: "USER_LIST_UPDATE_SUCCESS",
  USER_LIST_SET_FILTERS: "USER_LIST_SET_FILTERS",
  USER_LIST_DELETE_USER: "USER_LIST_DELETE_USER",
  USER_LIST_DELETE_USER_SUCCESS: "USER_LIST_DELETE_USER_SUCCESS",
  USER_LIST_STORE_REQUEST: "USER_LIST_STORE_REQUEST"
}