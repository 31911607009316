import actionTypes from './actionTypes';

export const rightsGroupFormFetch = id => ({
  type: actionTypes.RIGHTS_GROUP_FORM_FETCH,
  payload: {
    id
  }
});

export const rightsGroupFormFetchSuccess = () => ({
  type: actionTypes.RIGHTS_GROUP_FORM_FETCH_SUCCESS
});

export const rightsGroupFormReset = () => ({
  type: actionTypes.RIGHTS_GROUP_FORM_RESET
});

export const rightsGroupFormSave = values => ({
  type: actionTypes.RIGHTS_GROUP_FORM_SAVE,
  payload: {
    values
  }
});