import {call, delay, takeEvery, takeLatest, all, put, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError, handleSuccess} from '../../_helpers/api';
import {appActionCompleted, appFetchSuccess, appSetAnalytics, appValidDataFinished} from './actions';
import {getAllItems, postItem, putItem} from '../resources/sagas';
import {getLocalStorage} from '../../_helpers/localStorage';
import serviceAuth from '../../_services/_auth/service';
import {getServiceName} from '../../_helpers/utils';
import serviceData from '../../_services/_manager/service';
import serviceMonitoring from '../../_services/_monitoring/service';

const emitter = "APP";

function* appFetch() {
  try {
    const organization = yield select(state => state.getIn(["auth", "user", "organization"]));
    const [analytics1, analytics2] = yield all([
      call(getAllItems, {
        payload: {
          name: "webServices",
          params: {
            pagination: false,
            "article.name": "Analytics",
            "organization.id": organization
          },
          emitter,
          store: false
        }
      }),
      call(getAllItems, {
        payload: {
          name: "webServices",
          params: {
            pagination: false,
            "article.name": "Analytics",
            "advertisingAgencies.id": organization
          },
          emitter,
          store: false
        }
      })
    ]);
    const arr = [...analytics1.members, ...analytics2.members];
    yield put(appSetAnalytics(arr.length > 0 ? arr : null));
    yield put(appFetchSuccess());
  } catch (e) {
    console.log(e);
    // noinspection JSCheckFunctionSignatures
    yield call(handleError, 500, "Erreur de récupération des données principales");
  }
}

function* appValidDataAndPublish() {
  try {
    yield call(validData);
    const products = yield select(state => state.getIn(["auth", "resources", "products"]));
    yield all(products.get("members").map(product => call(publishDataOnProduct, product)).toJS());
    yield call(handleSuccess, "Validation terminée et demande de publication prise en compte");
  } catch (e) {
    yield call(handleError, 500, "Erreur durant la validation ou la publication des données");
  }
  yield put(appValidDataFinished());
}

function* appValidData() {
  try {
    yield call(validData);
    yield call(handleSuccess, "Validation terminée avec succès");
  } catch (e) {
    yield call(handleError, 500, "Erreur durant la validation", true);
  }
  yield put(appValidDataFinished());
}

function* validData() {
  const apiAuth = serviceAuth();
  //Régénaration des selections
  const selections = yield call(getAllItems, {
    payload: {
      name: "selections",
      params: {
        pagination: false
      }
    }
  });
  const apiData = serviceData();
  yield all(selections.members.map(selection => call(apiData["regenerateSelection"].create, selection["id"])))
  //On verifie qu'une tache data_dump_file n'est pas déjà existante
  const {count: taskQueuesCount, members: tasksQueues} = yield call(getAllItems, {
    payload: {
      apiName: "auth",
      name: "taskQueues",
      params: {
        name: "data_dump_file",
        "service.id": getLocalStorage("WEB_SERVICE_ID")
      },
      emitter
    }
  });
  if (taskQueuesCount === 0) {
    const result = yield call(apiAuth["tasksForce"].create, {
      name: "data_dump_file",
      serviceReference: getServiceName()
    });
    if (result.data && result.data.state === "failed") {
      // noinspection SpellCheckingInspection,JSCheckFunctionSignatures
      throw new Error("impossible de valider les données");
    }
  } else {
    //On verifie que la tache trouvé n'est pas en cours
    const {count} = yield call(getAllItems, {
      payload: {
        apiName: "auth",
        name: "taskQueues",
        params: {
          name: "data_dump_file",
          "service.id": getLocalStorage("WEB_SERVICE_ID"),
          state: "processing"
        },
        emitter
      }
    });
    if (count === 0) {
      //Aucune tache n'est en cours donc on peut lancer celle trouvé
      const taskQueue = tasksQueues[0];
      const result = yield call(apiAuth['dataDumpFile'].get, taskQueue.id);
      if (result.data && result.data.state === "failed") {
        // noinspection SpellCheckingInspection,JSCheckFunctionSignatures
        throw new Error("impossible de valider les données");
      }
    }
  }
}

function* publishDataOnProduct(product) {
  const apiAuth = serviceAuth();
  //On verifie qu'une tache data_dump_file n'est pas déjà existante
  const {count: taskQueuesCount, members: tasksQueues} = yield call(getAllItems, {
    payload: {
      apiName: "auth",
      name: "taskQueues",
      params: {
        name: "data_publish",
        "service.id": getLocalStorage("WEB_SERVICE_ID"),
        "product.id": product["id"]
      },
      emitter
    }
  });
  if (taskQueuesCount === 0) {
    const result = yield call(postItem, {
      payload: {
        apiName: "auth",
        name: "taskQueues",
        item: {
          name: "data_publish",
          service: `/web_services/${getLocalStorage("WEB_SERVICE_ID")}`,
          product: `/products/${product["id"]}`,
          onQueue: false
        },
        emitter,
        store: false
      }
    });
    if (result.data && result.data.state === "failed") {
      // noinspection SpellCheckingInspection,JSCheckFunctionSignatures
      throw new Error(`impossible de publier sur le produit: ${product["name"]}`);
    }
  } else {
    //On verifie que la tache trouvé n'est pas en cours
    const {count} = yield call(getAllItems, {
      payload: {
        apiName: "auth",
        name: "taskQueues",
        params: {
          name: "data_publish",
          "service.id": getLocalStorage("WEB_SERVICE_ID"),
          "product.id": product["id"],
          state: "processing"
        },
        emitter
      }
    });
    if (count === 0) {
      //Aucune tache n'est en cours donc on peut lancer celle trouvé
      const taskQueue = tasksQueues[0];
      const result = yield call(apiAuth['data_publish'].get, taskQueue.id);
      if (result.data && result.data.state === "failed") {
        // noinspection SpellCheckingInspection,JSCheckFunctionSignatures
        throw new Error(`impossible de publier sur le produit: ${product["name"]}`);
      }
    }
  }
}

const getActionMessage = (actionName, status, params = {}) => {
  switch (actionName) {
    case "publish_service_on_network":
      switch (status) {
        case "error":
          return "Erreur durant la demande de publication sur le réseau";
        case "success":
          return "La demande de publication sur le réseau a été prise en compte";
        default:
          return "";
      }
    case "publish_service_on_product":
      switch (status) {
        case "error":
          return `Erreur durant la demande de publication sur le produit ${params.product.get("name")}`;
        case "success":
          return `La demande de publication sur le produit ${params.product.get("name")} a été prise en compte`;
        default:
          return "";
      }
    case "sync_stats":
      switch (status) {
        case "error":
          return "Erreur durant la synchronisation des statistiques";
        case "success":
          return "La demande de synchronisation des statistiques a été prise en compte";
        default:
          return "";
      }
    case "sync_e-mails":
      switch (status) {
        case "error":
          return "Erreur durant la synchronisation des e-mails";
        case "success":
          return "La demande de synchronisation des e-mails a été prise en compte";
        default:
          return "";
      }
    case "restart_thezia":
      switch (status) {
        case "error":
          return `Erreur durant le redémarrage de l'application pour l'équipement "${params.name}"`;
        case "success":
          return `L'équipement "${params.name}" a bien redémarré son application`;
        default:
          return `L'équipement "${params.name}" n'a pas pu redémarrer son application`;
      }
    case "ping":
      switch (status) {
        case "error":
          return `Erreur durant le test de connexion de l'équipement "${params.name}"`;
        case "success":
          return `L'équipement "${params.name}" est connecté`;
        default:
          return `L'équipement "${params.name}" n'est pas connecté`;
      }
    default:
      switch (status) {
        case "error":
          return "Erreur";
        case "success":
          return "Succès";
        default:
          return "Échec";
      }
  }
}

function* publicationOnProduct({actionName, product, service}) {
  let taskName;
  switch (service.article.id) {
    case 5: // Application Baliz
      taskName = "baliz_publish";
      break;
    case 6: // Application Funbox
      taskName = "funbox_api_publish";
      break;
    case 7: // Gestionnaire de données (DATA)
      taskName = "data_publish";
      break;
    case 8: // Analytics
      taskName = actionName === "sync_stats" ? "analytics_sync" : "analytics_sync_email";
      break;
    case 14: // Application V4
      taskName = "deploy_st3v4";
      break;
    default:
      taskName = "";
  }
  const {count: taskQueuesCount} = yield call(getAllItems, {
    payload: {
      apiName: "monitoring",
      name: "taskQueues",
      params: {
        name: taskName,
        "service": service["id"],
        ...(product ? {"product": product} : {})
      },
      emitter
    }
  });
  if (taskQueuesCount === 0) {
    yield call(postItem, {
      payload: {
        apiName: "monitoring",
        name: "taskQueues",
        item: {
          name: taskName,
          service: service["id"],
          ...(product ? {product: product} : {}),
          onQueue: false
        },
        emitter
      }
    });
  }
}

function* appAction(action) {
  const {actionName, key, params} = action.payload;
  if (["publish_service_on_network", "sync_stats", "sync_e-mails"].includes(actionName)) {
    const {service} = params;
    try {
      yield all(service.get("products").map(product => call(publicationOnProduct, {
        actionName,
        productIRI: product.get("@id"),
        service
      })).toJS());
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      yield call(handleSuccess, getActionMessage(actionName, "success", params));
    } catch (e) {
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      yield call(handleError, 500, getActionMessage(actionName, "error", params), true);
    }
  } else if (actionName === "publish_service_on_product") {
    const {product, service} = params;
    try {
      yield call(publicationOnProduct, {
        actionName,
        product: product.get("id"),
        service: service.toJS()
      });
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      yield call(handleSuccess, getActionMessage(actionName, "success", params));
    } catch (e) {
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      yield call(handleError, 500, getActionMessage(actionName, "error", params), true);
    }
  } else {
    try {
      const apiMonitoring = serviceMonitoring();
      const result = yield call(apiMonitoring["tasksForce"].create, {
        "name": actionName,
        "productReference": key
      });
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      if (result.status === "success") {
        yield call(handleSuccess, getActionMessage(actionName, "success", params));
      } else {
        yield call(handleError, 0, getActionMessage(actionName, result.status, params), true);
      }
    } catch (e) {
      yield delay(1000);
      yield put(appActionCompleted(actionName, key));
      yield call(handleError, 500, getActionMessage(actionName, "error", params), true);
    }
  }
}

export function* watchApp() {
  yield takeLatest(actionTypes.APP_FETCH, appFetch)
  yield takeLatest(actionTypes.APP_VALID_DATA_AND_PUBLISH, appValidDataAndPublish)
  yield takeLatest(actionTypes.APP_VALID_DATA, appValidData)
  yield takeEvery(actionTypes.APP_ACTION, appAction)
}

export default watchApp;
