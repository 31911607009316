import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate, useParams, useLocation} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';

import {productViewFetch, productViewReset} from './_store/actions';
import {selectProductView} from './_store/selectors';
import {
  StyledConnexionContainer,
  StyledHours,
  StyledHour,
  StyledProductHeader,
  StyledProductImg,
  StyledProductInfos,
  StyledDays,
  StyledDay,
  StyledConnexion,
  StyledStatus,
  StyledTableContainer, StyledProductEditButton
} from './styled';
import Loading from '../../_components/Loading/Loading';
import FormSection from '../../_components/_form/FormSection/FormSection';
import {GStyledSectionTitle} from '../../styled';
import usePrevious from '../../_hooks/usePrevious';
import {fromJS} from 'immutable';
import AddIcon from '@mui/icons-material/Add';
import {getFormattedDate} from '../../_helpers/date';
import Link from '@mui/material/Link';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TableContainer from '../../_components/TableContainer/TableContainer';
import {productListUpdate} from '../ProductList/_store/actions';
import ConnectionTable from './_components/ConnectionTable';
import Services from './_components/Services';
import {selectAppAction, selectAppTrackLocation} from '../../_store/app/selectors';
import useCheckId from '../../_hooks/useCheckId';
import {appAction} from '../../_store/app/actions';
import Logs from './_components/Logs';
import {StyledServiceEditButton} from "../ServiceView/styled";
import {selectAuthUser} from '../../_store/auth/selectors';

const StyledTabs = styled(Tabs)({
  backgroundColor: "#f3f0f9",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  '& .MuiTabs-indicator': {
    height: "3px"
  }
});

const StyledTab = styled(props => <Tab disableRipple {...props}/>)({
  color: "rgba(0, 0, 0, 0.87)",
  textTransform: "none",
  fontSize: "1rem"
});

function ProductView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {id: paramsId} = useParams();
  const user = useSelector(selectAuthUser);
  const id = useCheckId(paramsId);

  const {pageLoaded, product} = useSelector(selectProductView);

  const ping = useSelector(state => selectAppAction(state, product ? ["ping", product.get("reference")] : null));
  const restartThezia = useSelector(state => selectAppAction(state, product ? ["restart_thezia", product.get("reference")] : null));
  const [tab, setTab] = useState(0);
  const trackLocation = useSelector(selectAppTrackLocation);

  useEffect(() => {
    if (id) {
      dispatch(productViewFetch(id));
    }
  }, [id]);

  useEffect(() => {
    return () => dispatch(productViewReset());
  }, []);

  // Permet de se positionner sur l'onglet des tickets quand on arrive sur la page depuis l'ajout d'un ticket
  useEffect(() => {
    if (trackLocation.get("current") === location.pathname) {
      setTab(trackLocation.get("prev") === "/tickets/ajout" ? 1 : 0);
    }
  }, [trackLocation, location]);

  // TODO Remplacer ces valeurs
  const totalDays = 7;
  const end = new Date();
  const start = new Date(end);
  start.setDate(start.getDate() - totalDays + 1);
  const connexion = fromJS({
    startDate: start,
    endDate: end,
    error: ""
  });

  const days = useMemo(() => {
    const arr = [];
    for (let i = 0; i < totalDays; i++) {
      const date = new Date(connexion.get("startDate"));
      date.setDate(date.getDate() + i);
      arr.push(
        <StyledDay key={`day-${i}`} style={{top: i * 30}}>
          {date.toLocaleDateString()}
        </StyledDay>
      );
    }
    return arr;
  }, [connexion]);

  const tickets = fromJS({
    members: [
      {
        id: 1,
        "@id": "/tickets/1",
        name: "Pas de statistiques",
        author: "Doe John",
        ref: "#0789",
        status: "En cours",
        category: "Logiciel",
        date: "2023-10-26T09:45:59+00:00",
        lastActivity: "2023-10-26T16:12:00+00:00",
        nbComments: 8
      },
      {
        id: 2,
        "@id": "/tickets/2",
        name: "Problème de connexion",
        author: "Doe John",
        ref: "#0622",
        status: "Fermé",
        category: "Réseau",
        date: "2023-10-02T14:17:00+00:00",
        lastActivity: "2023-10-07T11:02:00+00:00",
        nbComments: 2
      }
    ],
    count: 2,
    indexByUri: {
      "/tickets/1": 0,
      "/tickets/2": 1
    },
    indexes: {},
    pageCount: 1,
    request: ""
  });

  const columns = useMemo(() => {
    return [
      // {
      //   Header: "Référence",
      //   accessor: "ref",
      //   filterable: true,
      //   sortable: true,
      //   sortableName: "ref",
      //   fixWidth: 300,
      //   Cell: cell => cell.value
      // },
      {
        Header: "Statut",
        accessor: "status",
        filterable: true,
        sortable: true,
        sortableName: "status",
        Cell: cell => (
          <StyledStatus
            bgColor={cell.value === "En cours" ? "#3f51b5" : "#d9d9d9"}
            color={cell.value === "En cours" ? "#fff" : "rgba(0, 0, 0, 0.87)"}
          >
            {cell.value}
          </StyledStatus>
        )
      },
      {
        Header: "Titre",
        accessor: "name",
        filterable: true,
        sortable: true,
        sortableName: "name",
        Cell: cell => (
          <Link
            component={RouterLink}
            to={`/tickets/${cell.row.original.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            {cell.value}
          </Link>
        )
      },
      {
        Header: "Dernière activité",
        accessor: "lastActivity",
        filterable: true,
        sortable: true,
        sortableName: "lastActivity",
        Cell: cell => getFormattedDate(cell.value, false)
      },
      {
        Header: "Auteur",
        accessor: "author",
        filterable: true,
        sortable: true,
        sortableName: "author",
        Cell: cell => cell.value
      },
      {
        Header: "Catégorie",
        accessor: "category",
        filterable: true,
        sortable: true,
        sortableName: "category",
        Cell: cell => cell.value
      },
      {
        Header: "Nombre de commentaires",
        accessor: "nbComments",
        filterable: true,
        sortable: true,
        sortableName: "nbComments",
        Cell: cell => cell.value
      }
    ];
  }, [pageLoaded]);

  const filters = fromJS({
    "page": 1,
    "itemsPerPage": 20,
    "order[updatedAt]": "desc"
  });
  const tableLoading = false;

  const fetchData = useCallback(params => {
  }, [dispatch]);

  return (
    <>
      <Loading isLoading={!pageLoaded} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {pageLoaded && (
        <Grid container>
          <Grid item xs={12} style={{height: "3rem"}}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
            >
              <Typography
                key="2"
                color="inherit"
                sx={{color: "#1976d2", "&:hover": {cursor: "pointer", textDecoration: "underline"}}}
                onClick={() => navigate("/equipements")}
              >
                Équipements
              </Typography>
              <Typography key="3" color="text.primary">
                {product.get("name")}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <StyledProductHeader>
                  <StyledProductImg path={product.get("featuredImage") ? product.getIn(["featuredImage", "publicPath"]) : null}/>
                  <StyledProductInfos>
                    <div style={{fontSize: "1.4rem", color: "#673ab7", fontWeight: "bold"}}>
                      {product.get("name")}
                    </div>
                    <div><span style={{fontWeight: "bold"}}>Type : </span>{product.getIn(["article", "name"])}</div>
                    <div><span style={{fontWeight: "bold"}}>Référence : </span>{product.get("reference")}</div>
                  </StyledProductInfos>
                  <StyledProductEditButton>
                    <Tooltip title="Éditer" arrow>
                      <IconButton
                        aria-label="edit"
                        onClick={() => navigate(`/equipements/edition/${id}`)}
                        disabled={!user.getIn(["rights", "products", "edit"])}
                        sx={{
                          color: "#ffa000",
                          backgroundColor: "rgba(0, 0, 0, 0.04) !important"
                        }}
                      >
                        <EditOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </StyledProductEditButton>
                </StyledProductHeader>
                <StyledTabs
                  value={tab}
                  onChange={(e, newTab) => setTab(newTab)}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="product tabs"
                  sx={{marginBottom: "2rem"}}
                >
                  <StyledTab label="Général"/>
                  {/*<StyledTab*/}
                  {/*  label={*/}
                  {/*    <>*/}
                  {/*      Tickets*/}
                  {/*      <span style={{*/}
                  {/*        position: "absolute",*/}
                  {/*        height: "20px",*/}
                  {/*        width: "20px",*/}
                  {/*        backgroundColor: "#663ab6",*/}
                  {/*        borderRadius: "50%",*/}
                  {/*        color: "#fff",*/}
                  {/*        fontSize: "0.75rem",*/}
                  {/*        right: "0",*/}
                  {/*        top: "0",*/}
                  {/*        display: "flex",*/}
                  {/*        alignItems: "center",*/}
                  {/*        justifyContent: "center"*/}
                  {/*      }}>*/}
                  {/*        2*/}
                  {/*      </span>*/}
                  {/*    </>*/}
                  {/*  }*/}
                  {/*  sx={{*/}
                  {/*    position: "relative"*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <StyledTab label="Journal d'activités"/>
                </StyledTabs>
                {tab === 0 && (
                  <>
                    <Grid item xs={12} sx={{mb: 4}}>
                      <Button
                        variant="contained"
                        color="action"
                        disableRipple
                        sx={{mr: 2}}
                        disabled={ping && ping.get("inProgress")}
                        onClick={() => dispatch(appAction("ping", product.get("reference"), {
                          name: product.get("name")
                        }))}
                      >
                        <div style={{marginTop: "1px", marginBottom: "-1px"}}>Tester la connexion</div>
                      </Button>
                      <Button
                        variant="contained"
                        color="action"
                        disableRipple
                        disabled={restartThezia && restartThezia.get("inProgress")}
                        onClick={() => dispatch(appAction("restart_thezia", product.get("reference"), {
                          name: product.get("name")
                        }))}
                      >
                        <div style={{marginTop: "1px", marginBottom: "-1px"}}>Redémarrage de l'application</div>
                      </Button>
                      {/*<Button*/}
                      {/*  variant="contained"*/}
                      {/*  color="action"*/}
                      {/*  sx={{width: "100%"}}*/}
                      {/*  // onClick={() => navigate("/comptes/groupes/ajout")}*/}
                      {/*>*/}
                      {/*  Éteindre/Rallumer l'écran*/}
                      {/*</Button>*/}
                    </Grid>
                    <GStyledSectionTitle>Connexion</GStyledSectionTitle>
                    <Grid container spacing={2} sx={{mb: 4}}>
                      <Grid item xs={12}>
                        <ConnectionTable/>
                      </Grid>
                    </Grid>
                    <GStyledSectionTitle>Services rattachés</GStyledSectionTitle>
                    <Grid item xs={12} sx={{mb: -2, table: {borderTop: "none"}}}>
                      <Services/>
                    </Grid>
                  </>
                )}
                {/*{tab === 1 && (*/}
                {/*  <Grid container spacing={2}>*/}
                {/*    <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>*/}
                {/*      <Button*/}
                {/*        variant="contained"*/}
                {/*        color="add"*/}
                {/*        startIcon={<AddIcon/>}*/}
                {/*        onClick={() => navigate("/tickets/ajout", {*/}
                {/*          state: {*/}
                {/*            from: location.pathname,*/}
                {/*            tab: 1*/}
                {/*          }*/}
                {/*        })}*/}
                {/*      >*/}
                {/*        <div style={{marginTop: "1px", marginBottom: "-1px"}}>Nouveau</div>*/}
                {/*      </Button>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} sx={{marginBottom: -2}}>*/}
                {/*      <TableContainer*/}
                {/*        id="table-container-product-tickets-list"*/}
                {/*        columns={columns}*/}
                {/*        data={tickets ? tickets.get('members').toJS() : []}*/}
                {/*        pageCount={tickets ? tickets.get("pageCount") : 1}*/}
                {/*        count={tickets ? tickets.get("count") : 0}*/}
                {/*        customPageSize={20}*/}
                {/*        fetchData={fetchData}*/}
                {/*        filters={filters.toJS()}*/}
                {/*        loading={tableLoading}*/}
                {/*        defaultSort={{sortDirection: "desc", accessor: "updatedAt"}}*/}
                {/*      />*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*)}*/}
                {tab === 1 && (
                  <Grid container>
                    <Grid item xs={12} sx={{mb: -2, table: {borderTop: "none"}}}>
                      <Logs/>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ProductView;