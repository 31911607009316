import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  pageLoaded: false,
  tableLoading: {
    products: false,
    tickets: false
  },
  filters: {
    products: {
      page: 1,
      itemsPerPage: 20
    },
    tickets: {
      page: 1,
      itemsPerPage: 20
    }
  }
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actionTypes.SERVICE_VIEW_FETCH_SUCCESS:
      return state.set("pageLoaded", true);
    case actionTypes.SERVICE_VIEW_RESET:
      return initialState;
    case actionTypes.SERVICE_VIEW_UPDATE_PRODUCTS:
      return state.setIn(["tableLoading", "products"], true);
    case actionTypes.SERVICE_VIEW_UPDATE_PRODUCTS_SUCCESS:
      return state.setIn(["tableLoading", "products"], false);
    case actionTypes.SERVICE_VIEW_UPDATE_TICKETS:
      return state.setIn(["tableLoading", "tickets"], true);
    case actionTypes.SERVICE_VIEW_UPDATE_TICKETS_SUCCESS:
      return state.setIn(["tableLoading", "tickets"], false);
    case actionTypes.SERVICE_VIEW_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    default:
      return state;
  }
};

export default reducer;