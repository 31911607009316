import API from '../apiGenerator';
import {API_ANALYTICS, AUTH} from '../../_constants/api';
import {getCookie} from '../../_helpers/cookie';
import axios from 'axios';
import {bearer} from '../../_helpers/api';

// Alphabétique
export const resourcesNames = {
  statistics: {
    plural: "STATISTICS",
    singular: "STATISTIC"
  }
};

const serviceAnalytics = (entity = null, entities = [], alias = null) => {

  const api = new API({
    base_url: API_ANALYTICS,
    // TODO Changer le token
    // token: getCookie("token-v2"),
    token: "ACIky7wErPzio8HVJ7RZ",
    alias
  });

  if (entity) {
    api.createEntity(entity);
  }

  if (entities) {
    api.createEntities(entities);
  }

  api.addEndpoints({
    name: "getMinMaxDate",
    request: {
      get: () => axios.get(`${API_ANALYTICS}/${alias}/get-min-max-date`, bearer()).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getByYear",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-by-year`, bearer({}, params)).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getByMonth",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-by-month`, bearer({}, params)).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getByDay",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-by-day`, bearer({}, params)).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getByHour",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-by-hour`, bearer({}, params)).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getReviewPage",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-review-page`, bearer({}, params)).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "getOverviewByProduct",
    request: {
      get: params => axios.get(`${API_ANALYTICS}/${alias}/get-overview-by-product`, bearer({}, params)).then(response => response.data)
    }
  });

  if (!entity && entities.length === 0) {
    // Alphabétique
    api.createEntities([
      "statistics"
    ])
  }

  return api;
};

export default serviceAnalytics;
