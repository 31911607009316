import actionTypes from './actionTypes';

export const userFormFetch = id => ({
  type: actionTypes.USER_FORM_FETCH,
  payload: {
    id
  }
});

export const userFormFetchSuccess = () => ({
  type: actionTypes.USER_FORM_FETCH_SUCCESS
});

export const userFormReset = () => ({
  type: actionTypes.USER_FORM_RESET
});

export const userFormSave = values => ({
  type: actionTypes.USER_FORM_SAVE,
  payload: {
    values
  }
});

export const userFormSendResetPassword = values => ({
  type: actionTypes.USER_FORM_SEND_PASSWORD_RESET,
  payload: {
    values
  }
})
