import {useCallback} from 'react';

function useVisualizationRedirect() {
  return useCallback((product, service) => {
    let url;
    switch (service.getIn(["article", "id"])) {
      case 6:
        url = encodeURIComponent(`https://funbox.cartelmatic.com/${service.get("reference")}?_k=${service.get("apiKey")}&_p=${product.get("reference")}&_clear=false&_viewer=true`);
        window.open(`https://viewer.cartelmatic.com/?url=${url}&fullscreen=true`, "_blank");
        break;
      case 5:
        url = encodeURIComponent(`https://baliz.cartelmatic.com/${service.get("reference")}?_k=${service.get("apiKey")}&_p=${product.get("reference")}&_clear=false&_viewer=true`);
        window.open(`https://viewer.cartelmatic.com/?url=${url}`, "_blank");
        break;
      default:
    }
  }, []);
}

export default useVisualizationRedirect;