export default {
  USER_FORM_FETCH: "USER_FORM_FETCH",
  USER_FORM_FETCH_SUCCESS: "USER_FORM_FETCH_SUCCESS",
  USER_FORM_RESET: "USER_FORM_RESET",
  USER_FORM_SAVE: "USER_FORM_SAVE",
  USER_FORM_SAVE_SUCCESS: "USER_FORM_SAVE_SUCCESS",
  USER_FORM_SAVE_FAILURE: "USER_FORM_SAVE_FAILURE",
  USER_FORM_STORE_REQUEST: "USER_FORM_STORE_REQUEST",
  USER_FORM_SEND_PASSWORD_RESET: "USER_FORM_SEND_PASSWORD_RESET"
}
