import {createSelector} from 'reselect';

export const selectAuthUser = state => state.getIn(["auth", "user"]);
export const selectAuthOrganization = state => state.getIn(["auth", "resources", "organizations", "member"]);
export const selectAuthIsTried = state => state.getIn(["auth", "isTried"]);
export const selectAuthWebService = state => state.getIn(["auth", "webService"]);
export const selectAuthIsSubmitting = state => state.getIn(["auth", "isSubmitting"]);
export const selectAuthIsAuthenticated = state => state.getIn(["auth", "isAuthenticated"]);
export const selectAuthEmployee = state => state.getIn(["auth", "employee"]);
export const selectAuthValidate = state => state.getIn(["auth", "validate"]);
export const selectAuthValidToken = state => state.getIn(["auth", "validToken"]);
export const selectAuthTokenChecked = state => state.getIn(["auth", "tokenChecked"]);

export const selectProfile = createSelector([
  selectAuthUser,
  selectAuthEmployee,
  selectAuthIsAuthenticated
], (user, employee, isAuthenticated) => ({
  user,
  employee,
  isAuthenticated
}));

export const selectAccountActivation = createSelector([
  selectAuthValidate,
  selectAuthValidToken,
  selectAuthTokenChecked,
  selectAuthIsSubmitting
], (validate, validToken, tokenChecked, isSubmitting) => ({
  validate,
  validToken,
  tokenChecked,
  isSubmitting
}));

export const selectSignIn = createSelector([
  selectAuthIsSubmitting,
  selectAuthIsAuthenticated
], (isSubmitting, isAuthenticated, isTried) => ({
  isSubmitting,
  isAuthenticated
}));