import React from 'react';
import {Typography} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function ConnexionLoading() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1099
      }}
    >
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", width: "60%", position: "relative"}}>
        <Typography variant="h6" color="primary" sx={{fontSize: "1.15rem", position: "absolute", top: "-2.2rem"}}>
          Connexion en cours...
        </Typography>
        <LinearProgress color="primary" sx={{width: "100%", height: "6px", borderRadius: "3px"}}/>
      </Box>
    </Box>
  );
}

export default ConnexionLoading;