import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import FeedIcon from '@mui/icons-material/Feed';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import IconButton from '@mui/material/IconButton';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PermDeviceInformationOutlinedIcon from '@mui/icons-material/PermDeviceInformationOutlined';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';

import {matchPathname} from '../../_helpers/utils';
import {useSelector} from 'react-redux';
import {selectAppAnalytics} from '../../_store/app/selectors';
import {selectAuthUser} from '../../_store/auth/selectors';

const StyledButton = styled(({active = false, children, ...rest}) => (
  <Button
    {...rest}
    disableRipple
    disableFocusRipple
  >
    {children}
  </Button>
))(({active, theme}) => ({
  textTransform: "none",
  border: "none",
  color: active ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  "&:hover": {
    border: "none",
    backgroundColor: "transparent"
  },
  "& svg": {
    color: active ? "#1976d2" : "rgba(0, 0, 0, 0.7)"
  }
}));

function HorizontalNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [accountsAnchorEl, setAccountsAnchorEl] = useState(null);
  const accountsOpen = Boolean(accountsAnchorEl);
  const analytics = useSelector(selectAppAnalytics);
  const user = useSelector(selectAuthUser);
  const handleClick = e => {
    setAccountsAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAccountsAnchorEl(null);
  };

  const appLoaded = true;
  return appLoaded && (
    <Box sx={{
      position: "absolute",
      zIndex: 1099,
      height: 42,
      top: 64,
      right: 0,
      left: 0,
      display: {xs: "none", lg: "flex"},
      justifyContent: "center",
      backgroundColor: "#fff",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14), 0 2px 8px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.05)"
    }}>
      <ButtonGroup size="large" aria-label="horizontal navigation">
        {/*<StyledButton*/}
        {/*  active={matchPathname(location, `/tableau-de-bord`)}*/}
        {/*  onClick={() => navigate(`/tableau-de-bord`)}*/}
        {/*  startIcon={*/}
        {/*    <DashboardOutlinedIcon*/}
        {/*      sx={{*/}
        {/*        fontSize: "24px !important",*/}
        {/*        marginTop: "-2px"*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*>*/}
        {/*  Tableau de bord*/}
        {/*</StyledButton>*/}
        <StyledButton
          active={matchPathname(location, `/equipements`)}
          onClick={() => navigate(`/equipements`)}
          disabled={!user.getIn(["rights", "products", "view"])}
          sx={{"&:disabled": {border: "none"}}}
          startIcon={
            user.getIn(["rights", "products", "view"]) ? (
              <PermDeviceInformationOutlinedIcon
                sx={{
                  fontSize: "24px !important",
                  marginTop: "-2px"
                }}
              />
            ) : (
              <LockOutlinedIcon
                sx={{
                  fontSize: "24px !important",
                  marginTop: "-2px",
                  color: "rgba(0, 0, 0, 0.26) !important"
                }}
              />
            )
          }
        >
          Équipements
        </StyledButton>
        <StyledButton
          active={matchPathname(location, `/services`)}
          onClick={() => navigate(`/services`)}
          startIcon={
            <InstallMobileOutlinedIcon
              sx={{
                fontSize: "24px !important",
                marginTop: "-2px"
              }}
            />
          }
        >
          Services
        </StyledButton>
        {analytics && (
          <StyledButton
            active={matchPathname(location, `/statistiques`)}
            onClick={() => navigate(`/statistiques`)}
            disabled={!user.getIn(["rights", "analytics", "view"])}
            sx={{"&:disabled": {border: "none"}}}
            startIcon={
              user.getIn(["rights", "analytics", "view"]) ? (
                <AssessmentOutlinedIcon
                  sx={{
                    fontSize: "24px !important",
                    marginTop: "-2px"
                  }}
                />
              ) : (
                <LockOutlinedIcon
                  sx={{
                    fontSize: "24px !important",
                    marginTop: "-2px",
                    color: "rgba(0, 0, 0, 0.26) !important"
                  }}
                />
              )
            }
          >
            Statistiques
          </StyledButton>
        )}
        {/*<StyledButton*/}
        {/*  active={matchPathname(location, `/tickets`)}*/}
        {/*  onClick={() => navigate(`/tickets`)}*/}
        {/*  startIcon={*/}
        {/*    <ConfirmationNumberOutlinedIcon*/}
        {/*      sx={{*/}
        {/*        fontSize: "24px !important",*/}
        {/*        marginTop: "-2px"*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*>*/}
        {/*  Tickets*/}
        {/*</StyledButton>*/}
        {user.getIn(["rights", "isSuperAdminOrCartel"]) ? (
          <>
            <StyledButton
              id="accounts-button"
              aria-controls={accountsOpen ? "accounts-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={accountsOpen ? "true" : undefined}
              onClick={handleClick}
              active={matchPathname(location, "/comptes")}
              sx={{"&:disabled": {border: "none"}}}
              startIcon={
                <GroupOutlinedIcon
                  sx={{
                    fontSize: "24px !important",
                    marginTop: "-2px"
                  }}
                />
              }
              endIcon={accountsOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            >
              Comptes
            </StyledButton>
            <Menu
              id="accounts-menu"
              aria-labelledby="accounts-button"
              anchorEl={accountsAnchorEl}
              open={accountsOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              sx={{
                "& > .MuiPaper-root": {
                  boxShadow: "rgba(140, 152, 164, 0.13) 0 6px 24px 0",
                  borderRadius: 0
                },
                "& ul": {
                  borderTop: theme => `3px solid ${theme.palette.primary.main}`
                }
              }}
            >
              <MenuItem
                disableRipple
                onClick={() => {
                  handleClose();
                  navigate("/comptes/groupes");
                }}
                sx={{
                  color: matchPathname(location, "/comptes/groupes") ? "#1976d2" : "inherit"
                }}
              >
                Groupes
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => {
                  handleClose();
                  navigate("/comptes/utilisateurs");
                }}
                sx={{
                  color: matchPathname(location, "/comptes/utilisateurs") ? "#1976d2" : "inherit"
                }}
              >
                Utilisateurs
              </MenuItem>
            </Menu>
          </>
        ) : (
          <StyledButton
            active={matchPathname(location, "/comptes/utilisateurs")}
            onClick={() => navigate("/comptes/utilisateurs")}
            disabled={!user.getIn(["rights", "users", "view"])}
            sx={{"&:disabled": {border: "none"}}}
            startIcon={
              user.getIn(["rights", "users", "view"]) ? (
                <GroupOutlinedIcon
                  sx={{
                    fontSize: "24px !important",
                    marginTop: "-2px"
                  }}
                />
              ) : (
                <LockOutlinedIcon
                  sx={{
                    fontSize: "24px !important",
                    marginTop: "-2px",
                    color: "rgba(0, 0, 0, 0.26) !important"
                  }}
                />
              )
            }
          >
            Utilisateurs
          </StyledButton>
        )}
      </ButtonGroup>
      <ButtonGroup sx={{position: "absolute", right: "8px"}}>
        {/*<Tooltip title="Journal des activités" arrow>*/}
        {/*  <IconButton aria-label="history" onClick={() => navigate("/journal-des-activites")} sx={{*/}
        {/*    color: theme => matchPathname(location, "/journal-des-activites") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",*/}
        {/*    "&:hover": {*/}
        {/*      backgroundColor: "transparent"*/}
        {/*    }*/}
        {/*  }}>*/}
        {/*    <FeedIcon/>*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        <Tooltip title="Besoin d’aide ?" arrow>
          <IconButton aria-label="help" onClick={() => navigate("/besoin-d-aide")} sx={{
            color: theme => matchPathname(location, "/besoin-d-aide") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
            "&:hover": {
              backgroundColor: "transparent"
            }
          }}>
            <HelpCenterIcon/>
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}

export default HorizontalNavigation;
