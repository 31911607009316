import React, {useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';

import {authProtectedRoutes, publicRoutes} from './allRoutes';
import Layout from '../_components/Layout/Layout';
import Error from '../_scenes/Error/Error';
import AccessDeniedCheck from '../_components/AccessDeniedCheck/AccessDeniedCheck';

const Index = () => {
  return useMemo(() => (
    <Routes>
      {publicRoutes.map((route, index) => (
        <Route
          key={`public-${index}`}
          {...route}
        />
      ))}
      <Route path="/" element={<Layout/>}>
        {authProtectedRoutes.map((route, index) => (
          <Route
            key={`protected-${index}`}
            {...route}
            element={
            <AccessDeniedCheck>
              {route.element}
            </AccessDeniedCheck>
          }
          />
        ))}
      </Route>
      <Route path="*" element={<Error/>}/>
    </Routes>
  ), []);
};

export default Index;