import {createSelector} from 'reselect';

export const selectRightsGroupList = createSelector([
  state => state.getIn(["scenes", "rightsGroupList", "pageLoaded"]),
  state => state.getIn(["scenes", "rightsGroupList", "tableLoading"]),
  state => state.getIn(["scenes", "rightsGroupList", "resources", "groups"]),
  state => state.getIn(["scenes", "rightsGroupList", "filters"])
], (pageLoaded, tableLoading, rightsGroups, filters) => ({
  pageLoaded,
  tableLoading,
  rightsGroups,
  filters
}));