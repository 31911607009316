import {object, string} from 'yup';

const validationSchema = () => {
  return (
    object().shape({
      name: string().required()
    })
  )
};

export default validationSchema;