import React, {useCallback, useEffect, useState} from 'react';
import {TwitterPicker} from 'react-color';

function ColorPickerType({field, form}) {
  const [open, setOpen] = useState(false);

  // Permet de fermer l'overlay plutôt que de submit le formulaire en tapant sur "Entrée"
  useEffect(() => {
    const handleEnter = e => {
      if (e.key === "Enter") {
        e.preventDefault();
        setOpen(false);
      }
    }
    if (open) {
      window.addEventListener("keypress", handleEnter);
      return () => {
        window.removeEventListener("keypress", handleEnterWhenFocus);
        window.removeEventListener("keypress", handleEnter);
      };
    }
  }, [open]);

  const handleEnterWhenFocus = useCallback(e => {
    if (e.key === "Enter") {
      e.preventDefault();
      setOpen(true);
    }
  }, []);

  return (
    <>
      <span>Couleur</span>
      <div
        tabIndex="0"
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: "30px",
          height: "30px",
          borderRadius: "4px",
          backgroundColor: field.value,
          border: "1px solid #282828",
          boxSizing: "border-box"
        }}
        onClick={() => setOpen(open => !open)}
        onFocus={() => {
          window.removeEventListener("keypress", handleEnterWhenFocus);
          window.addEventListener("keypress", handleEnterWhenFocus);
        }}
      >
        <span style={{position: "relative", left: "35px"}}>{field.value}</span>
        {open && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1100
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "33px",
                left: "-5px",
                zIndex: 1101
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <TwitterPicker
                colors={["#282828", "#FF1744", "#D500F9", "#651FFF", "#2979FF", "#00B75E", "#FF9100", "#795548"]}
                color={field.value}
                onChange={color => form.setFieldValue(field.name, color.hex.toUpperCase())}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ColorPickerType;