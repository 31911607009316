import actionTypes from './actionTypes';

export const productListFetch = () => ({
  type: actionTypes.PRODUCT_LIST_FETCH
});

export const productListFetchSuccess = () => ({
  type: actionTypes.PRODUCT_LIST_FETCH_SUCCESS
});

export const productListReset = () => ({
  type: actionTypes.PRODUCT_LIST_RESET
});

export const productListUpdate = params => ({
  type: actionTypes.PRODUCT_LIST_UPDATE,
  payload: {
    params
  }
});

export const productListUpdateSuccess = payload => ({
  type: actionTypes.PRODUCT_LIST_UPDATE_SUCCESS,
  payload
});