import {all, fork} from 'redux-saga/effects';

import watchApp from './app/sagas';
import watchAuth from './auth/sagas';
import watchRightsGroupList from '../_scenes/RightsGroupList/_store/sagas';
import watchRightsGroupForm from '../_scenes/RightsGroupForm/_store/sagas';
import watchUserList from '../_scenes/UserList/_store/sagas';
import watchUserForm from '../_scenes/UserForm/_store/sagas';
import watchProductList from '../_scenes/ProductList/_store/sagas';
import watchProductForm from '../_scenes/ProductForm/_store/sagas';
import watchProductView from '../_scenes/ProductView/_store/sagas';
import watchServiceList from '../_scenes/ServiceList/_store/sagas';
import watchServiceForm from '../_scenes/ServiceForm/_store/sagas';
import watchServiceView from '../_scenes/ServiceView/_store/sagas';
import watchTicketList from '../_scenes/TicketList/_store/sagas';
import watchTicketForm from '../_scenes/TicketForm/_store/sagas';

function* rootSaga() {
  yield all([
    fork(watchApp),
    fork(watchAuth),
    fork(watchRightsGroupList),
    fork(watchRightsGroupForm),
    fork(watchUserList),
    fork(watchUserForm),
    fork(watchProductList),
    fork(watchProductForm),
    fork(watchProductView),
    fork(watchServiceList),
    fork(watchServiceForm),
    fork(watchServiceView),
    fork(watchTicketList),
    fork(watchTicketForm)
  ])
}

export default rootSaga;