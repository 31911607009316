import {styled} from '@mui/material/styles';
import {API_MANAGER} from "../../_constants/api";

export const StyledServiceHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "1rem 1rem 0 1rem",
  backgroundColor: "#f3f0f9",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px"
});

export const StyledServiceImg = styled("div")(({path}) => ({
  flex: "0 0 auto",
  width: path ? "150px" : 0,
  height: "150px",
  borderRadius: "8px",
  border: path ? "3px solid #e1d8f1" : "none",
  marginLeft: path ? 0 : "-1rem",
  backgroundColor: "#e1d8f1",
  backgroundImage: path ? `url(${API_MANAGER}/${path})` : "none",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat"
}));

export const StyledServiceInfos = styled("div")({
  flex: "1 1 auto",
  padding: "0 1rem",
  lineHeight: "1.8"
});

export const StyledServiceEditButton = styled("div")({
  flex: "0 0 auto",
  alignSelf: "flex-start"
});

export const StyledStatus = styled("span")(({bgColor, color}) => ({
  backgroundColor: bgColor,
  color,
  borderRadius: "4px",
  padding: "2px 4px"
}));