import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';


import {StyledCollapseRights} from '../styled';

function CollapseRights({name, children}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid item xs={12} onClick={() => setOpen(open => !open)} sx={{cursor: "pointer"}}>
        <ul style={{padding: 0, margin: 0, listStylePosition: "inside"}}>
          <Typography color="primary" sx={{fontWeight: 700}}>
            <li style={{
              backgroundColor: "#f3f0f9",
              borderRadius: "4px",
              padding: "4px 8px"
            }}>
              {open ? <KeyboardArrowUpOutlinedIcon sx={{mb: "-6px", mr: "5px"}}/> : <KeyboardArrowDownOutlinedIcon sx={{mb: "-6px", mr: "5px"}}/>}{name}
            </li>
          </Typography>
        </ul>
      </Grid>
      <StyledCollapseRights item xs={12} open={open}>
        <div style={{overflow: "hidden"}}>
          {children}
        </div>
      </StyledCollapseRights>
    </>
  );
}

export default CollapseRights;