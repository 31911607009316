import React, {Fragment, useState} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Phone, Send} from '@mui/icons-material';

import logs2024 from './_changelogs/2024.json';
import {a11yProps} from '../../_helpers/utils';
import TabPanel from '../../_components/TabPanel/TabPanel';

function HelperDesk() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{mb: 2}}>Besoin d’aide ?</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
            <Typography gutterBottom variant="h4" component="div">
              Journal des mises à jour du Manager
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{mb: 2}}>
              Retrouvez ici l’ensemble des changements notables triés chronologiquement.
            </Typography>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
              <Tabs value={value} onChange={handleChange} aria-label="tabs logs">
                <Tab label="2024" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {logs2024.map((log, index) => (
                <Fragment key={index}>
                  <Typography gutterBottom variant="h5" component="div">
                    {`${log.service ? `${log.service}: ` : ""}${log.version} (${log.date})`}
                  </Typography>
                  {log.added && (
                    <Box>
                      <Chip label={"Nouveauté"} color="primary"/>
                      <Typography variant="body2" component={"div"}>
                        <ul>
                          {log.added?.map((item, indexItem) => {
                            return (
                              <li key={indexItem}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </Typography>
                    </Box>
                  )}
                  {log.changed && (
                    <Box>
                      <Chip label={"Modification"} color="warning"/>
                      <Typography variant="body2" component="div">
                        <ul>
                          {log.changed?.map((item, indexItem) => {
                            return (
                              <li key={indexItem}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </Typography>
                    </Box>
                  )}
                  {log.removed && (
                    <Box>
                      <Chip label={"Suppression"} color="error"/>
                      <Typography variant="body2" component="div">
                        <ul>
                          {log.removed?.map((item, indexItem) => {
                            return (
                              <li key={indexItem}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </Typography>
                    </Box>
                  )}
                </Fragment>
              ))}
            </TabPanel>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <Typography gutterBottom variant="h4" component="div">
                  Notice d’utilisation
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 2}}>
                  Vous pouvez consulter notre documentation en cliquant sur le lien ci-dessous.
                </Typography>
                <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "16px"}}/>
                <Typography variant="body2" component="div" sx={{textAlign: "center"}}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => window.open("https://www.cartelmatic.com/documentations/")}
                  >
                    <div style={{marginTop: "1px", marginBottom: "-1px"}}>Voir la documentation</div>
                  </Button>
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <Typography gutterBottom variant="h4" component="div">
                  Une question ? Un problème ?
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{mb: 2}}>
                  Contactez notre support du lundi au jeudi de 9h à 18h et le vendredi de 9h à 17h.
                </Typography>
                <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "16px"}}/>
                <Typography variant="body2" component="div">
                  <List
                    sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton onClick={() => window.open("tel:+299127212")}>
                      <ListItemIcon>
                        <Phone/>
                      </ListItemIcon>
                      <ListItemText primary="02 99 12 72 12"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => window.location = "mailto:yann.lhenoret@cartelmatic.com"}>
                      <ListItemIcon>
                        <Send/>
                      </ListItemIcon>
                      <ListItemText primary="yann.lhenoret@cartelmatic.com"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => window.location = "mailto:christophe.perrussel@cartelmatic.com"}>
                      <ListItemIcon>
                        <Send/>
                      </ListItemIcon>
                      <ListItemText primary="christophe.perrussel@cartelmatic.com"/>
                    </ListItemButton>
                  </List>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default HelperDesk;