import React from 'react';
import {styled} from '@mui/material/styles';

const Container = styled("div")(({bgColor, color, style}) => ({
  borderRadius: "4px",
  padding: "3px 6px",
  width: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: bgColor,
  color,
  ...style
}));

function Status({bgColor = "#3f51b5", color = "#ffffff", label = "", style = {}}) {
  return (
    <Container bgColor={bgColor} color={color} style={style}>
      {label}
    </Container>
  );
}

export default Status;