import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Drawer as MuiDrawer} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import FeedIcon from '@mui/icons-material/Feed';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PermDeviceInformationOutlinedIcon from '@mui/icons-material/PermDeviceInformationOutlined';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {matchPathname} from '../../_helpers/utils';
import {useSelector} from 'react-redux';
import {selectAuthUser} from '../../_store/auth/selectors';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function Drawer({drawerOpen, setDrawerOpen}) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectAuthUser);
  const [accountsOpen, setAccountsOpen] = useState(false);

  const navigateAndCloseDrawer = to => {
    setDrawerOpen(false);
    navigate(to);
  };

  return (
    <MuiDrawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box
        sx={{width: 250}}
        role="presentation"
      >
        <List disablePadding>
          {/*<ListItemButton onClick={() => navigateAndCloseDrawer("/tableau-de-bord")}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <DashboardOutlinedIcon sx={{*/}
          {/*      color: matchPathname(location, "/tableau-de-bord") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",*/}
          {/*      marginTop: "-2px"*/}
          {/*    }}/>*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="Tableau de bord" disableTypography sx={{*/}
          {/*    fontWeight: 500,*/}
          {/*    color: matchPathname(location, "/tableau-de-bord") ? "#1976d2" : "inherit"*/}
          {/*  }}/>*/}
          {/*</ListItemButton>*/}
          <ListItemButton
            onClick={() => navigateAndCloseDrawer("/equipements")}
            disabled={!user.getIn(["rights", "products", "view"])}
          >
            <ListItemIcon>
              {user.getIn(["rights", "products", "view"]) ? (
                <PermDeviceInformationOutlinedIcon sx={{
                  color: matchPathname(location, "/equipements") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                  marginTop: "-2px"
                }}/>
              ) : (
                <LockOutlinedIcon
                  sx={{
                    color: matchPathname(location, "/equipements") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                    marginTop: "-2px"
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText primary="Équipements" disableTypography
                          sx={{fontWeight: 500, color: matchPathname(location, "/equipements") ? "#1976d2" : "inherit"}}/>
          </ListItemButton>
          <ListItemButton onClick={() => navigateAndCloseDrawer("/services")}>
            <ListItemIcon>
              <InstallMobileOutlinedIcon sx={{
                color: matchPathname(location, "/services") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                marginTop: "-2px"
              }}/>
            </ListItemIcon>
            <ListItemText primary="Services" disableTypography
                          sx={{fontWeight: 500, color: matchPathname(location, "/services") ? "#1976d2" : "inherit"}}/>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigateAndCloseDrawer("/statistiques")}
            disabled={!user.getIn(["rights", "analytics", "view"])}
          >
            <ListItemIcon>
              {user.getIn(["rights", "analytics", "view"]) ? (
                <AssessmentOutlinedIcon sx={{
                  color: matchPathname(location, "/statistiques") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                  marginTop: "-2px"
                }}/>
              ) : (
                <LockOutlinedIcon
                  sx={{
                    color: matchPathname(location, "/statistiques") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                    marginTop: "-2px"
                  }}
                />
              )}
            </ListItemIcon>
            <ListItemText primary="Statistiques" disableTypography sx={{
              fontWeight: 500,
              color: matchPathname(location, "/statistiques") ? "#1976d2" : "inherit"
            }}/>
          </ListItemButton>
          {/*<ListItemButton onClick={() => navigateAndCloseDrawer("/tickets")}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <ConfirmationNumberOutlinedIcon sx={{*/}
          {/*      color: matchPathname(location, "/tickets") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",*/}
          {/*      marginTop: "-2px"*/}
          {/*    }}/>*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="Tickets" disableTypography*/}
          {/*                sx={{fontWeight: 500, color: matchPathname(location, "/tickets") ? "#1976d2" : "inherit"}}/>*/}
          {/*</ListItemButton>*/}
          {user.getIn(["rights", "isSuperAdminOrCartel"]) ? (
            <>
              <ListItemButton
                onClick={() => setAccountsOpen(accountsOpen => !accountsOpen)}
              >
                <ListItemIcon>
                  <GroupOutlinedIcon sx={{
                    color: matchPathname(location, "/comptes") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                    marginTop: "-2px"
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Comptes" disableTypography
                              sx={{fontWeight: 500, color: matchPathname(location, "/comptes") ? "#1976d2" : "inherit"}}/>
                {accountsOpen ? <KeyboardArrowUpIcon
                    sx={{color: matchPathname(location, "/comptes") ? "#1976d2" : "rgba(0, 0, 0, 0.7)"}}/> :
                  <KeyboardArrowDownIcon
                    sx={{color: matchPathname(location, "/comptes") ? "#1976d2" : "rgba(0, 0, 0, 0.7)"}}/>}
              </ListItemButton>
              <Collapse in={accountsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton onClick={() => navigateAndCloseDrawer("/comptes/groupes")} sx={{pl: 4}}>
                    <ListItemText primary="Groupes"
                                  sx={{color: matchPathname(location, "/comptes/groupes") ? "#1976d2" : "inherit"}}/>
                  </ListItemButton>
                  <ListItemButton onClick={() => navigateAndCloseDrawer("/comptes/utilisateurs")} sx={{pl: 4}}>
                    <ListItemText primary="Utilisateurs"
                                  sx={{color: matchPathname(location, "/comptes/utilisateurs") ? "#1976d2" : "inherit"}}/>
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton
              onClick={() => navigateAndCloseDrawer("/comptes/utilisateurs")}
              disabled={!user.getIn(["rights", "users", "view"])}
            >
              <ListItemIcon>
                {user.getIn(["rights", "users", "view"]) ? (
                  <GroupOutlinedIcon sx={{
                    color: matchPathname(location, "/comptes/utilisateurs") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                    marginTop: "-2px"
                  }}/>
                ) : (
                  <LockOutlinedIcon
                    sx={{
                      color: matchPathname(location, "/comptes/utilisateurs") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                      marginTop: "-2px"
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Utilisateurs" disableTypography
                            sx={{fontWeight: 500, color: matchPathname(location, "/comptes/utilisateurs") ? "#1976d2" : "inherit"}}/>
            </ListItemButton>
          )}
        </List>
        <Divider/>
        <List disablePadding>
          {/*<ListItemButton onClick={() => navigateAndCloseDrawer("/journal-des-activites")}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <FeedIcon sx={{*/}
          {/*      color: matchPathname(location, "/journal-des-activites") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",*/}
          {/*      marginTop: "-2px"*/}
          {/*    }}/>*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="Journal des activités" disableTypography sx={{*/}
          {/*    fontWeight: 500,*/}
          {/*    color: matchPathname(location, "/journal-des-activites") ? "#1976d2" : "inherit"*/}
          {/*  }}/>*/}
          {/*</ListItemButton>*/}
          <ListItemButton onClick={() => navigateAndCloseDrawer("/besoin-d-aide")}>
            <ListItemIcon>
              <HelpCenterIcon sx={{
                color: matchPathname(location, "/besoin-d-aide") ? "#1976d2" : "rgba(0, 0, 0, 0.7)",
                marginTop: "-2px"
              }}/>
            </ListItemIcon>
            <ListItemText primary="Besoin d’aide ?" disableTypography sx={{
              fontWeight: 500,
              color: matchPathname(location, "/besoin-d-aide") ? "#1976d2" : "inherit"
            }}/>
          </ListItemButton>
        </List>
      </Box>
    </MuiDrawer>
  );
}

export default Drawer;