import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {productViewUpdateServices} from '../_store/actions';
import {selectProductView} from '../_store/selectors';
import TableContainer from '../../../_components/TableContainer/TableContainer';
import useAdminRedirect from '../../../_hooks/useAdminRedirect';
import {fromJS} from 'immutable';
import {appAction} from '../../../_store/app/actions';
import {selectAuthUser} from '../../../_store/auth/selectors';

export const getStatus = status => {
  const style = {
    borderRadius: "4px",
    padding: "3px 6px",
    width: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  };
  switch (status.color) {
    case "success":
      return <div style={{...style, backgroundColor: "#2e7d32", color: "#ffffff"}}>{status.name}</div>;
    case "orange":
      return <div style={{...style, backgroundColor: "#ff7905", color: "#ffffff"}}>{status.name}</div>;
    default:
      <div style={{...style, backgroundColor: "#c7c7c7", color: "rgba(0, 0, 0, 0.75)"}}>{status.name}</div>;
  }
}

function Services() {
  const dispatch = useDispatch();
  const {services, filters, tableLoading, product} = useSelector(selectProductView);
  const firstRender = useRef(true);
  const handleAdminRedirect = useAdminRedirect();
  const user = useSelector(selectAuthUser);

  const columns = useMemo(() => {
    return [
      {
        Header: "Nom",
        accessor: "name",
        sortable: true,
        sortableName: "name",
        Cell: cell => {
          let disabled = false;
          switch (cell.row.original.article.id) {
            case 5:
              disabled = !user.getIn(["rights", "baliz", "view"]);
              break;
            case 6:
              disabled = !user.getIn(["rights", "funbox", "view"]);
              break;
            case 7:
              disabled = !user.getIn(["rights", "data", "view"]);
              break;
            default:
          }
          return !disabled ? (
            <Link
              component={RouterLink}
              to={`/services/${cell.row.original.id}`}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              {cell.value}
            </Link>
          ) : cell.value;
        }
      },
      {
        Header: "Type",
        fixWidth: 400,
        Cell: cell => cell.row.original.article.name
      },
      {
        Header: "Statut",
        fixWidth: 300,
        Cell: cell => getStatus(cell.row.original.articleStatus)
      },
      {
        Header: "Actions",
        fixWidth: 353,
        Cell: cell => {
          const service = cell.row.original;
          let disabled = false;
          switch (service.article.id) {
            case 5:
              disabled = !user.getIn(["rights", "baliz", "edit"]);
              break;
            case 6:
              disabled = !user.getIn(["rights", "funbox", "edit"]);
              break;
            case 7:
              disabled = !user.getIn(["rights", "data", "edit"]);
              break;
            default:
          }
          if (service.article.name === "Analytics") {
            return (
              <Box sx={{float: "right"}}>
              <Button
                variant="contained"
                size="small"
                color="action"
                disableRipple
                onClick={() => console.log("Synchroniser")}
              >
                <div style={{marginTop: "1px", marginBottom: "-1px"}}>Synchroniser</div>
              </Button>
              </Box>
            )
          } else {
            return (
              <Box sx={{float: "right"}}>
                <Button
                  variant="contained"
                  size="small"
                  color="action"
                  disableRipple
                  onClick={() => handleAdminRedirect(fromJS({
                    article: {id: service.article.id},
                    reference: service.reference,
                    apiKey: service.apiKey
                  }))}
                  disabled={disabled}
                  sx={{mr: 1}}
                >
                  <div style={{marginTop: "1px", marginBottom: "-1px"}} >Back-office</div>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="action"
                  disableRipple
                  onClick={() => dispatch(appAction("publish_service_on_product", `publish_${service.reference}_${product.get("reference")}`, {
                    service: fromJS(service),
                    product: product
                  }))}
                >
                  <div style={{marginTop: "1px", marginBottom: "-1px"}}>Mettre à jour</div>
                </Button>
              </Box>
            )
          }
        }
      }
    ];
  }, [user]);

  const fetchData = useCallback(params => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      dispatch(productViewUpdateServices(params));
    }
  }, [dispatch]);

  return (
    <TableContainer
      id="table-container-product-service-list"
      columns={columns}
      data={services.get("members").toJS()}
      count={services.get("count")}
      pageCount={services.get("pageCount")}
      customPageSize={20}
      fetchData={fetchData}
      filters={filters.toJS()}
      loading={tableLoading}
      defaultSort={{sortDirection: "asc", accessor: "name"}}
    />
  );
}

export default Services;