import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  error: null,
  appLoaded: false,
  validating: false,
  trackLocation: {
    current: null,
    prev: null
  },
  actions: {},
  analytics : null,
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case actionTypes.ERROR:
      return state.set("error", {
        code: payload.code,
        message: payload.message
      });
    case actionTypes.APP_FETCH_SUCCESS:
      return state.set("appLoaded", true);
    case actionTypes.APP_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    case actionTypes.APP_RESET:
      return initialState;
    case actionTypes.APP_VALID_DATA_AND_PUBLISH:
    case actionTypes.APP_VALID_DATA:
      return state.set("validating", true);
    case actionTypes.APP_VALID_DATA_FINISHED:
      return state.set("validating", false);
    case actionTypes.APP_TRACK_LOCATION:
      return state.set("trackLocation", fromJS({
        current: payload.location,
        prev: state.getIn(["trackLocation", "current"])
      }));
    case actionTypes.APP_ACTION:
      return state.setIn(["actions", payload.actionName, payload.key], fromJS({
        inProgress: true
      }));
    case actionTypes.APP_ACTION_COMPLETED:
      return state.setIn(["actions", payload.actionName, payload.key], fromJS({
        inProgress: false
      }));
    case actionTypes.APP_SET_ANALYTICS:
      return state.set("analytics", fromJS(payload.value));
    default:
      return state;
  }
};

export default reducer;
