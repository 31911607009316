import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import useAdminRedirect from '../../../_hooks/useAdminRedirect';
import useVisualizationRedirect from '../../../_hooks/useVisualizationRedirect';
import {selectAuthUser} from '../../../_store/auth/selectors';

const getColor = service => {
  switch (service.getIn(["article", "id"])) {
    case 5:
      return ({
        color: "#213446",
        textDecorationColor: "#213446",
        backgroundColor: "rgba(33, 52, 70, 0.3)",
        borderRadius: "4px",
        padding: "1px 6px 0 6px"
      });
    case 6:
      return ({
        color: "#2196f3",
        textDecorationColor: "#2196f3",
        backgroundColor: "rgba(33, 150, 243, 0.3)",
        borderRadius: "4px",
        padding: "1px 6px 0 6px"
      });
    case 7:
      return ({
        color: "#146152",
        textDecorationColor: "#146152",
        backgroundColor: "rgba(20, 97, 82, 0.3)",
        borderRadius: "4px",
        padding: "1px 6px 0 6px"
      });
    default:
      return ({
        color: "#6d4c41",
        textDecorationColor: "#6d4c41",
        backgroundColor: "rgba(109, 76, 65, 0.3)",
        borderRadius: "4px",
        padding: "1px 6px 0 6px"
      });
  }
}

const getName = {
  "Application Baliz": "Baliz",
  "Application Funbox": "Funbox App",
  "Application V4": "V4",
  "Gestionnaire de données (DATA)": "Data"
}

const StyledButton = styled(Box)(({service, disabled = false}) => ({
  display: "flex",
  alignItems: "center",
  cursor: disabled ? "initial" : "pointer",
  margin: "0 5px 5px 0",
  fontSize: "0.875rem",
  userSelect: "none",
  opacity: disabled ? 0.5 : 1,
  ...getColor(service)
}));

const StyledMenuTitle = styled(({children, ...rest}) => (
  <Box {...rest}>
    {children}
  </Box>
))(({theme}) => ({
  color: theme.palette.primary.main,
  padding: "6px 16px",
  fontSize: "0.875rem",
  fontWeight: "bold"
}));

const StyledMenuItem = styled(MenuItem)({
  fontSize: "0.875rem"
});

function ServiceWithMenu({product, service}) {
  const handleAdminRedirect = useAdminRedirect();
  const handleVisualizationRedirect = useVisualizationRedirect();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(selectAuthUser);

  // Si le service est de type Baliz ou Funbox App alors on ajoute un menu
  return [5, 6].includes(service.getIn(["article", "id"])) ? (
    <>
      <StyledButton
        id={`service-${service.get("id")}-button`}
        onClick={e => setAnchorEl(e.currentTarget)}
        aria-controls={Boolean(anchorEl) ? `service-${service.get("id")}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        service={service}
      >
        <Tooltip title={service.get("name")} arrow enterDelay={500} disableInteractive>
          <span style={{fontWeight: "bold"}}>
            {getName[service.getIn(["article", "name"])]}
          </span>
        </Tooltip>
      </StyledButton>
      <Menu
        id={`service-${service.get("id")}-menu`}
        aria-labelledby={`service-${service.get("id")}-button`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        sx={{
          "& ul": {
            padding: 0
          }
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <StyledMenuTitle>
          {service.get("name")}
        </StyledMenuTitle>
        <StyledMenuItem
          onClick={() => {
            setAnchorEl(null);
            setTimeout(() => handleAdminRedirect(service), 200);
          }}
          disableRipple
          disabled={
            (service.getIn(["article", "id"]) === 5 && !user.getIn(["rights", "baliz", "edit"])) ||
            (service.getIn(["article", "id"]) === 6 && !user.getIn(["rights", "funbox", "edit"]))
          }
        >
          Back-office
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            setAnchorEl(null);
            setTimeout(() => handleVisualizationRedirect(product, service), 200);
          }}
          disableRipple
        >
          Visualiser
        </StyledMenuItem>
      </Menu>
    </>
  ) : (
    <StyledButton
      id={`service-${service.get("id")}-button`}
      onClick={() => {
        if (!(service.getIn(["article", "id"]) === 7 && !user.getIn(["rights", "data", "edit"]))) {
          setTimeout(() => handleAdminRedirect(service), 200);
        }
      }}
      service={service}
      disabled={service.getIn(["article", "id"]) === 7 && !user.getIn(["rights", "data", "edit"])}
    >
      <Tooltip title={service.get("name")} arrow enterDelay={500} disableInteractive>
            <span style={{fontWeight: "bold"}}>
              {getName[service.getIn(["article", "name"])]}
            </span>
      </Tooltip>
    </StyledButton>
  )
}

export default ServiceWithMenu;