import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  pageLoaded: false,
  isSubmitted: false,
  isSubmitting: false
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actionTypes.USER_FORM_FETCH_SUCCESS:
      return state.set("pageLoaded", true);
    case actionTypes.USER_FORM_RESET:
      return initialState;
    case actionTypes.USER_FORM_SAVE:
      return state.set("isSubmitting", true);
    case actionTypes.USER_FORM_SAVE_SUCCESS:
      return state.merge({isSubmitted: true, isSubmitting: false});
    case actionTypes.USER_FORM_SAVE_FAILURE:
      return state.set("isSubmitting", false);
    case actionTypes.USER_FORM_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    default:
      return state;
  }
};

export default reducer;