import React from 'react';
import {Navigate} from 'react-router-dom';

import SignIn from '../_scenes/SignIn/SignIn';
// import DataLogList from '../_scenes/DataLogList/DataLogList';
import Dashboard from '../_scenes/Dashboard/Dashboard';
import HelperDesk from '../_scenes/HelperDesk/HelperDesk';
import ProductList from '../_scenes/ProductList/ProductList';
import ProductForm from '../_scenes/ProductForm/ProductForm';
import ServiceList from '../_scenes/ServiceList/ServiceList';
import ServiceForm from '../_scenes/ServiceForm/ServiceForm';
import Statistics from '../_scenes/Statistics/Statistics';
import RightsGroupList from '../_scenes/RightsGroupList/RightsGroupList';
import RightsGroupForm from '../_scenes/RightsGroupForm/RightsGroupForm';
import Error from '../_scenes/Error/Error';
import UserList from '../_scenes/UserList/UserList';
import UserForm from '../_scenes/UserForm/UserForm';
import AccountActivation from '../_scenes/AccountActivation/AccountActivation';
import ProductView from '../_scenes/ProductView/ProductView';
import ServiceView from '../_scenes/ServiceView/ServiceView';
// import TicketList from '../_scenes/TicketList/TicketList';
// import TicketForm from '../_scenes/TicketForm/TicketForm';
import AccessDenied from '../_scenes/AccessDenied/AccessDenied';

const authProtectedRoutes = [
  {index: true, element: <Navigate to="equipements" replace/>},
  {path: "tableau-de-bord", element: <Dashboard/>},
  // {path: "journal-des-activites", element: <DataLogList/>},
  {path: "besoin-d-aide", element: <HelperDesk/>},
  {path: "equipements", element: <ProductList/>},
  {path: "equipements/edition/:id", element: <ProductForm/>},
  {path: "equipements/:id", element: <ProductView/>},
  {path: "services", element: <ServiceList/>},
  {path: "services/edition/:id", element: <ServiceForm/>},
  {path: "services/:id", element: <ServiceView/>},
  {path: "statistiques", element: <Statistics/>},
  // {path: "tickets", element: <TicketList/>},
  // {path: "tickets/ajout", element: <TicketForm/>},
  {path: "comptes/groupes", element: <RightsGroupList/>},
  {path: "comptes/groupes/ajout", element: <RightsGroupForm/>},
  {path: "comptes/groupes/edition/:id", element: <RightsGroupForm/>},
  {path: "comptes/utilisateurs", element: <UserList/>},
  {path: "comptes/utilisateurs/ajout", element: <UserForm/>},
  {path: "comptes/utilisateurs/edition/:id", element: <UserForm/>},
  {path: "acces-refuse", element: <AccessDenied/>}
];

const publicRoutes = [
  {path: "/connexion", element: <SignIn/>},
  {path: "/activation", element: <AccountActivation/>},
  {path: "/reinitialisation-mot-de-passe", element: <AccountActivation/>},
  {path: "/erreur", element: <Error/>}
];

export {authProtectedRoutes, publicRoutes};