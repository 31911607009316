const actionTypes = {
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    GET_ITEM: "GET_ITEM",
    GET_ITEM_BY_ID: "GET_ITEM_BY_ID",
    POST_ITEM: "POST_ITEM",
    PUT_ITEM: "PUT_ITEM",
    DELETE_ITEM: "DELETE_ITEM",
    DELETE_ITEM_BY_ID: "DELETE_ITEM_BY_ID"
};

export default actionTypes;
