import actionTypes from './actionTypes';

export const serviceViewFetch = id => ({
  type: actionTypes.SERVICE_VIEW_FETCH,
  payload: {
    id
  }
});

export const serviceViewFetchSuccess = () => ({
  type: actionTypes.SERVICE_VIEW_FETCH_SUCCESS
});

export const serviceViewReset = () => ({
  type: actionTypes.SERVICE_VIEW_RESET
});

export const serviceViewUpdateProducts = params => ({
  type: actionTypes.SERVICE_VIEW_UPDATE_PRODUCTS,
  payload: {
    params
  }
});

export const serviceViewUpdateProductsSuccess = payload => ({
  type: actionTypes.SERVICE_VIEW_UPDATE_PRODUCTS_SUCCESS,
  payload
});

export const serviceViewUpdateTickets = params => ({
  type: actionTypes.SERVICE_VIEW_UPDATE_TICKETS,
  payload: {
    params
  }
});

export const serviceViewUpdateTicketsSuccess = payload => ({
  type: actionTypes.SERVICE_VIEW_UPDATE_TICKETS_SUCCESS,
  payload
});