import actionTypes from './actionTypes';

export const ticketListFetch = () => ({
  type: actionTypes.TICKET_LIST_FETCH
});

export const ticketListFetchSuccess = () => ({
  type: actionTypes.TICKET_LIST_FETCH_SUCCESS
});

export const ticketListReset = () => ({
  type: actionTypes.TICKET_LIST_RESET
});

export const ticketListUpdate = params => ({
  type: actionTypes.TICKET_LIST_UPDATE,
  payload: {
    params
  }
});

export const ticketListUpdateSuccess = payload => ({
  type: actionTypes.TICKET_LIST_UPDATE_SUCCESS,
  payload
});

export const ticketListSetFilters = filters => ({
  type: actionTypes.TICKET_LIST_SET_FILTERS,
  payload: {
    filters
  }
});