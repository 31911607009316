export default {
  RIGHTS_GROUP_LIST_FETCH: "RIGHTS_GROUP_LIST_FETCH",
  RIGHTS_GROUP_LIST_FETCH_SUCCESS: "RIGHTS_GROUP_LIST_FETCH_SUCCESS",
  RIGHTS_GROUP_LIST_RESET: "RIGHTS_GROUP_LIST_RESET",
  RIGHTS_GROUP_LIST_UPDATE: "RIGHTS_GROUP_LIST_UPDATE",
  RIGHTS_GROUP_LIST_UPDATE_SUCCESS: "RIGHTS_GROUP_LIST_UPDATE_SUCCESS",
  RIGHTS_GROUP_LIST_SET_FILTERS: "RIGHTS_GROUP_LIST_SET_FILTERS",
  RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP: "RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP",
  RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP_SUCCESS: "RIGHTS_GROUP_LIST_DELETE_RIGHTS_GROUP_SUCCESS",
  RIGHTS_GROUP_LIST_STORE_REQUEST: "RIGHTS_GROUP_LIST_STORE_REQUEST"
}