import actionTypes from './actionTypes';

export const serviceFormFetch = id => ({
  type: actionTypes.SERVICE_FORM_FETCH,
  payload: {
    id
  }
});

export const serviceFormFetchSuccess = () => ({
  type: actionTypes.SERVICE_FORM_FETCH_SUCCESS
});

export const serviceFormReset = () => ({
  type: actionTypes.SERVICE_FORM_RESET
});

export const serviceFormSave = values => ({
  type: actionTypes.SERVICE_FORM_SAVE,
  payload: {
    values
  }
});