import CryptoJS from 'crypto-js';
import {getServiceName} from './utils';
const storageName = "_cfsfbxa_data";
export const setLocalStorage = configs => {
    const configsEncrypt = getLocalStorage("ENV") !== "development" ? CryptoJS.AES.encrypt(JSON.stringify(configs), "b9fplr458jwrv6FlR4Iz") : JSON.stringify(configs);
    localStorage.setItem(`${storageName}-${getServiceName()}`, configsEncrypt);
    return configs;
};

export const getLocalStorage = (name = null) => {
    const localStorageConfig = localStorage.getItem(`${storageName}-${getServiceName()}`);
    if (!localStorageConfig) {
        return null;
    }
    let decryptedData;
    try {
        const bytes = CryptoJS.AES.decrypt(localStorageConfig.toString(), "b9fplr458jwrv6FlR4Iz");
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        decryptedData = JSON.parse(localStorageConfig);
    }
    if (!name) {
        return decryptedData ? decryptedData : null;
    }
    return decryptedData && decryptedData[name] ? decryptedData[name] : null;
};
