export default {
  PRODUCT_VIEW_FETCH: "PRODUCT_VIEW_FETCH",
  PRODUCT_VIEW_FETCH_SUCCESS: "PRODUCT_VIEW_FETCH_SUCCESS",
  PRODUCT_VIEW_RESET: "PRODUCT_VIEW_RESET",
  PRODUCT_VIEW_STORE_REQUEST: "PRODUCT_VIEW_STORE_REQUEST",
  PRODUCT_VIEW_SET_FILTERS: "PRODUCT_VIEW_SET_FILTERS",
  PRODUCT_VIEW_FETCH_CONNECTION: "PRODUCT_VIEW_FETCH_CONNECTION",
  PRODUCT_VIEW_FETCH_CONNECTION_SUCCESS: "PRODUCT_VIEW_FETCH_CONNECTION_SUCCESS",
  PRODUCT_VIEW_UPDATE_SERVICES: "PRODUCT_VIEW_UPDATE_SERVICES",
  PRODUCT_VIEW_UPDATE_SERVICES_SUCCESS: "PRODUCT_VIEW_UPDATE_SERVICES_SUCCESS",
  PRODUCT_VIEW_UPDATE_TICKETS: "PRODUCT_VIEW_UPDATE_TICKETS",
  PRODUCT_VIEW_UPDATE_TICKETS_SUCCESS: "PRODUCT_VIEW_UPDATE_TICKETS_SUCCESS",
  PRODUCT_VIEW_RESET_LOGS: "PRODUCT_VIEW_RESET_LOGS",
  PRODUCT_VIEW_UPDATE_LOGS: "PRODUCT_VIEW_UPDATE_LOGS",
  PRODUCT_VIEW_UPDATE_LOGS_SUCCESS: "PRODUCT_VIEW_UPDATE_LOGS_SUCCESS"
}