import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FastField, Formik} from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Link from '@mui/material/Link';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import {
  rightsGroupListFetch,
  rightsGroupListDeleteRightsGroup,
  rightsGroupListReset,
  rightsGroupListSetFilters,
  rightsGroupListUpdate
} from './_store/actions';
import {selectRightsGroupList} from './_store/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import AutoSave from '../../_components/_form/AutoSave/AutoSave';
import TableContainer from '../../_components/TableContainer/TableContainer';
import useDialog from '../../_hooks/useDialog';
import DeleteDialog from '../../_components/DeleteDialog/DeleteDialog';
import {rolesBaliz, rolesData, rolesManager} from '../../_constants/rightsGroupRoles';
import {selectAuthUser} from '../../_store/auth/selectors';

function RightsGroupList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    pageLoaded,
    tableLoading,
    rightsGroups,
    filters
  } = useSelector(selectRightsGroupList);
  const deleteDialog = useDialog();
  const user = useSelector(selectAuthUser);

  useEffect(() => {
    dispatch(rightsGroupListFetch());
    return () => dispatch(rightsGroupListReset());
  }, []);

  const handleSubmit = values => {
    const params = {
      ...filters.toJS(),
      name: values.search || null
    };
    dispatch(rightsGroupListSetFilters(params));
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const handleDeleteItem = item => {
    setSelectedItem(item);
    deleteDialog.onOpen();
  };

  const handleConfirmDelete = () => {
    deleteDialog.onClose();
    dispatch(rightsGroupListDeleteRightsGroup(selectedItem["@id"]));
    setSelectedItem(null);
  };

  const numberOfRoles = useMemo(() => {
    let number = 0;
    const increaseNumber = row => {
      if (row.group) {
        row.arr.forEach(row => {
          number += row.roles.filter(role => !role.endsWith("_ALL") && role !== "").length;
        });
      } else {
        number += row.roles.filter(role => !role.endsWith("_ALL") && role !== "").length;
      }
    }
    rolesManager.forEach(row => increaseNumber(row));
    rolesBaliz.forEach(row => increaseNumber(row));
    rolesData.forEach(row => increaseNumber(row));
    return number;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Nom",
        accessor: "name",
        filterable: true,
        sortable: true,
        sortableName: "name",
        // fixWidth: 450,
        Cell: cell => {
          return (
            <Link
              component={RouterLink}
              to={`/comptes/groupes/edition/${cell.row.original.id}`}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <span
                style={{
                  marginTop: "4px",
                  marginLeft: "4px",
                  color: cell.row.original.color,
                  textDecoration: "underline",
                  fontWeight: 700
                }}
              >
                {cell.value}
              </span>
            </Link>
          )
        }
      },
      {
        Header: "Nombre de droits",
        accessor: "roles",
        // sortable: true,
        // sortableName: "roles",
        //fixWidth: 200,
        Cell: cell => {
          return `${cell.value.filter(role => !role.endsWith("_ALL")).length}/${numberOfRoles}`
        }
      },
      {
        Header: "Nombre d'utilisateurs",
        accessor: "users",
        // sortable: true,
        // sortableName: "users",
        //fixWidth: 200,
        Cell: cell => {
          return cell.value.length
        }
      },
      {
        Header: "Actions",
        fixWidth: 80,
        Cell: cellProps => {
          return (
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              sx={{float: "right"}}
            >
              <Tooltip title="Éditer" arrow>
                <IconButton
                  aria-label="edit"
                  onClick={() => navigate(`/comptes/groupes/edition/${cellProps.row.original.id}`)}
                  sx={{
                    color: "#ffa000"
                  }}
                >
                  <EditOutlinedIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Supprimer" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteItem(cellProps.row.original)}
                  sx={{
                    color: "#d32f2f"
                  }}
                >
                  <DeleteOutlinedIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          );
        }
      }
    ];
  }, [pageLoaded, numberOfRoles, user]);

  const fetchData = useCallback(params => {
    dispatch(rightsGroupListUpdate(params));
  }, [dispatch]);

  return (
    <>
      <DeleteDialog
        onConfirm={handleConfirmDelete}
        onClose={deleteDialog.onClose}
        open={deleteDialog.open}
        title="Suppression du groupe de droits"
        content={selectedItem ? `Êtes-vous sûr de vouloir supprimer le groupe de droits "${selectedItem.name}" ?` : ""}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{mb: 2}}>Liste des groupes de droits</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
          >
            <Typography key="1" color="inherit">
              Comptes
            </Typography>,
            <Typography key="2" color="text.primary">
              Groupes
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{width: "100%"}}>
            <Paper variant="outlined" elevation={0} sx={{width: "100%"}}>
              <Grid container>
                <Grid item xs={12} sm="auto" order={{xs: 1, sm: 2}}
                      sx={{display: "flex", alignItems: "center", p: 2, pl: {sm: 0}, pb: {xs: 0, sm: 2}}}>
                  <Button
                    variant="contained"
                    color="add"
                    startIcon={<AddIcon/>}
                    onClick={() => navigate("/comptes/groupes/ajout")}
                  >
                    <div style={{marginTop: "1px", marginBottom: "-1px"}}>Nouveau</div>
                  </Button>
                </Grid>
                <Grid item xs={12} sm sx={{p: 2}} order={{xs: 2, sm: 1}}>
                  <Formik
                    initialValues={{
                      search: ""
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                      return new Promise(resolve =>
                        setTimeout(() => {
                          handleSubmit(values);
                          setSubmitting(false);
                          resolve();
                        }, 200)
                      );
                    }}
                  >
                    {formikProps => (
                      <form>
                        <Grid container>
                          <AutoSave debounceMs={300} dirty={formikProps.dirty}/>
                          <Grid item xs={12}>
                            <FastField
                              component={TextType}
                              name="search"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon sx={{color: "action.active"}}/>
                                  </InputAdornment>)
                              }}
                              placeholder="Rechercher par nom..."
                            />
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Grid>
                <Grid item xs={12} order={{xs: 3}}>
                  {pageLoaded && (
                    <TableContainer
                      id="table-container-user-group-list"
                      columns={columns}
                      data={rightsGroups ? rightsGroups.get('members').toJS() : []}
                      pageCount={rightsGroups ? rightsGroups.get("pageCount") : 1}
                      count={rightsGroups ? rightsGroups.get("count") : 0}
                      customPageSize={20}
                      fetchData={fetchData}
                      filters={filters.toJS()}
                      loading={tableLoading}
                      defaultSort={{sortDirection: "asc", accessor: "name"}}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default RightsGroupList;