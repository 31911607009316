import React, {useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {fromJS} from 'immutable';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import TableContainer from '../../../_components/TableContainer/TableContainer';
import {serviceViewUpdateProducts} from '../_store/actions';
import {selectServiceView} from '../_store/selectors';
import {appAction} from '../../../_store/app/actions';
import {getFormattedDate} from '../../../_helpers/date';
import {selectAppAction} from '../../../_store/app/selectors';
import {selectAuthUser} from '../../../_store/auth/selectors';

function Products() {
  const dispatch = useDispatch();
  const {service, products, taskQueues, lastTasks, filters, tableLoading} = useSelector(selectServiceView);
  const publishServiceOnProduct = useSelector(state => selectAppAction(state, service ? ["publish_service_on_product"] : null));
  const user = useSelector(selectAuthUser);
  const firstRender = useRef(true);

  const tasksByProduct = useMemo(() => {
    let tasks = {};
    products.get("members").forEach(product => {
      let task = null;
      taskQueues.get("members").forEach(taskQueue => {
        if (taskQueue.get("product") === product.get("id")) {
          task = taskQueue;
        }
      });
      if (task && task.get("onQueue")) {
        tasks = {
          ...tasks,
          [product.get("id")]: {
            date: task.get("createdAt"),
            status: task.get("status")
          }
        }
      } else {
        lastTasks.get("members").forEach(lastTask => {
          if (lastTask.get("product") === product.get("id")) {
            task = lastTask;
          }
        });
        if (task && task.get("task")) {
          tasks = {
            ...tasks,
            [product.get("id")]: {
              date: task.getIn(["task", "createdAt"]),
              status: task.getIn(["task", "status"])
            }
          }
        }
      }
    });
    return tasks;
  }, [products, taskQueues, lastTasks]);

  const columns = useMemo(() => {
    const getState = status => {
      const style = {
        borderRadius: "4px",
        padding: "3px 6px"
      };
      switch (status) {
        case "success":
          return <span style={{...style, backgroundColor: "#2e7d32", color: "#ffffff"}}>Succès</span>;
        default:
          return <span style={{...style, backgroundColor: "#1e272c", color: "#ffffff"}}>Echec</span>;
      }
    }

    return [
      {
        Header: "Nom",
        accessor: "name",
        filterable: true,
        sortable: true,
        sortableName: "name",
        Cell: cell => {
          if (user.getIn(["rights", "products", "view"])) {
            return (
              <Link
                component={RouterLink}
                to={`/equipements/${cell.row.original.id}`}
                disabled
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                {cell.value}
              </Link>
            )
          } else {
            return cell.value;
          }
        }
      },
      {
        Header: "Dernière publication",
        fixWidth: 400,
        Cell: cell => tasksByProduct[cell.row.original["id"]] ? getFormattedDate(tasksByProduct[cell.row.original["id"]].date, false) : "-"
      },
      {
        Header: "Statut",
        fixWidth: 300,
        Cell: cell => tasksByProduct[cell.row.original["id"]] ? getState(tasksByProduct[cell.row.original["id"]].status) : <span>-</span>
      },
      {
        Header: "Actions",
        fixWidth: 90,
        Cell: cell => {
          return (
            <Button
              variant="contained"
              size="small"
              color="action"
              disableRipple
              disabled={publishServiceOnProduct && publishServiceOnProduct.getIn([`publish_${service.get("reference")}_${cell.row.original.reference}`, "inProgress"])}
              onClick={() => dispatch(appAction("publish_service_on_product", `publish_${service.get("reference")}_${cell.row.original.reference}`, {
                service,
                product: fromJS(cell.row.original)
              }))}
              sx={{float: "right"}}
            >
              Publier
            </Button>
          )
        }
      }
    ];
  }, [tasksByProduct, service, publishServiceOnProduct, user]);

  const fetchData = useCallback(params => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      dispatch(serviceViewUpdateProducts(params));
    }
  }, [dispatch]);

  return (
    <TableContainer
      id="table-container-service-product-list"
      columns={columns}
      data={products.get("members").toJS()}
      count={products.get("count")}
      pageCount={products.get("pageCount")}
      customPageSize={20}
      fetchData={fetchData}
      filters={filters.toJS()}
      loading={tableLoading}
      defaultSort={{sortDirection: "asc", accessor: "name"}}
    />
  );
}

export default Products;