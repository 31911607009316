const constants = {
    ERROR: "ERROR",
    APP_FETCH: "APP_FETCH",
    APP_STORE_REQUEST: "APP_STORE_REQUEST",
    APP_FETCH_SUCCESS: "APP_FETCH_SUCCESS",
    APP_RESET: "APP_RESET",
    APP_VALID_DATA_AND_PUBLISH: "APP_VALID_DATA_AND_PUBLISH",
    APP_VALID_DATA: "APP_VALID_DATA",
    APP_VALID_DATA_FINISHED: "APP_VALID_DATA_FINISHED",
    APP_TRACK_LOCATION: "APP_TRACK_LOCATION",
    APP_ACTION: "APP_ACTION",
    APP_ACTION_COMPLETED: "APP_ACTION_COMPLETED",
    APP_SET_ANALYTICS: "APP_SET_ANALYTICS"
};

export default constants;
