import {createSelector} from 'reselect';

export const selectServiceView = createSelector([
  state => state.getIn(["scenes", "serviceView", "pageLoaded"]),
  state => state.getIn(["scenes", "serviceView", "resources", "services", "member"]),
  state => state.getIn(["scenes", "serviceView", "resources", "tickets"]),
  state => state.getIn(["scenes", "serviceView", "resources", "products"]),
  state => state.getIn(["scenes", "serviceView", "resources", "taskQueues"]),
  state => state.getIn(["scenes", "serviceView", "resources", "lastTasks"]),
  state => state.getIn(["scenes", "serviceView", "filters", "products"]),
  state => state.getIn(["scenes", "serviceView", "tableLoading", "products"])
], (pageLoaded, service, tickets, products, taskQueues, lastTasks, filters, tableLoading) => ({
  pageLoaded,
  service,
  tickets,
  products,
  taskQueues,
  lastTasks,
  filters,
  tableLoading
}));