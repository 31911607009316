import {call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getItemById, postItem, putItem} from '../../../_store/resources/sagas';
import {ticketFormFetchSuccess} from './actions';
import {saveRequestSuccess} from '../../../_store/resources/actions';
import {removeAccents} from '../../../_helpers/string';
import serviceAuth from '../../../_services/_auth/service';
import {REDIRECT} from '../../../_constants/api';

const emitter = "TICKET_FORM";

function* ticketFormSave(action) {
  try {
    const {values} = action.payload;

    // const organization = yield select(state => state.getIn(["auth", "resources", "organizations", "member"]));
    //
    // const item = {
    //   ...(values["@id"] ? {"@id": values["@id"]} : {}),
    //   fullName: values.fullName,
    //   ticketname: removeAccents(values.fullName).toLowerCase().replace(" ", "."),
    //   email: values.email,
    //   groups: values.groups.map(group => group.value),
    //   organization: organization.get("id")
    // }
    //
    // if (values["@id"]) {
    //   yield call(putItem, {
    //     payload: {
    //       name: "tickets",
    //       item,
    //       emitter,
    //       apiName: "auth"
    //     }
    //   });
    // } else {
    //   yield call(postItem, {
    //     payload: {
    //       name: "tickets",
    //       item,
    //       emitter,
    //       apiName: "auth"
    //     }
    //   });
    //   const apiAuth = serviceAuth();
    //   yield call(apiAuth["accountActivation"].update, {email: values.email, redirect: REDIRECT});
    // }
    console.log(values);
    yield put(saveRequestSuccess(emitter));
  } catch (e) {
    yield call(handleError, 500, "Erreur durant la sauvegarde du ticket", true);
  }
}

export function* watchTicketForm() {
  yield takeLatest(actionTypes.TICKET_FORM_SAVE, ticketFormSave);
}

export default watchTicketForm;