import {takeLatest, put, call, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getAllItems} from '../../../_store/resources/sagas';
import {
  ticketListFetchSuccess,
  ticketListUpdateSuccess
} from './actions';

const emitter = "TICKET_LIST";

function* ticketListFetch() {
  try {
    yield put(ticketListFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des tickets");
  }
}

function* ticketListUpdate(action) {
  try {
    const {payload} = action;

    const organization = yield select(state => state.getIn(["auth", "resources", "organizations", "member"]));

    yield call(getAllItems, {
      payload: {
        name: "tickets",
        params: {
          ...payload.params,
          // ["organization.id"]: organization.get("id")
        },
        emitter
      }
    });

    yield put(ticketListUpdateSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des tickets");
  }
}

export function* watchServiceList() {
  yield takeLatest(actionTypes.TICKET_LIST_FETCH, ticketListFetch);
  yield takeLatest(actionTypes.TICKET_LIST_UPDATE, ticketListUpdate);
}

export default watchServiceList;