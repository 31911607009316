import {fromJS} from 'immutable';

import actionTypes from './actionTypes';
import {removeCookie} from '../../_helpers/cookie';

const initialState = fromJS({
  isAuthenticated: false,
  user: null,
  employee: null,
  isTried: false,
  isSubmitting: false,
  expirationToken: null,
  emailSend: false,
  validEmail: false,
  emailChecked: false,
  reset: false,
  validate: false,
  validToken: true,
  tokenChecked: false
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case actionTypes.SIGN_IN:
      return state.set("isSubmitting", true);
    case actionTypes.SIGN_IN_SUCCESS: {
      return state.merge(fromJS({
        user: payload.user,
        isAuthenticated: true,
        isSubmitting: false,
        expirationToken: payload.expiration
      }));
    }
    case actionTypes.SIGN_IN_FAILURE:
      return state.set("isSubmitting", false);
    case actionTypes.TRY_AUTO_SIGN_IN:
      return state.set("isTried", false);
    case actionTypes.TRY_AUTO_SIGN_IN_SUCCESS:
    case actionTypes.TRY_AUTO_SIGN_IN_FAILURE:
      return state.set("isTried", true);
    case actionTypes.SIGN_OUT:
      removeCookie("token-v2");
      return state.merge(fromJS({
        user: null,
        isAuthenticated: false
      }));
    case "AUTH_STORE_REQUEST":
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    case actionTypes.FORGET_PASSWORD_RESET:
      removeCookie("token-v2");
      return state.merge(fromJS({
        emailSend: false,
        emailChecked: false,
        isSubmitting: false,
        validEmail: false
      }));
    case actionTypes.FORGET_PASSWORD_ASK_RESET:
      return state.merge(fromJS({
        emailSend: false,
        emailChecked: false,
        isSubmitting: false,
        validEmail: false
      }));
    case actionTypes.FORGET_PASSWORD_ASK_RESET_SUCCESS:
      return state.merge(fromJS({
        emailSend: true,
        emailChecked: true,
        isSubmitting: false,
        validEmail: true
      }));
    case actionTypes.FORGET_PASSWORD_ASK_RESET_FAILURE:
      return state.merge(fromJS({
        emailSend: false,
        emailChecked: true,
        isSubmitting: false,
        validEmail: false
      }));
    case actionTypes.RESET_PASSWORD_SAVE:
      return state.merge(fromJS({
        reset: false,
        isSubmitting: true
      }));
    case actionTypes.RESET_PASSWORD_SAVE_SUCCESS:
      return state.merge(fromJS({
        reset: true,
        isSubmitting: false
      }));
    case actionTypes.RESET_PASSWORD_SAVE_FAILURE:
      return state.merge(fromJS({
        reset: false,
        isSubmitting: false
      }));
    case actionTypes.RESET_PASSWORD_CHECK_RECOVER_TOKEN:
    case actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN:
      removeCookie("token-v2");
      return state.merge(fromJS({
        isSubmitting: false,
        emailSend: false,
        validEmail: false,
        emailChecked: false,
        reset: false,
        validate: false,
        validToken: true,
        tokenChecked: false
      }));
    case actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_SUCCESS:
      return state.merge(fromJS({
        validToken: true,
        tokenChecked: true,
        user: payload.data
      }));
    case actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_FAILURE:
      return state.merge(fromJS({
        validToken: false,
        tokenChecked: true
      }));
    case actionTypes.ACCOUNT_ACTIVATION_SAVE:
      return state.merge(fromJS({
        validate: false,
        isSubmitting: true
      }));
    case actionTypes.ACCOUNT_ACTIVATION_SAVE_SUCCESS:
      return state.merge(fromJS({
        validate: true,
        isSubmitting: false
      }));
    case actionTypes.ACCOUNT_ACTIVATION_SAVE_FAILURE:
      return state.merge(fromJS({
        validate: false,
        isSubmitting: false
      }));
    default:
      return state;
  }
};

export default reducer;