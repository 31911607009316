import {call, put, takeEvery, select} from 'redux-saga/effects';

import {apiRequest, apiRequestCancel, apiRequestFailure, apiRequestSuccess} from './actions';
import serviceManager, {resourcesNames as resourcesNamesManager} from '../../_services/_manager/service';
import serviceAuth, {resourcesNames as resourcesNamesAuth} from '../../_services/_auth/service';
import serviceAnalytics, {resourcesNames as resourcesNamesAnalytics} from '../../_services/_analytics/service';
import serviceMonitoring, {resourcesNames as resourcesNamesMonitoring} from '../../_services/_monitoring/service';
import actionTypes from './actionTypes';

export function* getAllItems(action) {
  const analytics = yield select(state => state.getIn(["app", "analytics"]));
  const apiAnalytics = analytics ? serviceAnalytics(null, [], analytics.get("reference")) : null;
  const {
    name,
    params = {},
    emitter,
    customName = null,
    condition = true,
    apiName,
    customIndexes = [],
    store = true
  } = action.payload;
  const api = apiName === "auth" ? serviceAuth() : apiName === "analytics" ? apiAnalytics : apiName === "monitoring" ? serviceMonitoring() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : apiName === "analytics" ? resourcesNamesAnalytics : apiName === "monitoring" ? resourcesNamesMonitoring : resourcesNamesManager;
  const actionType = `${emitter}_GET_ALL_ITEMS_${resourcesNames[name].plural}`;
  yield put(apiRequest(actionType));
  try {
    if ((typeof condition === "boolean" && condition) || (typeof condition === "string" && condition.length > 0) || (Array.isArray(condition) && condition.length > 0)) {
      const data = yield call(api[name].getAll, params, customIndexes);
      yield put(apiRequestSuccess(actionType));
      if (store) {
        yield put({
          type: `${emitter}_STORE_REQUEST`,
          payload: {
            data,
            customName,
            name
          }
        });
      }
      return data;
    } else {
      yield put(apiRequestCancel(actionType));
    }
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}

export function* getItem(action) {
  const {name, uri, emitter, customName, condition = true, apiName} = action.payload;

  if (uri) {
    const analytics = yield select(state => state.getIn(["app", "analytics"]));
    const apiAnalytics = analytics ? serviceAnalytics(null, [], analytics.get("reference")) : null;
    const api = apiName === "auth" ? serviceAuth() : apiName === "analytics" ? apiAnalytics : apiName === "monitoring" ? serviceMonitoring() : serviceManager();
    const resourcesNames = apiName === "auth" ? resourcesNamesAuth : resourcesNamesManager;
    const actionType = `${emitter}_GET_ITEM_${resourcesNames[name].singular}`;
    yield put(apiRequest(actionType));

    try {
      if ((typeof condition === "boolean" && condition) || (typeof condition === "string" && condition.length > 0) || (Array.isArray(condition) && condition.length > 0)) {
        const data = yield call(api[name].getOne, uri);
        yield put(apiRequestSuccess(actionType));
        yield put({
          type: `${emitter}_STORE_REQUEST`,
          payload: {
            data,
            customName,
            name
          }
        });
        return data;
      } else {
        yield put(apiRequestCancel(actionType));
      }
    } catch (e) {
      yield put(apiRequestFailure(actionType));
      throw e;
    }
  }
}

export function* getItemById(action) {
  const {name, id, emitter, customName, condition = true, apiName} = action.payload;
  const analytics = yield select(state => state.getIn(["app", "analytics"]));
  const apiAnalytics = analytics ? serviceAnalytics(null, [], analytics.get("reference")) : null;
  const api = apiName === "auth" ? serviceAuth() : apiName === "analytics" ? apiAnalytics : apiName === "monitoring" ? serviceMonitoring() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : resourcesNamesManager;
  const actionType = `${emitter}_GET_ITEM_${resourcesNames[name].singular}`;
  yield put(apiRequest(actionType));
  try {
    if ((typeof condition === "boolean" && condition) || (typeof condition === "string" && condition.length > 0) || (Array.isArray(condition) && condition.length > 0)) {
      const data = yield call(api[name].getOneById, id);
      yield put(apiRequestSuccess(actionType));
      yield put({
        type: `${emitter}_STORE_REQUEST`,
        payload: {
          data,
          customName,
          name
        }
      });
      return data;
    } else {
      yield put(apiRequestCancel(actionType));
    }
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}

/**
 *
 * @param action {{payload: {name : string, item : any, emitter: string, customName: string|null}}}
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{payload: {data: *, name, customName}, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: *}>>, *, *>}
 */
export function* postItem(action) {
  const {name, item, emitter, customName, store = true, apiName} = action.payload;
  const analytics = yield select(state => state.getIn(["app", "analytics"]));
  const apiAnalytics = analytics ? serviceAnalytics(null, [], analytics.get("reference")) : null;
  const api = apiName === "auth" ? serviceAuth() : apiName === "analytics" ? apiAnalytics : apiName === "monitoring" ? serviceMonitoring() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : apiName === "monitoring" ? resourcesNamesMonitoring : resourcesNamesManager;
  const actionType = `${emitter}_POST_ITEM_${resourcesNames[name].singular}`;
  yield put(apiRequest(actionType));
  try {
    const data = yield call(api[name].post, item);
    yield put(apiRequestSuccess(actionType));
    if (store) {
      yield put({
        type: `${emitter}_STORE_REQUEST`,
        payload: {
          data,
          customName,
          name
        }
      });
    }
    return data;
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}

/**
 *
 * @param action {{payload: {name : string, item: any, emitter: string, customName: string|null}}}
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{payload: {data: *, name, customName}, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: *}>>, *, *>}
 */
export function* putItem(action) {
  const {name, item, emitter, customName, store = true, apiName} = action.payload;
  const analytics = yield select(state => state.getIn(["app", "analytics"]));
  const apiAnalytics = analytics ? serviceAnalytics(null, [], analytics.get("reference")) : null;
  const api = apiName === "auth" ? serviceAuth() : apiName === "analytics" ? apiAnalytics : apiName === "monitoring" ? serviceMonitoring() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : resourcesNamesManager;
  const actionType = `${emitter}_PUT_ITEM_${resourcesNames[name].singular}`;
  yield put(apiRequest(actionType));
  try {
    const data = yield call(api[name].put, item);
    yield put(apiRequestSuccess(actionType));
    if (store) {
      yield put({
        type: `${emitter}_STORE_REQUEST`,
        payload: {
          data,
          customName,
          name
        }
      });
    }
    return data;
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}

export function* deleteItem(action) {
  const {name, uri, emitter, apiName} = action.payload;
  const api = apiName === "auth" ? serviceAuth() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : resourcesNamesManager;
  const actionType = `${emitter}_DELETE_ITEM_${resourcesNames[name].singular}`;
  yield put(apiRequest(actionType));
  try {
    yield call(api[name].delete, uri);
    yield put(apiRequestSuccess(actionType));
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}

export function* deleteItemById(action) {
  const {name, id, emitter, apiName} = action.payload;
  const api = apiName === "auth" ? serviceAuth() : serviceManager();
  const resourcesNames = apiName === "auth" ? resourcesNamesAuth : resourcesNamesManager;
  const actionType = `${emitter}_DELETE_ITEM_${resourcesNames[name].singular}`;
  yield put(apiRequest(actionType));
  try {
    yield call(api[name].deleteById, id);
    yield put(apiRequestSuccess(actionType));
  } catch (e) {
    yield put(apiRequestFailure(actionType));
    throw e;
  }
}


export function* watchResources() {
  yield takeEvery(actionTypes.GET_ALL_ITEMS, getAllItems);
  yield takeEvery(actionTypes.GET_ITEM, getItem);
  yield takeEvery(actionTypes.GET_ITEM_BY_ID, getItemById);
  yield takeEvery(actionTypes.POST_ITEM, postItem);
  yield takeEvery(actionTypes.PUT_ITEM, putItem);
  yield takeEvery(actionTypes.DELETE_ITEM, deleteItem);
  yield takeEvery(actionTypes.DELETE_ITEM_BY_ID, deleteItemById);
}

export default watchResources;
