import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, FastField} from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';

import {serviceListFetch, serviceListReset, serviceListUpdate} from './_store/actions';
import {selectServiceList} from './_store/selectors';
import Loading from '../../_components/Loading/Loading';
import ServiceCard from './_components/ServiceCard';
import AutoSave from '../../_components/_form/AutoSave/AutoSave';
import TextType from '../../_components/_form/TextType/TextType';

function ServiceList() {
  const dispatch = useDispatch();
  const {
    pageLoaded,
    servicesLoaded,
    services
  } = useSelector(selectServiceList);

  useEffect(() => {
    dispatch(serviceListFetch());
    return () => dispatch(serviceListReset());
  }, [dispatch]);

  const sortedServices = useMemo(() => {
    return services ? services.get("members").sort((a, b) => {
      const aArticleId = a.getIn(["article", "id"]);
      const bArticleId = b.getIn(["article", "id"]);
      return (aArticleId - bArticleId) || a.get("name").localeCompare(b.get("name"));
    }) : null;
  }, [services]);

  const serviceCards = useMemo(() => {
    return sortedServices && sortedServices.size > 0
      ? sortedServices.map(service => <ServiceCard key={service.get("reference")} service={service}/>)
      : (
        <Grid item xs={12}>
          <Alert severity="info">
            Aucun service trouvé
          </Alert>
        </Grid>
      );
  }, [sortedServices]);

  const handleSubmit = useCallback(values => {
    dispatch(serviceListUpdate({
      name: values.search || null
    }));
  }, [dispatch]);

  return (
    <>
      <Loading isLoading={!pageLoaded} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {pageLoaded && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{mb: 2}}>Liste des services</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: 2}}>
                <Grid container spacing={2} sx={{mb: 2}}>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{
                        search: ""
                      }}
                      onSubmit={(values, {setSubmitting}) => {
                        return new Promise(resolve =>
                          setTimeout(() => {
                            handleSubmit(values);
                            setSubmitting(false);
                            resolve();
                          }, 200)
                        );
                      }}
                    >
                      {formikProps => {
                        return (
                          <Form>
                            <Grid container spacing={2}>
                              <AutoSave debounceMs={500} dirty={formikProps.dirty || formikProps.submitCount > 0}/>
                              <Grid item xs={12}>
                                <FastField
                                  component={TextType}
                                  name="search"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon sx={{color: "action.active"}}/>
                                      </InputAdornment>
                                    )
                                  }}
                                  placeholder="Rechercher par nom..."
                                />
                              </Grid>
                            </Grid>
                          </Form>
                        )
                      }}
                    </Formik>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{position: "relative"}}>
                  <Loading isLoading={!servicesLoaded} backgroundColor="rgba(250, 250, 250, 0.4)" color="transparent"
                           containerStyle={{left: "16px", top: "16px !important"}}/>
                  {services.get("count") > 0 ? (
                    <Grid item xs={12}>
                      <Alert severity="info" icon={false}>
                        {`${services.get("count")} service${services.get("count") > 1 ? "s" : ""} trouvé${services.get("count") > 1 ? "s" : ""}`}
                      </Alert>
                    </Grid>
                  ) : null}
                  {serviceCards}
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ServiceList;