import actionTypes from './actionTypes';

export const apiRequest = (name) => {
    return ({
        type: name
    })
};

export const apiRequestSuccess = (name) => ({
    type: `${name}_SUCCESS`
});

export const apiRequestFailure = (name) => ({
    type: `${name}_FAILURE`
});

export const apiRequestCancel = (name) => ({
    type: `${name}_CANCEL`
});

export const saveRequestSuccess = (name) => ({
    type: `${name}_SAVE_SUCCESS`
});

export const saveRequestFailure = (name) => ({
    type: `${name}_SAVE_FAILURE`
});

/**
 *
 * @param name
 * @param params
 * @param emitter
 * @param customName
 * @param condition
 * @param apiName
 * @param customIndexes
 * @return {{payload: {condition: boolean, name: *, customName: *, params: {}, emitter: *, apiName: *, customIndexes: *}, type: string}}
 */
export const getAllItems = (name, params = {}, emitter, customName = null, condition = true, apiName, customIndexes = []) => ({
    type: actionTypes.GET_ALL_ITEMS,
    payload: {
        name,
        params,
        emitter,
        customName,
        condition,
        apiName,
        customIndexes
    }
});

export const getItem = (name, uri, emitter, customName, condition, apiName) => ({
    type: actionTypes.GET_ITEM,
    payload: {
        name,
        uri,
        emitter,
        customName,
        condition,
        apiName
    }
});

export const getItemById = (name, id, emitter, customName, condition, apiName) => ({
    type: actionTypes.GET_ITEM_BY_ID,
    payload: {
        name,
        id,
        emitter,
        customName,
        condition,
        apiName
    }
});

export const postItem = (name, item, emitter, customName, apiName) => ({
    type: actionTypes.POST_ITEM,
    payload: {
        name,
        item,
        emitter,
        customName,
        apiName
    }
});

export const putItem = (name, item, emitter, customName, apiName) => ({
    type: actionTypes.PUT_ITEM,
    payload: {
        name,
        item,
        emitter,
        customName,
        apiName
    }
});

export const deleteItem = (name, uri, apiName) => ({
    type: actionTypes.DELETE_ITEM,
    payload: {
        name,
        uri,
        apiName
    }
});

export const deleteItemById = (name, id, emitter, apiName) => ({
    type: actionTypes.DELETE_ITEM_BY_ID,
    payload: {
        name,
        id,
        emitter,
        apiName
    }
});
