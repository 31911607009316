import axios from 'axios';

import API from '../apiGenerator';
import {AUTH} from '../../_constants/api';
import {getCookie} from '../../_helpers/cookie';
import {bearer} from '../../_helpers/api';

// Alphabétique
export const resourcesNames = {
  groups: {
    plural: "RIGHTS_GROUPS",
    singular: "RIGHTS_GROUP"
  },
  users: {
    plural: "USERS",
    singular: "USER"
  }
};

const serviceAuth = (entity = null, entities = []) => {

  const api = new API({
    base_url: AUTH,
    token: getCookie("token-v2")
  });

  if (entity) {
    api.createEntity(entity);
  }

  if (entities) {
    api.createEntities(entities);
  }

  api.addEndpoints({
    name: "loginCheck",
    request: {
      create: (data) => axios.post(`${AUTH}/auth`, data).then(response => {
        return response.data;
      })
    }
  });

  api.addEndpoints({
    name: "accessToken",
    request: {
      get: () => axios.get(`${AUTH}/user/access_token`, bearer()).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "accessTokenRecover",
    request: {
      get: token => axios.get(`${AUTH}/user/access_token`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "accountActivation",
    request: {
      update: data => axios.put(`${AUTH}/auth/account-activation`, data).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "changePasswordWithRecoverToken",
    request: {
      update: (token, data) => axios.put(`${AUTH}/users/${data.id}`, data, {
        headers: {
          "Authorization": "Bearer " + token
        }
      }).then(response => response.data)
    }
  });

  api.addEndpoints({
    name: "askResetPassword",
    request: {
      update: (data) => axios.put(`${AUTH}/auth/ask-reset-password`, data).then(response => response.data)
    }
  });

  if (!entity && entities.length === 0) {
    // Alphabétique
    api.createEntities([
      "groups",
      "users"
    ])
  }
  return api;
};

export default serviceAuth;
