import {createSelector} from 'reselect';

export const selectProductFormIsSubmitting = state => state.getIn(["scenes", "productForm", "isSubmitting"]);
export const selectProductFormIsSubmitted = state => state.getIn(["scenes", "productForm", "isSubmitted"]);
export const selectProductFormProduct = state => state.getIn(["scenes", "productForm", "resources", "products", "member"]);

export const selectProductForm = createSelector([
  selectProductFormIsSubmitting,
  selectProductFormIsSubmitted,
  selectProductFormProduct
], (isSubmitting, isSubmitted, product) => ({
  isSubmitting,
  isSubmitted,
  product
}));