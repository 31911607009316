import {call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {documentManager, handleError} from '../../../_helpers/api';
import {getItemById, putItem} from '../../../_store/resources/sagas';
import {serviceFormFetchSuccess} from './actions';
import {saveRequestSuccess, saveRequestFailure} from '../../../_store/resources/actions';

const emitter = "SERVICE_FORM";

function* serviceFormFetch(action) {
  try {
    const {payload} = action;
    yield call(getItemById, {
      payload: {
        name: "webServices",
        id: payload.id,
        customName: "services",
        emitter
      }
    });
    yield put(serviceFormFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération du service", true);
  }
}

function* serviceFormSave(action) {
  try {
    const {values} = action.payload;

    const currentService = yield select(state => state.getIn(["scenes", "serviceForm", "resources", "services", "member"]));

    let featuredImage = null;
    if (values.photo.length > 0) {
      featuredImage = yield call(documentManager, values.photo, emitter);
    }

    yield call(putItem, {
      payload: {
        name: "webServices",
        item: {
          "@id": currentService.get("@id"),
          name: values.name,
          featuredImage
        },
        emitter
      }
    });

    yield put(saveRequestSuccess(emitter));
  } catch (e) {
    yield put(saveRequestFailure(emitter));
    yield call(handleError, 500, "Erreur durant la sauvegarde du service", true);
  }
}

export function* watchServiceForm() {
  yield takeLatest(actionTypes.SERVICE_FORM_FETCH, serviceFormFetch);
  yield takeLatest(actionTypes.SERVICE_FORM_SAVE, serviceFormSave);
}

export default watchServiceForm;