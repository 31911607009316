import {createSelector} from 'reselect';

export const selectRightsGroupForm = createSelector([
  state => state.getIn(["scenes", "rightsGroupForm", "pageLoaded"]),
  state => state.getIn(["scenes", "rightsGroupForm", "isSubmitting"]),
  state => state.getIn(["scenes", "rightsGroupForm", "isSubmitted"]),
  state => state.getIn(["scenes", "rightsGroupForm", "resources", "groups", "member"])
], (pageLoaded, isSubmitting, isSubmitted, rightsGroup) => ({
  pageLoaded,
  isSubmitting,
  isSubmitted,
  rightsGroup
}));