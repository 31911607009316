import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {FastField, Form, Formik} from 'formik';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TableContainerMui from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';

import validationSchema from './validationSchema';
import {rightsGroupFormFetch, rightsGroupFormReset, rightsGroupFormSave} from './_store/actions';
import {selectRightsGroupForm} from './_store/selectors';
import {selectAuthOrganization} from '../../_store/auth/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import FormSection from '../../_components/_form/FormSection/FormSection';
import {rolesBaliz, rolesData, rolesManager, rolesFunbox} from '../../_constants/rightsGroupRoles';
import {removeAccents} from '../../_helpers/string';
import ColorPickerType from '../../_components/_form/ColorPickerType/ColorPickerType';
import Loading from '../../_components/Loading/Loading';
import CollapseRights from './_components/CollapseRights';
import HtmlTooltip from '../../_components/HtmlTooltip/HtmlTooltip';

function RightsGroupForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: paramsId} = useParams();
  const id = paramsId ? parseInt(paramsId) : paramsId;
  const organization = useSelector(selectAuthOrganization);
  const {
    pageLoaded,
    isSubmitting,
    isSubmitted,
    rightsGroup
  } = useSelector(selectRightsGroupForm);

  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    color: "#282828",
    roles: [],
    isClientGroup: true,
    organization: organization.get("id")
  });

  // Redirection vers la page d'erreur si l'id n'est pas un entier
  useEffect(() => {
    if (id !== undefined && !id) {
      navigate("/erreur", {state: {}, replace: true});
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(rightsGroupFormFetch(id));
    }
    return () => dispatch(rightsGroupFormReset());
  }, [id]);

  const rightsGroupIRI = rightsGroup ? rightsGroup.get("@id") : null;

  useEffect(() => {
    if (rightsGroupIRI) {
      setInitialValues({
        ["@id"]: rightsGroupIRI,
        name: rightsGroup.get("name"),
        code: rightsGroup.get("code"),
        color: rightsGroup.get("color"),
        roles: rightsGroup.get("roles").toJS().sort(),
        isClientGroup: rightsGroup.get("isClientGroup"),
        organization: rightsGroup.get("organization")
      });
    }
  }, [rightsGroupIRI]);

  const handleSubmit = values => {
    dispatch(rightsGroupFormSave(values));
  };

  useEffect(() => {
    if (isSubmitted) {
      navigate("/comptes/groupes");
    }
  }, [isSubmitted]);

  const handleCheckedAll = useCallback((e, roles, values, setFieldValue) => {
    const filteredRoles = roles.filter(role => role !== "");
    if (e.target.checked) {
      const newRoles = [...new Set([...values.roles, ...filteredRoles])].sort();
      setFieldValue(e.target.name, newRoles);
    } else {
      const newRoles = values.roles.filter(el => !filteredRoles.includes(el)).sort();
      setFieldValue(e.target.name, newRoles);
    }
  }, []);

  const handleCheckedOne = useCallback((e, roles, values, setFieldValue) => {
    const filteredRoles = roles.filter(role => role !== "");
    if (e.target.checked) {
      // Permet de recocher la case "Tout" automatiquement si tous les roles sont cochés
      let allChecked = true;
      for (let i = 1; i < filteredRoles.length; i++) {
        if (filteredRoles[i] !== e.target.value && !values.roles.includes(filteredRoles[i])) {
          allChecked = false;
        }
      }
      const newRoles = [...new Set([...values.roles, e.target.value, ...allChecked ? [filteredRoles[0]] : []])].sort();
      setFieldValue(e.target.name, newRoles);
    } else {
      const rolesToDelete = [e.target.value, filteredRoles[0]]
      const newRoles = values.roles.filter(el => !rolesToDelete.includes(el)).sort();
      setFieldValue(e.target.name, newRoles);
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(40, 40, 40)",
      color: "#fff"
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px"
    },
    [`&.${tableCellClasses.head}:last-child`]: {
      borderTopRightRadius: "4px"
    }
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
    "&:last-child td, &:last-child th": {
      border: 0
    },
    "&:last-child th": {
      borderBottomLeftRadius: "4px"
    },
    "&:last-child td:last-child": {
      borderBottomRightRadius: "4px"
    }
  }));

  return (
    <>
      <Loading isLoading={Boolean(id && !pageLoaded)} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {Boolean(!id || pageLoaded) && (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{mb: 2}}>
              {id ? `Édition du groupe de droits : ${rightsGroup.get("name")}` : "Ajout d'un groupe de droits"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
            >
              <Typography key="1" color="inherit">
                Comptes
              </Typography>
              <Typography
                key="2"
                color="inherit"
                sx={{color: "#1976d2", "&:hover": {cursor: "pointer", textDecoration: "underline"}}}
                onClick={() => navigate("/comptes/groupes")}
              >
                Groupes
              </Typography>
              <Typography key="3" color="text.primary">
                {id ? `Édition du groupe n°${id}` : "Ajout"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({values, setFieldValue, dirty}) => {
                    return (
                      <Form style={{width: "100%", display: "flex"}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FastField
                              name="name"
                              component={TextType}
                              label="Dénomination"
                              required
                              onChange={e => {
                                setFieldValue("name", e.target.value);
                                if (organization && !id) {
                                  const code = `${organization.get("reference")}_${removeAccents(e.target.value.toUpperCase().replace(" ", "_"))}`;
                                  setFieldValue("code", code);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FastField
                              name="color"
                              component={ColorPickerType}
                            />
                          </Grid>
                          <FormSection>Gestion des droits</FormSection>
                          <CollapseRights name="Manager">
                            <TableContainerMui>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell sx={{width: "300px"}}>Dénomination</StyledTableCell>
                                    <StyledTableCell align="center">Tout</StyledTableCell>
                                    <StyledTableCell align="center">Voir</StyledTableCell>
                                    <StyledTableCell align="center">Ajouter</StyledTableCell>
                                    <StyledTableCell align="center">Modifier</StyledTableCell>
                                    <StyledTableCell align="center">Déplacer</StyledTableCell>
                                    <StyledTableCell align="center">Supprimer</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rolesManager.map((item, index) => {
                                    return item.group ? (
                                      <>
                                        <StyledTableRow key={index} style={{backgroundColor: "#646464"}}>
                                          <StyledTableCell component="th" scope="row" key={`${index}-0`}
                                                           style={{color: "#fff"}}>
                                            {item.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" key={`${index}-1`}/>
                                          <StyledTableCell align="center" key={`${index}-2`}/>
                                          <StyledTableCell align="center" key={`${index}-3`}/>
                                          <StyledTableCell align="center" key={`${index}-4`}/>
                                          <StyledTableCell align="center" key={`${index}-5`}/>
                                          <StyledTableCell align="center" key={`${index}-6`}/>
                                        </StyledTableRow>
                                        {item.arr.map((child, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                              {child.name}
                                            </StyledTableCell>
                                            {child.roles.map((role, index) => index === 0 ? (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <FastField
                                                      children={() => (
                                                        <Checkbox
                                                          name="roles"
                                                          value={role}
                                                          checked={values.roles.includes(role)}
                                                          onChange={e => handleCheckedAll(e, child.roles, values, setFieldValue)}
                                                          sx={{padding: 0}}
                                                        />
                                                      )}
                                                    />
                                                  ) : "-"}
                                                </StyledTableCell>
                                              ) : (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <div style={{position: "relative", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                      <FastField
                                                        children={() => (
                                                          <Checkbox
                                                            name="roles"
                                                            value={role}
                                                            checked={values.roles.includes(role)}
                                                            onChange={e => handleCheckedOne(e, child.roles, values, setFieldValue)}
                                                            sx={{padding: 0}}
                                                          />
                                                        )}
                                                      />
                                                      {/*<HtmlTooltip*/}
                                                      {/*  arrow*/}
                                                      {/*  enterDelay={500}*/}
                                                      {/*  disableInteractive*/}
                                                      {/*  title={*/}
                                                      {/*    <ul style={{padding: 0, margin: 0, listStyleType: "none"}}>*/}
                                                      {/*      <li>- Texte 1</li>*/}
                                                      {/*      <li>- Texte 2</li>*/}
                                                      {/*      <li>- Texte 3</li>*/}
                                                      {/*    </ul>*/}
                                                      {/*  }*/}
                                                      {/*>*/}
                                                      {/*  <InfoIcon style={{*/}
                                                      {/*    position: "absolute",*/}
                                                      {/*    color: "#282828",*/}
                                                      {/*    fontSize: "1.2rem",*/}
                                                      {/*    marginLeft: "44px"*/}
                                                      {/*  }}/>*/}
                                                      {/*</HtmlTooltip>*/}
                                                    </div>
                                                  ) : "-"}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </StyledTableRow>
                                        ))}
                                      </>
                                    ) : (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                          {item.name}
                                        </StyledTableCell>
                                        {item.roles.map((role, index) => index === 0 ? (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <FastField
                                                  children={() => (
                                                    <Checkbox
                                                      name="roles"
                                                      value={role}
                                                      checked={values.roles.includes(role)}
                                                      onChange={e => handleCheckedAll(e, item.roles, values, setFieldValue)}
                                                      sx={{padding: 0}}
                                                    />
                                                  )}
                                                />
                                              ) : "-"}
                                            </StyledTableCell>
                                          ) : (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <div style={{position: "relative", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                  <FastField
                                                    children={() => (
                                                      <Checkbox
                                                        name="roles"
                                                        value={role}
                                                        checked={values.roles.includes(role)}
                                                        onChange={e => handleCheckedOne(e, item.roles, values, setFieldValue)}
                                                        sx={{padding: 0}}
                                                      />
                                                    )}
                                                  />
                                                  {/*<HtmlTooltip*/}
                                                  {/*  arrow*/}
                                                  {/*  enterDelay={500}*/}
                                                  {/*  disableInteractive*/}
                                                  {/*  title={*/}
                                                  {/*    <ul style={{padding: 0, margin: 0, listStyleType: "none"}}>*/}
                                                  {/*      <li>- Texte 1</li>*/}
                                                  {/*      <li>- Texte 2</li>*/}
                                                  {/*      <li>- Texte 3</li>*/}
                                                  {/*    </ul>*/}
                                                  {/*  }*/}
                                                  {/*>*/}
                                                  {/*  <InfoIcon style={{*/}
                                                  {/*    position: "absolute",*/}
                                                  {/*    color: "#282828",*/}
                                                  {/*    fontSize: "1.2rem",*/}
                                                  {/*    marginLeft: "44px"*/}
                                                  {/*  }}/>*/}
                                                  {/*</HtmlTooltip>*/}
                                                </div>
                                              ) : "-"}
                                            </StyledTableCell>
                                          )
                                        )}
                                      </StyledTableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainerMui>
                          </CollapseRights>
                          <CollapseRights name="Data manager">
                            <TableContainerMui>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell sx={{width: "300px"}}>Dénomination</StyledTableCell>
                                    <StyledTableCell align="center">Tout</StyledTableCell>
                                    <StyledTableCell align="center">Voir</StyledTableCell>
                                    <StyledTableCell align="center">Ajouter</StyledTableCell>
                                    <StyledTableCell align="center">Modifier</StyledTableCell>
                                    <StyledTableCell align="center">Déplacer</StyledTableCell>
                                    <StyledTableCell align="center">Supprimer</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rolesData.map((item, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell component="th" scope="row">
                                        {item.name}
                                      </StyledTableCell>
                                      {item.roles.map((role, index) => index === 0 ? (
                                          <StyledTableCell align="center" key={index}>
                                            {role !== "" ? (
                                              <FastField
                                                children={() => (
                                                  <Checkbox
                                                    name="roles"
                                                    value={role}
                                                    checked={values.roles.includes(role)}
                                                    onChange={e => handleCheckedAll(e, item.roles, values, setFieldValue)}
                                                    sx={{padding: 0}}
                                                  />
                                                )}
                                              />
                                            ) : "-"}
                                          </StyledTableCell>
                                        ) : (
                                          <StyledTableCell align="center" key={index}>
                                            {role !== "" ? (
                                              <FastField
                                                children={() => (
                                                  <Checkbox
                                                    name="roles"
                                                    value={role}
                                                    checked={values.roles.includes(role)}
                                                    onChange={e => handleCheckedOne(e, item.roles, values, setFieldValue)}
                                                    sx={{padding: 0}}
                                                  />
                                                )}
                                              />
                                            ) : "-"}
                                          </StyledTableCell>
                                        )
                                      )}
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainerMui>
                          </CollapseRights>
                          <CollapseRights name="Baliz">
                            <TableContainerMui>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell sx={{width: "300px"}}>Dénomination</StyledTableCell>
                                    <StyledTableCell align="center">Tout</StyledTableCell>
                                    <StyledTableCell align="center">Voir</StyledTableCell>
                                    <StyledTableCell align="center">Ajouter</StyledTableCell>
                                    <StyledTableCell align="center">Modifier</StyledTableCell>
                                    <StyledTableCell align="center">Déplacer</StyledTableCell>
                                    <StyledTableCell align="center">Supprimer</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rolesBaliz.map((item, index) => {
                                    return item.group ? (
                                      <>
                                        <StyledTableRow key={index} style={{backgroundColor: "#646464"}}>
                                          <StyledTableCell component="th" scope="row" key={`${index}-0`}
                                                           style={{color: "#fff"}}>
                                            {item.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" key={`${index}-1`}/>
                                          <StyledTableCell align="center" key={`${index}-2`}/>
                                          <StyledTableCell align="center" key={`${index}-3`}/>
                                          <StyledTableCell align="center" key={`${index}-4`}/>
                                          <StyledTableCell align="center" key={`${index}-5`}/>
                                          <StyledTableCell align="center" key={`${index}-6`}/>
                                        </StyledTableRow>
                                        {item.arr.map((child, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                              {child.name}
                                            </StyledTableCell>
                                            {child.roles.map((role, index) => index === 0 ? (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <FastField
                                                      children={() => (
                                                        <Checkbox
                                                          name="roles"
                                                          value={role}
                                                          checked={values.roles.includes(role)}
                                                          onChange={e => handleCheckedAll(e, child.roles, values, setFieldValue)}
                                                          sx={{padding: 0}}
                                                        />
                                                      )}
                                                    />
                                                  ) : "-"}
                                                </StyledTableCell>
                                              ) : (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <FastField
                                                      children={() => (
                                                        <Checkbox
                                                          name="roles"
                                                          value={role}
                                                          checked={values.roles.includes(role)}
                                                          onChange={e => handleCheckedOne(e, child.roles, values, setFieldValue)}
                                                          sx={{padding: 0}}
                                                        />
                                                      )}
                                                    />
                                                  ) : "-"}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </StyledTableRow>
                                        ))}
                                      </>
                                    ) : (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                          {item.name}
                                        </StyledTableCell>
                                        {item.roles.map((role, index) => index === 0 ? (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <FastField
                                                  children={() => (
                                                    <Checkbox
                                                      name="roles"
                                                      value={role}
                                                      checked={values.roles.includes(role)}
                                                      onChange={e => handleCheckedAll(e, item.roles, values, setFieldValue)}
                                                      sx={{padding: 0}}
                                                    />
                                                  )}
                                                />
                                              ) : "-"}
                                            </StyledTableCell>
                                          ) : (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <FastField
                                                  children={() => (
                                                    <Checkbox
                                                      name="roles"
                                                      value={role}
                                                      checked={values.roles.includes(role)}
                                                      onChange={e => handleCheckedOne(e, item.roles, values, setFieldValue)}
                                                      sx={{padding: 0}}
                                                    />
                                                  )}
                                                />
                                              ) : "-"}
                                            </StyledTableCell>
                                          )
                                        )}
                                      </StyledTableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainerMui>
                          </CollapseRights>
                          <CollapseRights name="Funbox">
                            <TableContainerMui>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell sx={{width: "300px"}}>Dénomination</StyledTableCell>
                                    <StyledTableCell align="center">Tout</StyledTableCell>
                                    <StyledTableCell align="center">Voir</StyledTableCell>
                                    <StyledTableCell align="center">Ajouter</StyledTableCell>
                                    <StyledTableCell align="center">Modifier</StyledTableCell>
                                    <StyledTableCell align="center">Déplacer</StyledTableCell>
                                    <StyledTableCell align="center">Supprimer</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rolesFunbox.map((item, index) => {
                                    return item.group ? (
                                      <>
                                        <StyledTableRow key={index} style={{backgroundColor: "#646464"}}>
                                          <StyledTableCell component="th" scope="row" key={`${index}-0`}
                                                           style={{color: "#fff"}}>
                                            {item.name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center" key={`${index}-1`}/>
                                          <StyledTableCell align="center" key={`${index}-2`}/>
                                          <StyledTableCell align="center" key={`${index}-3`}/>
                                          <StyledTableCell align="center" key={`${index}-4`}/>
                                          <StyledTableCell align="center" key={`${index}-5`}/>
                                          <StyledTableCell align="center" key={`${index}-6`}/>
                                        </StyledTableRow>
                                        {item.arr.map((child, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                              {child.name}
                                            </StyledTableCell>
                                            {child.roles.map((role, index) => index === 0 ? (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <FastField
                                                      children={() => (
                                                        <Checkbox
                                                          name="roles"
                                                          value={role}
                                                          checked={values.roles.includes(role)}
                                                          onChange={e => handleCheckedAll(e, child.roles, values, setFieldValue)}
                                                          sx={{padding: 0}}
                                                        />
                                                      )}
                                                    />
                                                  ) : "-"}
                                                </StyledTableCell>
                                              ) : (
                                                <StyledTableCell align="center" key={index}>
                                                  {role !== "" ? (
                                                    <FastField
                                                      children={() => (
                                                        <Checkbox
                                                          name="roles"
                                                          value={role}
                                                          checked={values.roles.includes(role)}
                                                          onChange={e => handleCheckedOne(e, child.roles, values, setFieldValue)}
                                                          sx={{padding: 0}}
                                                        />
                                                      )}
                                                    />
                                                  ) : "-"}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </StyledTableRow>
                                        ))}
                                      </>
                                    ) : (
                                      <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                          {item.name}
                                        </StyledTableCell>
                                        {item.roles.map((role, index) => index === 0 ? (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <FastField
                                                  children={() => (
                                                    <Checkbox
                                                      name="roles"
                                                      value={role}
                                                      checked={values.roles.includes(role)}
                                                      onChange={e => handleCheckedAll(e, item.roles, values, setFieldValue)}
                                                      sx={{padding: 0}}
                                                    />
                                                  )}
                                                />
                                              ) : "-"}
                                            </StyledTableCell>
                                          ) : (
                                            <StyledTableCell align="center" key={index}>
                                              {role !== "" ? (
                                                <FastField
                                                  children={() => (
                                                    <Checkbox
                                                      name="roles"
                                                      value={role}
                                                      checked={values.roles.includes(role)}
                                                      onChange={e => handleCheckedOne(e, item.roles, values, setFieldValue)}
                                                      sx={{padding: 0}}
                                                    />
                                                  )}
                                                />
                                              ) : "-"}
                                            </StyledTableCell>
                                          )
                                        )}
                                      </StyledTableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainerMui>
                          </CollapseRights>
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<SaveOutlinedIcon/>}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={!dirty}
                              >
                                Sauvegarder
                              </LoadingButton>
                              <Button
                                variant="contained"
                                color="cancel"
                                onClick={() => navigate("/comptes/groupes")}
                              >
                                Annuler
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default RightsGroupForm;