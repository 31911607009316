import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import ICU from 'i18next-icu';

import translationsFr from './fr.json';
import translationsEn from './en.json';

const resources = {
  fr: {
    translation: translationsFr
  },
  en: {
    translation: translationsEn
  }
};

const language = localStorage.getItem("I18N_LANGUAGE");

if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "fr");
}

i18n
.use(ICU)
.use(detector)
.use(initReactI18next)
.init({
  resources,
  lng: localStorage.getItem("I18N_LANGUAGE") || "fr",
  fallbackLng: "fr",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;