import React from 'react';
import {useSelector} from 'react-redux';
import {matchPath, useLocation, Navigate} from 'react-router-dom';

import {selectAuthUser} from '../../_store/auth/selectors';

function AccessDeniedCheck({children}) {
  const user = useSelector(selectAuthUser);
  const {pathname} = useLocation();

  switch (true) {
    case Boolean(matchPath("/equipements", pathname)):
    case Boolean(matchPath("/equipements/:id", pathname)):
      if (!user.getIn(["rights", "products", "view"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    case Boolean(matchPath("/equipements/edition/:id", pathname)):
      if (!user.getIn(["rights", "products", "edit"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    case Boolean(matchPath("/statistiques", pathname)):
      if (!user.getIn(["rights", "analytics", "view"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    case Boolean(matchPath("/comptes/groupes", pathname)):
    case Boolean(matchPath("/comptes/groupes/ajout", pathname)):
    case Boolean(matchPath("/comptes/groupes/edition/:id", pathname)):
      if (!user.getIn(["rights", "isSuperAdminOrCartel"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    case Boolean(matchPath("/comptes/utilisateurs", pathname)):
      if (!user.getIn(["rights", "users", "view"]))
      break;
    case Boolean(matchPath("/comptes/utilisateurs/ajout", pathname)):
      if (!user.getIn(["rights", "users", "add"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    case Boolean(matchPath("/comptes/utilisateurs/edition/:id", pathname)):
      if (!user.getIn(["rights", "users", "edit"])) return <Navigate to="/acces-refuse" replace/>;
      break;
    default:
  }

  return children;
}

export default AccessDeniedCheck;