import {createSelector} from 'reselect';

import {selectAuthUser} from '../../../_store/auth/selectors';

const selectUserListPageLoaded = state => state.getIn(["scenes", "userList", "pageLoaded"]);
const selectUserListTableLoading = state => state.getIn(["scenes", "userList", "tableLoading"]);
const selectUserListUsers = state => state.getIn(["scenes", "userList", "resources", "users"]);
const selectUserListfilters = state => state.getIn(["scenes", "userList", "filters"]);

export const selectUserList = createSelector([
  selectUserListPageLoaded,
  selectUserListTableLoading,
  selectUserListUsers,
  selectUserListfilters,
  selectAuthUser
], (pageLoaded, tableLoading, users, filters, user) => ({
  pageLoaded,
  tableLoading,
  users,
  filters,
  user
}));