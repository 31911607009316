import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {appAction} from '../../../_store/app/actions';
import {selectAppAction} from '../../../_store/app/selectors';
import {getFormattedDate} from '../../../_helpers/date';
import {API_MANAGER} from '../../../_constants/api';
import {selectAuthUser} from '../../../_store/auth/selectors';
import ServiceWithMenu from './ServiceWithMenu';

const ordering = {};
const sortOrder = ["Application Baliz", "Application Funbox", "Gestionnaire de données (DATA)"];
for (let i = 0; i < sortOrder.length; i++) {
  ordering[sortOrder[i]] = i;
}

function ProductCard({product}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const pingInProgress = useSelector(state => selectAppAction(state, ["ping", product.get("reference"), "inProgress"]));
  const restartTheziaInProgress = useSelector(state => selectAppAction(state, ["restart_thezia", product.get("reference"), "inProgress"]));

  const sortedServices = useMemo(() => product.get("webServices").sort((a, b) => {
    const aArticleName = a.getIn(["article", "name"]);
    const bArticleName = b.getIn(["article", "name"]);
    return (ordering[aArticleName] - ordering[bArticleName]) || aArticleName.localeCompare(bArticleName);
  }), []);

  const webServices = useMemo(() => sortedServices.map((service, index) => {
    if (service.getIn(["article", "id"]) !== 8) {
      return <ServiceWithMenu key={index} product={product} service={service}/>;
    }
    return null;
  }), [product, sortedServices]);

  return (
    <Grid item key={product.get("@id")} xs={12} sm={6} md={4} lg={2} sx={{display: "flex"}}>
      <Card
        sx={{
          backgroundColor: product.get("isMonitored") ? product.get("isOnline") ? "#d0ffe7" : "#ffdfdf" : "#ededed",
          display: "flex",
          flexDirection: "column",
          flex: "1 1"
        }}
      >
        <CardActionArea
          sx={{flex: "0 0", ":hover": {
            backgroundColor: product.get("isMonitored") ? product.get("isOnline") ? "#aef5d1" : "#f9d1d1" : "#dddddd"
          }}}
          disableRipple
          onClick={() => navigate(`/equipements/${product.get("id")}`)}
        >
          <Box sx={{
            padding: "5px 8px",
            display: "flex",
            alignItems: "center",
            background: "linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.6) 50%, transparent 90%)",
            color: "#fff",
            fontWeight: "bold"
          }}>
            <div style={{
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              boxShadow: "0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12)",
              background: product.get("isMonitored") ? product.get("isOnline") ? "linear-gradient(225deg, #9eff9e 0, #0dec0d 40%, #08a908)" : "linear-gradient(225deg, #ff8989 0, #ff3e3e 40%, #c32727)" : "linear-gradient(225deg, #c8c8c8 0, #a0a0a0 40%, #828282)",
              border: `2px solid ${product.get("isMonitored") ? product.get("isOnline") ? "#08a908" : "#c32727" : "#828282"}`
            }}/>
            {product.get("lastConnexion") ? (
              <>
                <span style={{margin: "-2px 8px 2px 8px"}}>-</span>
                <span>{getFormattedDate(product.get("lastConnexion"), false)}</span>
              </>
            ) : null}
          </Box>
          <CardMedia
            component="img"
            height="140"
            image={product.get("featuredImage") ? `${API_MANAGER}/${product.getIn(["featuredImage", "publicPath"])}` : product.getIn(["article", "name"]).startsWith("Funbox") ? "/images/nuc.png" : product.get("reference").startsWith("TAB") ? "/images/tablette.png" : "/images/ft.png"}
            alt="photo de l'équipement"
            sx={{opacity: product.get("isMonitored") ? 1 : 0.4}}
          />
          <CardContent sx={{pb: 1}}>
            <Typography variant="h6" component="div" sx={{textDecoration: "underline"}}>
              <span style={{opacity: product.get("isMonitored") ? 1 : 0.6}}>{product.get("name")}</span>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardContent sx={{flex: "1 1", py: webServices.size > 0 ? 1 : 0}}>
          {webServices.size > 0 ? (
            <>
              <Typography variant="p" component="div" sx={{fontWeight: "bold", marginBottom: "4px"}}>
                Services associés :
              </Typography>
              <Box sx={{display: "flex", flexWrap: "wrap", marginBottom: "-5px"}}>
                {webServices}
              </Box>
            </>
          ) : null}
        </CardContent>
        <CardActions sx={{flex: "0 0"}}>
          <Grid container>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Tester la connexion" arrow enterDelay={500} disableInteractive>
                <Button
                  aria-label="network"
                  size="small"
                  disableRipple
                  sx={{
                    backgroundColor: "#00796b",
                    color: "#fff",
                    ":hover": {backgroundColor: "#004d40"},
                    ":disabled": {backgroundColor: "rgba(0, 0, 0, 0.12)"}
                  }}
                  disabled={pingInProgress}
                  onClick={() => dispatch(appAction("ping", product.get("reference"), {
                    name: product.get("name")
                  }))}
                >
                  <WifiIcon fontSize="small"/>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Redémarrage de l'application" arrow enterDelay={500} disableInteractive>
                <Button
                  aria-label="restart application"
                  size="small"
                  disableRipple
                  sx={{
                    backgroundColor: "#5d4037",
                    color: "#fff",
                    transition: "none",
                    ":hover": {backgroundColor: "#3e2723"},
                    ":disabled": {backgroundColor: "rgba(0, 0, 0, 0.12)"}
                  }}
                  disabled={restartTheziaInProgress}
                  onClick={() => dispatch(appAction("restart_thezia", product.get("reference"), {
                    name: product.get("name")
                  }))}
                >
                  <RestartAltIcon fontSize="small"/>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Fiche" arrow enterDelay={500} disableInteractive>
                <Button
                  aria-label="view"
                  size="small"
                  disableRipple
                  sx={{backgroundColor: "#0288d1", color: "#fff", ":hover": {backgroundColor: "#01579b"}}}
                  onClick={() => navigate(`/equipements/${product.get("id")}`)}
                >
                  <ArticleOutlinedIcon fontSize="small"/>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs sx={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Éditer" arrow enterDelay={500} disableInteractive>
                <Button
                  aria-label="edit"
                  size="small"
                  disableRipple
                  sx={{
                    backgroundColor: "#ffa000",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "#ff8f00"
                    },
                    ":disabled": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)"
                    }
                  }}
                  onClick={() => navigate(`/equipements/edition/${product.get("id")}`)}
                  disabled={!user.getIn(["rights", "products", "edit"])}
                >
                  <EditOutlinedIcon fontSize="small"/>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default ProductCard;