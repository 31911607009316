import {takeLatest, put, call, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError, handleSuccess} from '../../../_helpers/api';
import {deleteItem, getAllItems} from '../../../_store/resources/sagas';
import {
  userListDeleteUserSuccess,
  userListFetchSuccess, userListSetFilters,
  userListUpdateSuccess
} from './actions';

const emitter = "USER_LIST";

function* userListFetch() {
  try {
    const user = yield select(state => state.getIn(["auth", "user"]));
    const filters = yield select(state => state.getIn(["scenes", "userList", "filters"]));
    yield put(userListSetFilters({
      ...filters.toJS(),
      "organization": user.get("organization")
    }));
    yield put(userListFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des utilisateurs");
  }
}

function* userListUpdate(action) {
  try {
    const {params} = action.payload;
    const user = yield select(state => state.getIn(["auth", "user"]));
    yield call(getAllItems, {
      payload: {
        name: "users",
        params: {
          ...params,
          organization: user.get("organization")
        },
        emitter,
        apiName: "auth"
      }
    });

    yield put(userListUpdateSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des utilisateurs");
  }
}

function* userListDelete(action) {
  try {
    const {payload} = action;

    yield call(deleteItem, {
      payload: {
        name: "users",
        uri: payload.iri,
        emitter,
        apiName: "auth"
      }
    });

    yield call(handleSuccess, "L'utilisateur a été supprimé avec succès");
    const filters = yield select(state => state.getIn(["scenes", "userList", "filters"]));
    yield call(userListUpdate, {
      payload: {
        params: filters.toJS()
      }
    });
    yield put(userListDeleteUserSuccess());
  } catch (e) {
    yield call(handleError, 500, "Une erreur est survenue lors de la suppression de l'utilisateur", true);
  }
}

export function* watchUserList() {
  yield takeLatest(actionTypes.USER_LIST_FETCH, userListFetch);
  yield takeLatest(actionTypes.USER_LIST_UPDATE, userListUpdate);
  yield takeLatest(actionTypes.USER_LIST_DELETE_USER, userListDelete);
}

export default watchUserList;
