import {cloneDeep} from 'lodash';
import {default as i18n} from 'i18next';
import translations from './../_locales';
import Immutable from 'immutable';
i18n.addResourceBundle(i18n.language, "translations", translations[i18n.language]);

export const uniqueId = () => {
  return `${Math.random().toString(36).substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}`;
};

export const isJson = str => {
  try {
    JSON.stringify(str);
  } catch (e) {
    return false;
  }
  return typeof str === "object";
};
export const getServiceName = () => {
  return window.location.pathname.replace(/^\/([^/]*).*$/, '$1');
};
export const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
// Permet de récupérer un paramètre dans l'url comme le produit (_p)...
export const urlParam = name => {
  const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
  if (results) {
    return decodeURI(results[1]) || 0;
  }
  return null;
};

export const arrayDiff = (a1, a2) => {
  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }
  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    }
  }

  for (let k in a) {
    diff.push(k);
  }
  return diff;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Octets';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};

export const previousPath = path => {
  let to = path.lastIndexOf("/");
  to = to === -1 ? path.length : to + 1;
  return path.substring(0, to).replace(/\/$/, "");
};

export const reorderList = (list, startIndex, endIndex) => {
  const listCopy = cloneDeep(list);
  const [removed] = listCopy.splice(startIndex, 1);
  listCopy.splice(endIndex, 0, removed);
  listCopy.forEach((el, index) => {
    el.position = index;
  });
  return listCopy;
};

export const moveToList = (sourceList, destinationList, sourceIndex, destinationIndex) => {
  const sourceListCopy = cloneDeep(sourceList);
  const destinationListCopy = cloneDeep(destinationList);
  const [removed] = sourceListCopy.splice(sourceIndex, 1);
  destinationListCopy.splice(destinationIndex, 0, removed);
  sourceListCopy.forEach((el, index) => {
    el.position = index;
  });
  destinationListCopy.forEach((el, index) => {
    el.position = index;
  });
  return [sourceListCopy, destinationListCopy];
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h${minutes}`;
};

export const matchPathname = (location, string) => {
  return location.pathname === string || location.pathname.startsWith(`${string}/`);
};

export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const bordereauAsOptions = [{
  label: "Voir tout",
  value: ""
}, {
  label: "Hôtels",
  value: `hotels`
},{
  label: "Fêtes et manifestations",
  value: "eventCalendars"
},{
  label: "Restaurants",
  value: "restaurants"
},{
  label: "Hébergements locatifs",
  value: "rentalAccommodations"
},{
  label: "Patrimoines culturels",
  value: "culturalHeritages"
},{
  label: "Pointeurs",
  value: "wayPoints"
},{
  label: "Itinéraires",
  value: "itineraries"
},{
  label: "Loisirs",
  value: "leisures"
},{
  label: "Patrimoines naturels",
  value: "naturalHeritages"
},{
  label: "Hébergements plein air",
  value: "outdoorHotels"
},{
  label: "Commerces et services",
  value: "shops"
},{
  label: "Activités sportives et culturelles",
  value: "sportiveAndCulturalActivities"
},{
  label: "Dégustations",
  value: "tastings"
}];

export const languagesAsOptions = [
  {label: "Anglais", value: "en"},
  {label: "Allemand", value: "de"},
  {label: "Basque", value: "eu"},
  {label: "Espagnol", value: "es"},
  {label: "Français", value: "fr"},
  {label: "Italien", value: "it"},
  {label: "Néerlandais", value: "nl"},
  {label: "Norvégien", value: "no"}
];

export const stateAsOptions = [{
  label: i18n.t('waiting'),
  value: 'waiting'
}, {
  label: i18n.t('processing'),
  value: 'processing'
}, {
  label: i18n.t('completed'),
  value: 'completed'
}];

export const modeAsOptions = [{
  label: i18n.t('only_tourism'),
  value: 'only_tourism'
}, {
  label: i18n.t('only_town_all'),
  value: 'only_town_all'
}, {
  label: i18n.t('all'),
  value: 'all'
}];

export const poiTypesAsAcronym = {
  WayPoint: "PON",
  CulturalHeritage: "PCU",
  SportiveAndCulturalActivity: "ASC",
  DataDocument: "DOC",
  NaturalHeritage: "PNA",
  RentalAccommodation: "HLO",
  Hotel: "HOT",
  Tasting: "DEG",
  Shop: "COS",
  OutdoorHotel: "HPA",
  EventCalendar: "FMA",
  Restaurant: "RES",
  Leisure: "LOI",
  Itinerary: "ITI"
}

Immutable.Map.prototype.findIn = function(uri) {
  if (this.getIn(["members"])) {
    return this.getIn(["members", this.getIn(["indexByUri", uri])]);
  } else {
    return this.get("member");
  }
};

Immutable.Map.prototype.findInId = function(id) {
  if (this.getIn(["members"])) {
    return this.getIn(["members", this.getIn(["indexById", id])]);
  } else {
    return this.get("member");
  }
};

// Fonction récursive pour passer toutes les valeurs d'un objet à true
export const setAllToTrue = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        setAllToTrue(obj[key]);
      } else {
        obj[key] = true;
      }
    }
  }
}