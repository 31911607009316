import {useCallback} from 'react';

function useAdminRedirect() {
  return useCallback(service => {
    switch (service.getIn(["article", "id"])) {
      case 6:
        window.open(`https://admin-funbox.cartelmatic.com/${service.get("reference")}/?_k=${service.get("apiKey")}`, "_blank");
        break;
      case 5:
        window.open(`https://admin-baliz.cartelmatic.com/${service.get("reference")}/?_k=${service.get("apiKey")}`, "_blank");
        break;
      case 7:
        window.open(`https://data-manager.cartelmatic.com/${service.get("reference")}/?_k=${service.get("apiKey")}`, "_blank");
        break;
      case 14:
        window.open(`https://v4.cartelmatic.com/${service.get("reference")}/Admin/index.php`, "_blank");
        break;
      default:
    }
  }, []);
}

export default useAdminRedirect;