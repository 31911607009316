import {createSelector} from 'reselect';

export const selectServiceForm = createSelector([
  state => state.getIn(["scenes", "serviceForm", "isSubmitting"]),
  state => state.getIn(["scenes", "serviceForm", "isSubmitted"]),
  state => state.getIn(["scenes", "serviceForm", "resources", "services", "member"])
], (isSubmitting, isSubmitted, service) => ({
  isSubmitting,
  isSubmitted,
  service
}));