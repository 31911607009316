import React from 'react';
import Select from '@mui/material/Select';
import {getIn} from 'formik';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {FormHelperText, IconButton, InputLabel} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SelectType = ({field, form, icon = null, options, useChip, clearable, ...rest}) => {
  const error = getIn(form.errors, field.name) || false;
  const touched = getIn(form.touched, field.name) || false;
  const handleClearClick = () => {
    if(rest.multiple){
      form.setFieldValue(field.name, []);
    } else {
      form.setFieldValue(field.name, "");
    }
  }
  return (
    <FormControl sx={{display: "flex", flexGrow: 1}} size={"small"}>
      <InputLabel id="demo-simple-select-label" error={error && touched}>{rest.label}{rest.required && ("*")}</InputLabel>
      <Select
        id={field.name}
        sx={{width: "100%", paddingRight: 0}}
        error={error && touched}
        fullWidth
        size="small"
        {...field}
        {...rest}
        value={rest.multiple && !Array.isArray(field.value) ? [] : field.value}
        endAdornment={clearable ? (
          <IconButton
            size="small"
            sx={{
              display: field.value !== "" ? "flex" : "none",
              position: "absolute",
              right: "30px"
            }}
            onClick={handleClearClick}
          >
            <CloseIcon fontSize="small"/>
          </IconButton>
        ) : null}
        onChange={(e, newValue) => {
          const val = newValue.props.value;
          if(rest.multiple){
            if(val === ""){
              return form.setFieldValue(field.name, []);
            }
            let oldValue = !Array.isArray(field.value) ? [] : [...field.value];
            if(oldValue.includes(val)){
              oldValue = oldValue.filter(value => value !== val);
            } else {
              oldValue.push(val);
            }
            form.setFieldValue(field.name, oldValue);
          } else {
            if(val === ""){
              return form.setFieldValue(field.name, "");
            } else {
              form.setFieldValue(field.name, val)
            }
          }
        }}
      >
        {options.map((option, key) => {
          if (option.placeholder) {
            return <MenuItem key={key} value={option.value} disabled style={{backgroundColor: "transparent"}}>{option.label}</MenuItem>
          } else {
            return <MenuItem key={key} value={option.value} selected disabled={option.value === null}>{option.label}</MenuItem>
          }
        })}
      </Select>
      {error && touched && (
        <FormHelperText error>{error && touched ? error?.value || error : null}</FormHelperText>
      )}
    </FormControl>

  )
};

export default SelectType;
