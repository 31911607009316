import API from '../apiGenerator';
import {API_MANAGER} from '../../_constants/api';
import {getCookie} from '../../_helpers/cookie';

// Alphabétique
export const resourcesNames = {
  configurations: {
    plural: "CONFIGURATIONS",
    singular: "CONFIGURATION"
  },
  documents: {
    plural: "DOCUMENTS",
    singular: "DOCUMENT"
  },
  organizations: {
    plural: "ORGANIZATIONS",
    singular: "ORGANIZATION"
  },
  products: {
    plural: "PRODUCTS",
    singular: "PRODUCT"
  },
  tickets: {
    plural: "TICKETS",
    singular: "TICKET"
  },
  webServices: {
    plural: "SERVICES",
    singular: "SERVICE"
  }
};

const serviceManager = (entity = null, entities = []) => {

  const api = new API({
    base_url: API_MANAGER,
    token: getCookie("token-v2")
  });

  if (entity) {
    api.createEntity(entity);
  }

  if (entities) {
    api.createEntities(entities);
  }

  if (!entity && entities.length === 0) {
    // Alphabétique
    api.createEntities([
      "configurations",
      "documents",
      "organizations",
      "products",
      "tickets",
      "webServices"
    ])
  }

  return api;
};

export default serviceManager;