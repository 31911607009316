import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  pageLoaded: false,
  tableLoading: true,
  filters: {
    page: 1,
    itemsPerPage: 20,
    "order[updatedAt]": "desc"
  }
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actionTypes.RIGHTS_GROUP_LIST_FETCH:
    case actionTypes.RIGHTS_GROUP_LIST_UPDATE:
      return state.set("tableLoading", true);
    case actionTypes.RIGHTS_GROUP_LIST_FETCH_SUCCESS:
      return state.merge({
        pageLoaded: true,
        tableLoading: false
      });
    case actionTypes.RIGHTS_GROUP_LIST_UPDATE_SUCCESS:
      return state.merge({
        tableLoading: false,
        data: fromJS(payload)
      });
    case actionTypes.RIGHTS_GROUP_LIST_RESET:
      return initialState;
    case actionTypes.RIGHTS_GROUP_LIST_SET_FILTERS:
      return state.mergeIn(["filters"], fromJS(payload.filters));
    case actionTypes.RIGHTS_GROUP_LIST_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    default:
      return state;
  }
};

export default reducer;