import React from 'react';
import Box from '@mui/material/Box';

function TabPanel({children, value, index, ...other}) {
  return (
    <div
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;