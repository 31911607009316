import {all, takeLatest, put, call, select} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getAllItems} from '../../../_store/resources/sagas';
import {
  serviceListFetchSuccess,
  serviceListUpdateSuccess
} from './actions';
import {selectAuthUser} from '../../../_store/auth/selectors';

const emitter = "SERVICE_LIST";

function* getServices(params = {}) {
  const user = yield select(selectAuthUser);
  const [services1, services2] = yield all([
    call(getAllItems, {
      payload: {
        name: "webServices",
        params: {
          pagination: false,
          "organization.id": user.get("organization"),
          "not-like[articleStatus.id]": 10,
          ...params
        },
        emitter,
        store: false
      }
    }),
    call(getAllItems, {
      payload: {
        name: "webServices",
        params: {
          pagination: false,
          "advertisingAgencies.id": user.get("organization"),
          "not-like[articleStatus.id]": 10,
          ...params
        },
        emitter,
        store: false
      }
    })
  ]);
  const arr = [...services1.members, ...services2.members.map(service => ({...service, isAdvertisingAgency: true}))];
  // arr.sort((a, b) => {
  //   if (a.isMonitored !== b.isMonitored) {
  //     return a.isMonitored ? -1 : 1;
  //   }
  //   if (a.isOnline !== b.isOnline) {
  //     return a.isOnline ? -1 : 1;
  //   }
  //   return a.name.localeCompare(b.name);
  // });
  yield put({
    type: `${emitter}_STORE_REQUEST`,
    payload: {
      data: {
        members: arr,
        count: arr.length
      },
      name: "services"
    }
  });
}

function* serviceListFetch() {
  try {
    yield call(getServices);
    yield put(serviceListFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des services");
  }
}

function* serviceListUpdate(action) {
  try {
    const {params} = action.payload;
    yield call(getServices, params);
    yield put(serviceListUpdateSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des services");
  }
}

export function* watchServiceList() {
  yield takeLatest(actionTypes.SERVICE_LIST_FETCH, serviceListFetch);
  yield takeLatest(actionTypes.SERVICE_LIST_UPDATE, serviceListUpdate);
}

export default watchServiceList;
