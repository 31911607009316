import actionTypes from './actionTypes';

export const tryAutoSignIn = () => ({
  type: actionTypes.TRY_AUTO_SIGN_IN
});

export const tryAutoSignInSuccess = () => ({
  type: actionTypes.TRY_AUTO_SIGN_IN_SUCCESS
});

export const tryAutoSignInFailure = () => ({
  type: actionTypes.TRY_AUTO_SIGN_IN_FAILURE
});

export const signIn = values => {
  return {
    type: actionTypes.SIGN_IN,
    payload: {
      values
    }
  }
};

export const signInSuccess = payload => ({
  type: actionTypes.SIGN_IN_SUCCESS,
  payload
});

export const signInFailure = payload => {
  return {
    type: actionTypes.SIGN_IN_FAILURE,
    payload
  }
};

export const signOut = () => ({
  type: actionTypes.SIGN_OUT
});

export const forgetPasswordReset = () => ({
  type: actionTypes.FORGET_PASSWORD_RESET
});

export const forgetPasswordAskReset = values => ({
  type: actionTypes.FORGET_PASSWORD_ASK_RESET,
  payload: values
});

export const forgetPasswordAskResetSuccess = () => ({
  type: actionTypes.FORGET_PASSWORD_ASK_RESET_SUCCESS
});

export const forgetPasswordAskResetFailure = () => ({
  type: actionTypes.FORGET_PASSWORD_ASK_RESET_FAILURE
});

export const resetPasswordCheckRecoverToken = (token, email) => ({
  type: actionTypes.RESET_PASSWORD_CHECK_RECOVER_TOKEN,
  payload: {
    token,
    email
  }
});

export const resetPasswordCheckRecoverTokenSuccess = data => ({
  type: actionTypes.RESET_PASSWORD_CHECK_RECOVER_TOKEN_SUCCESS,
  payload: {
    data
  }
});

export const resetPasswordCheckRecoverTokenFailure = () => ({
  type: actionTypes.RESET_PASSWORD_CHECK_RECOVER_TOKEN_FAILURE
});

export const resetPasswordSave = (token, values) => ({
  type: actionTypes.RESET_PASSWORD_SAVE,
  payload: {
    token,
    values
  }
});

export const resetPasswordSaveSuccess = () => ({
  type: actionTypes.RESET_PASSWORD_SAVE_SUCCESS
});

export const resetPasswordSaveFailure = () => ({
  type: actionTypes.RESET_PASSWORD_SAVE_FAILURE
});

export const accountActivationCheckConfirmationToken = (token, email) => ({
  type: actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN,
  payload: {
    token,
    email
  }
});

export const accountActivationCheckConfirmationTokenSuccess = data => ({
  type: actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_SUCCESS,
  payload: {
    data
  }
});

export const accountActivationCheckConfirmationTokenFailure = () => ({
  type: actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN_FAILURE
});

export const accountActivationSave = (token, values, navigate) => ({
  type: actionTypes.ACCOUNT_ACTIVATION_SAVE,
  payload: {
    token,
    values,
    navigate
  }
});

export const accountActivationSaveSuccess = () => ({
  type: actionTypes.ACCOUNT_ACTIVATION_SAVE_SUCCESS
});

export const accountActivationSaveFailure = () => ({
  type: actionTypes.ACCOUNT_ACTIVATION_SAVE_FAILURE
});