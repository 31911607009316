import React from 'react';
import {useSelector} from 'react-redux';
import {fromJS} from 'immutable';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import '@cartelmatic/application-analytics/dist/styles.css';
import {AppStatistics} from '@cartelmatic/application-analytics';

import {selectStatistics} from './_store/selectors';

function Statistics() {
  const {
    analyticsService
  } = useSelector(selectStatistics);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{mb: 2}}>
          Statistiques
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{width: "100%"}}>
          <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
            <AppStatistics analytics={analyticsService || fromJS([])}/>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Statistics;