import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {FastField, Form, Formik} from 'formik';
import debounce from 'lodash/debounce';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Button from '@mui/material/Button';

import validationSchema from './validationSchema';
import {userFormFetch, userFormReset, userFormSave, userFormSendResetPassword} from './_store/actions';
import {selectUserForm} from './_store/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import Loading from '../../_components/Loading/Loading';
import AutoComplete from '../../_components/_form/AutoComplete/AutoComplete';
import serviceAuth from '../../_services/_auth/service';
import {selectAuthOrganization} from '../../_store/auth/selectors';
import CheckboxType from '../../_components/_form/CheckboxType/CheckboxType';

function UserForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: paramsId} = useParams();
  const id = paramsId ? parseInt(paramsId) : paramsId;
  const organization = useSelector(selectAuthOrganization);
  const {pageLoaded, isSubmitting, isSubmitted, user, connectedUser} = useSelector(selectUserForm);

  const [initialValues, setInitialValues] = useState({
    fullName: "",
    email: "",
    groups: []
  });

  // Redirection vers la page d'erreur si l'id n'est pas un entier
  useEffect(() => {
    if (id !== undefined && !id) {
      navigate("/erreur", {state: {}, replace: true});
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(userFormFetch(id));
    }
    return () => dispatch(userFormReset());
  }, [id]);

  useEffect(() => {
    if (user) {
      if ((user.get("roles").includes("ROLE_SUPER_ADMIN") || user.get("roles").includes("ROLE_CARTEL")) && !connectedUser.getIn(["rights", "isSuperAdminOrCartel"])) {
        navigate("/acces-refuse", {replace: true});
      }
      setInitialValues({
        ["@id"]: user.get("@id"),
        fullName: user.get("fullName"),
        email: user.get("email"),
        groups: user.get("groups").map(group => ({label: group.get("name"), value: group.get("@id")})).toJS()
      });
    }
  }, [user, connectedUser]);

  const validate = useCallback(debounce(values => {
    const apiAuth = serviceAuth();
    const checkCode = () => apiAuth["users"].getAll({email: values.email});
    if (values.email) {
      return checkCode().then(r => {
        if (r.count > 0 && r.members[0]["@id"] !== values["@id"]) {
          return {
            email: "Cette adresse e-mail est déjà utilisée"
          }
        } else {
          return {};
        }
      });
    }
  }, 500), []);

  const handleSubmit = values => {
    dispatch(userFormSave(values));
  };

  useEffect(() => {
    if (isSubmitted) {
      navigate("/comptes/utilisateurs");
    }
  }, [isSubmitted]);

  return (
    <>
      <Loading isLoading={Boolean(id && !pageLoaded)} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {Boolean(!id || pageLoaded) && (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{mb: 2}}>
              {id ? `Édition de l'utilisateur : ${user.get("fullName")}` : "Ajout d'un utilisateur"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
            >
              <Typography key="1" color="inherit">
                Comptes
              </Typography>
              <Typography
                key="2"
                color="inherit"
                sx={{color: "#1976d2", "&:hover": {cursor: "pointer", textDecoration: "underline"}}}
                onClick={() => navigate("/comptes/utilisateurs")}
              >
                Utilisateurs
              </Typography>
              <Typography key="3" color="text.primary">
                {id ? `Édition de l'utilisateur n°${id}` : "Ajout"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validate={validate}
                  onSubmit={handleSubmit}
                >
                  {({values, setFieldValue, dirty}) => {
                    return (
                      <Form style={{width: "100%", display: "flex"}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FastField
                              name="fullName"
                              component={TextType}
                              label="Nom Prénom"
                              placeholder="Ex: Doe John"
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FastField
                              name="email"
                              component={TextType}
                              label="Adresse e-mail"
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoComplete
                              name="groups"
                              multiple
                              size="small"
                              label="Groupes de droits"
                              entityName="groups"
                              searchField="name"
                              selectLabel="name"
                              selectValue="@id"
                              condition={{
                                "organization": organization.get("id")
                              }}
                            />
                          </Grid>
                          {/*<Grid item xs={12}>*/}
                          {/*  <FastField*/}
                          {/*    name="color"*/}
                          {/*    component={ColorPickerType}*/}
                          {/*  />*/}
                          {/*</Grid>*/}
                          {/*<FormSection>Gestion des droits</FormSection>*/}
                          {/*<Grid item xs={12}>*/}
                          {/*  <ul style={{padding: 0, margin: 0, listStylePosition: "inside"}}>*/}
                          {/*    <Typography color="primary" sx={{fontWeight: 700}}>*/}
                          {/*      <li>Baliz</li>*/}
                          {/*    </Typography>*/}
                          {/*  </ul>*/}
                          {/*</Grid>*/}
                          {/*<Grid item xs={12}>*/}
                          {/*  <TableContainerMui>*/}
                          {/*    <Table aria-label="customized table">*/}
                          {/*      <TableHead>*/}
                          {/*        <TableRow>*/}
                          {/*          <StyledTableCell>Dénomination</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Administrateur</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Ajouter</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Voir</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Modifier</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Déplacer</StyledTableCell>*/}
                          {/*          <StyledTableCell align="center">Supprimer</StyledTableCell>*/}
                          {/*        </TableRow>*/}
                          {/*      </TableHead>*/}
                          {/*      <TableBody>*/}
                          {/*        {rolesBaliz.map((item, index) => (*/}
                          {/*          <StyledTableRow key={index}>*/}
                          {/*            <StyledTableCell component="th" scope="row">*/}
                          {/*              {item.name}*/}
                          {/*            </StyledTableCell>*/}
                          {/*            {item.roles.map((role, index) => index === 0 ? (*/}
                          {/*                <StyledTableCell align="center" key={index}>*/}
                          {/*                  <FastField*/}
                          {/*                    children={() => (*/}
                          {/*                      <Checkbox*/}
                          {/*                        name="roles"*/}
                          {/*                        value={role}*/}
                          {/*                        checked={values.roles.includes(role)}*/}
                          {/*                        onChange={e => handleCheckedAll(e, item.roles, values, setFieldValue)}*/}
                          {/*                        sx={{padding: 0}}*/}
                          {/*                      />*/}
                          {/*                    )}*/}
                          {/*                  />*/}
                          {/*                </StyledTableCell>*/}
                          {/*              ) : (*/}
                          {/*                <StyledTableCell align="center" key={index}>*/}
                          {/*                  <FastField*/}
                          {/*                    children={() => (*/}
                          {/*                      <Checkbox*/}
                          {/*                        name="roles"*/}
                          {/*                        value={role}*/}
                          {/*                        checked={values.roles.includes(role)}*/}
                          {/*                        onChange={e => handleCheckedOne(e, item.roles, values, setFieldValue)}*/}
                          {/*                        sx={{padding: 0}}*/}
                          {/*                      />*/}
                          {/*                    )}*/}
                          {/*                  />*/}
                          {/*                </StyledTableCell>*/}
                          {/*              )*/}
                          {/*            )}*/}
                          {/*          </StyledTableRow>*/}
                          {/*        ))}*/}
                          {/*      </TableBody>*/}
                          {/*    </Table>*/}
                          {/*  </TableContainerMui>*/}
                          {/*</Grid>*/}
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <Button color="warning" variant="contained" onClick={() => dispatch(userFormSendResetPassword({email: user.get("email")}))}>
                                Envoyer un email de réinitialisation de mot de passe
                              </Button>
                              <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<SaveOutlinedIcon/>}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={!dirty}
                              >
                                Sauvegarder
                              </LoadingButton>
                              <Button
                                variant="contained"
                                color="cancel"
                                onClick={() => navigate("/comptes/utilisateurs")}
                              >
                                Annuler
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default UserForm;
