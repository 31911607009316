import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  pageLoaded: false,
  servicesLoaded: true
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actionTypes.SERVICE_LIST_UPDATE:
      return state.set("servicesLoaded", false);
    case actionTypes.SERVICE_LIST_FETCH_SUCCESS:
      return state.set("pageLoaded", true);
    case actionTypes.SERVICE_LIST_UPDATE_SUCCESS:
      return state.set("servicesLoaded", true);
    case actionTypes.SERVICE_LIST_RESET:
      return initialState;
    case actionTypes.SERVICE_LIST_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    default:
      return state;
  }
};

export default reducer;