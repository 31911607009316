import {call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError, handleSuccess} from '../../../_helpers/api';
import {getItemById, postItem, putItem} from '../../../_store/resources/sagas';
import {userFormFetchSuccess} from './actions';
import {saveRequestSuccess} from '../../../_store/resources/actions';
import {removeAccents} from '../../../_helpers/string';
import serviceAuth from '../../../_services/_auth/service';
import {REDIRECT} from '../../../_constants/api';

const emitter = "USER_FORM";

function* userFormFetch(action) {
  try {
    const {payload} = action;
    yield call(getItemById, {
      payload: {
        name: "users",
        id: payload.id,
        emitter,
        apiName: "auth"
      }
    });
    yield put(userFormFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération de l'utilisateur", true);
  }
}

function* userFormSave(action) {
  try {
    const {values} = action.payload;

    const organization = yield select(state => state.getIn(["auth", "resources", "organizations", "member"]));

    const item = {
      ...(values["@id"] ? {"@id": values["@id"]} : {}),
      fullName: values.fullName,
      ...(values["@id"] ? {} : {username: removeAccents(values.fullName).toLowerCase().replace(" ", ".")}),
      ...(values["@id"] ? {} : {roles: ["ROLE_ADMIN"]}),
      email: values.email,
      groups: values.groups.map(group => group.value),
      organization: organization.get("id")
    }

    if (values["@id"]) {
      yield call(putItem, {
        payload: {
          name: "users",
          item,
          emitter,
          apiName: "auth"
        }
      });
    } else {
      yield call(postItem, {
        payload: {
          name: "users",
          item,
          emitter,
          apiName: "auth"
        }
      });
      const apiAuth = serviceAuth();
      yield call(apiAuth["accountActivation"].update, {email: values.email, redirect: REDIRECT});
    }
    yield put(saveRequestSuccess(emitter));
  } catch (e) {
    yield call(handleError, 500, "Erreur durant la sauvegarde de l'utilisateur", true);
  }
}

function* userFormSendResetPassword(action){
  try {
    const {values} = action.payload;
    const apiAuth = serviceAuth();
    yield call(apiAuth["askResetPassword"].update, {
      ...values, redirect: "https://manager.cartelmatic.com"
    });
    yield call(handleSuccess, "Demande de réinitialisation envoyée");
  } catch (e) {
    yield call(handleError, e.code, "Impossible de répondre à la demande de réinitialisation du mot de passe");
  }
}

export function* watchUserForm() {
  yield takeLatest(actionTypes.USER_FORM_FETCH, userFormFetch);
  yield takeLatest(actionTypes.USER_FORM_SAVE, userFormSave);
  yield takeLatest(actionTypes.USER_FORM_SEND_PASSWORD_RESET, userFormSendResetPassword);
}

export default watchUserForm;
