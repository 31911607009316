import axios from 'axios';

import API from '../apiGenerator';
import {API_MONITORING} from '../../_constants/api';
import {bearer} from '../../_helpers/api';
import {getCookie} from '../../_helpers/cookie';

let instance = null;

// Alphabétique
export const resourcesNames = {
  lastTasks: {
    plural: "LAST_TASKS",
    singular: "LAST_TASK"
  },
  logs: {
    plural: "LOGS",
    singular: "LOG"
  },
  taskQueues: {
    plural: "TASK_QUEUES",
    singular: "TASK_QUEUE"
  }
};

const service = () => {

  const api = new API({
    base_url: API_MONITORING,
    token: getCookie("token-v2")
  });

  api.addEndpoints({
    name: "validRange",
    request: {
      get: params => axios.get(`${API_MONITORING}/range_connexions/valid-range`, bearer({}, params)).then(response => response)
    }
  });

  api.addEndpoints({
    name: "tasksForce",
    request: {
      create: data => axios.post(`${API_MONITORING}/tasks-force`, data, bearer()).then(response => response.data)
    }
  });

  api.createEntities([
    "lastTasks",
    "logs",
    "taskQueues"
  ]);

  return api;
};

const serviceMonitoring = () => {
  if (!instance || !getCookie("token-v2")) {
    instance = service();
  }
  return instance;
};

export default serviceMonitoring;