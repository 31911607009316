import React, {useEffect, useState} from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {styled} from '@mui/material/styles';
import {TableCell, TableRow} from '@mui/material';

const LoadingContainer = styled(({children, className}) => (
  <div
    id="is-loading"
    className={className}
  >
    {children}
  </div>
))(({backgroundColor, animationDurationMs, wholeScene, style}) => ({
  zIndex: 1098,
  backgroundColor: backgroundColor,
  transitionDuration: `${animationDurationMs}ms`,
  top: wholeScene ? "56px" : "0",
  "@media screen and (min-width: 0px) and (orientation: landscape)": {
    top: wholeScene ? "48px" : "0"
  },
  "@media screen and (min-width: 600px)": {
    top: wholeScene ? "64px" : "0"
  },
  "@media screen and (min-width: 1200px)": {
    top: wholeScene ? "106px" : "0"
  },
  ...style
}));

function Loading(
  {
    isLoading,
    animateOnMount = false,
    backgroundColor = "#fff",
    animationDurationMs = 300,
    wholeScene = false,
    isTable = false,
    containerStyle = null,
    ...rest
  }
) {
  const [mount, setMount] = useState(!!isLoading);
  const [className, setClassName] = useState(["is-loading-container", animateOnMount ? "is-loading-container-hidden" : "is-loading-container-visible"]);

  useEffect(() => {
    let mountTimeout, animateTimeout;
    if (isLoading) {
      setClassName(["is-loading-container", "is-loading-container-visible"]);
      setMount(true);
    } else {
      setClassName(["is-loading-container", "is-loading-container-hidden"]);
      mountTimeout = setTimeout(() => {
        setMount(false);
      }, animationDurationMs);
    }
    return () => {
      clearTimeout(mountTimeout);
      clearTimeout(animateTimeout);
    };
  }, [isLoading, animateOnMount, animationDurationMs]);

  if (mount) {
    const loader = (
      <LoadingContainer
        className={className.join(" ")}
        backgroundColor={backgroundColor}
        animationDurationMs={animationDurationMs}
        wholeScene={wholeScene}
        style={containerStyle ? containerStyle : {}}
      >
        <BeatLoader color="#14ffc7" loading={true} size={16} {...rest}/>
      </LoadingContainer>
    );

    if (isTable) {
      return (
        <TableRow sx={{position: "absolute", top: 0, right: 0, bottom: 0, left: 0}}>
          <TableCell sx={{border: "none"}}>
            {loader}
          </TableCell>
        </TableRow>
      )
    }
    return loader;
  }
  return null;
}

export default Loading;