import axios from 'axios';
import {camelToSnake, pascalCase} from '../_helpers/string';

class API {
  constructor({base_url, alias = null, token = null}) {
    this.base_url = base_url;
    this.alias = alias;
    this.token = token;
  }

  /**
   * Create and _store a single entity's endpoints
   * @param {string} entity
   */
  createEntity(entity) {
    const name = camelToSnake(entity);
    this[entity] = this.createBasicCRUDEndpoints({name, entity});
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach((entity) => {
      this.createEntity(entity);
    })
  }

  addEndpoints({name, request}) {
    this[name] = request;
  }

  bearer(config = {}, params = {}) {
    if (this.token) {
      config.headers = {
        "Authorization": "Bearer " + this.token,
        "Cache-Control": "max-age=0, private, no-cache"
      }
    }
    config.params = params;
    return config;
  }

  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {string} name
   * @param {string} entity
   */
  createBasicCRUDEndpoints({name, entity}) {
    const endpoints = {};
    const resourceURL = this.alias ? `${this.base_url}/${this.alias}/${name}` : `${this.base_url}/${name}`;
    endpoints.getAll = (params = {}, customIndexes = [], config = {}, toNormalize = true) => axios.get(resourceURL, this.bearer(config, params)).then(response => {
      const count = parseInt(response.data["hydra:totalItems"], 10);
      let indexes = {};

      if (customIndexes.length > 0) {
        customIndexes.forEach((customIndex, index) => {
          const i = response.data["hydra:member"].reduce((obj, item, indexItem) => {
            obj[item[customIndex.name]] = customIndex.value === "index" ? indexItem : item[customIndex.value];
            return obj;
          }, {});
          indexes = {
            ...indexes,
            [`index${pascalCase(customIndex.value)}By${pascalCase(customIndex.name)}`]: i
          };
        });
      }
      return toNormalize ? {
        members: response.data["hydra:member"],
        count,
        indexByUri: response.data["hydra:member"].reduce((i, element, key) => {
          i[element["@id"]] = key;
          return i;
        }, {}),
        indexes,
        pageCount: params.itemsPerPage && count > 0 ? Math.ceil(count / params.itemsPerPage) : 0,
        request: response.request.responseURL
      } : response.data
    });

    endpoints.getOne = (uri, params = {}, config = {}, toNormalize = true) => axios.get(`${this.base_url}${uri}`, this.bearer(config, params)).then(response => {
      return toNormalize ? {
        member: response.data,
        count: 1,
        '@id': response.data['@id']
      } : response.data;
    });

    endpoints.getOneById = (id, params = {}, config = {}, toNormalize = true) => axios.get(`${resourceURL}/${id}`, this.bearer(config, params)).then(response => {
      return toNormalize ? {
        member: response.data,
        count: 1,
        '@id': response.data['@id']
      } : response.data;
    });

    endpoints.post = (data, params = {}, config = {}, toNormalize = true) => axios.post(resourceURL, data, this.bearer(config, params)).then(response => {
      return toNormalize ? {
        member: response.data,
        count: 1,
        '@id': response.data['@id']
      } : response.data;
    });

    endpoints.put = (data, params = {}, config = {}, toNormalize = true) => axios.put(`${this.base_url}${data['@id']}`, data, this.bearer(config, params)).then(response => {
      return toNormalize ? {
        member: response.data,
        count: 1,
        '@id': response.data['@id']
      } : response.data;
    });

    endpoints.delete = (uri, params = {}, config = {}) => axios.delete(`${this.base_url}${uri}`, this.bearer(config, params));
    endpoints.deleteById = (id, params = {}, config = {}) => axios.delete(`${resourceURL}/${id}`, this.bearer(config, params));

    return endpoints;
  }
}

export default API;