import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getAllItems, getItemById} from '../../../_store/resources/sagas';
import {serviceViewFetchSuccess, serviceViewUpdateProductsSuccess} from './actions';

const emitter = "SERVICE_VIEW";

function* serviceViewFetch(action) {
  try {
    const {payload} = action;

    const productsFilters = yield select(state => state.getIn(["scenes", "serviceView", "filters", "products"]));
    const ticketsFilters = yield select(state => state.getIn(["scenes", "serviceView", "filters", "tickets"]));

    const [{member: webService}, products] = yield all([
      call(getItemById, {
        payload: {
          name: "webServices",
          id: payload.id,
          customName: "services",
          emitter
        }
      }),
      call(getAllItems, {
        payload: {
          name: "products",
          params: {
            ...productsFilters.toJS(),
            "order[name]": "asc",
            "webServices.id": payload.id
          },
          emitter
        }
      }),
      call(getAllItems, {
        payload: {
          name: "tickets",
          params: {
            ...ticketsFilters.toJS(),
            "services.id": payload.id
          },
          emitter
        }
      })
    ]);

    const productsId = products.members.map(product => product["id"]);
    let name;
    switch (webService.article.name){
      case "Application Baliz":
        name="baliz_publish";
        break;
      case "Application Funbox":
        name="funbox_api_publish";
        break;
      case "Application V4":
        name="deploy_st3v4";
        break;
      case "Gestionnaire de données (DATA)":
        name="data_publish";
        break;
      case "Analytics":
        name="analytics_deploy";
        break;
      default:
        break;
    }
    yield all([
      call(getAllItems, {
        payload: {
          name: "taskQueues",
          params: {
            name,
            "product[]": productsId
          },
          apiName: "monitoring",
          emitter
        }
      }),
      call(getAllItems, {
        payload: {
          name: "lastTasks",
          params: {
            label: name,
            "product[]": productsId
          },
          apiName: "monitoring",
          emitter
        }
      })
    ]);

    yield put(serviceViewFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération du service", true);
  }
}

function* serviceViewUpdateProducts(action) {
  try {
    const {payload} = action;

    const service = yield select(state => state.getIn(["scenes", "serviceView", "resources", "services", "member"]));

    yield call(getAllItems, {
      payload: {
        name: "products",
        params: {
          ...payload.params,
          "webServices.id": service.get("id")
        },
        emitter
      }
    });

    yield put(serviceViewUpdateProductsSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des équipements associés au service");
  }
}

function* serviceViewUpdateTickets(action) {
  try {
    const {payload} = action;

    const service = yield select(state => state.getIn(["scenes", "serviceView", "resources", "services", "member"]));

    yield call(getAllItems, {
      payload: {
        name: "tickets",
        params: {
          ...payload.params,
          "services.id": service.get("id")
        },
        emitter
      }
    });

    yield put(serviceViewUpdateProductsSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération des tickets associés au service");
  }
}

export function* watchServiceView() {
  yield takeLatest(actionTypes.SERVICE_VIEW_FETCH, serviceViewFetch);
  yield takeLatest(actionTypes.SERVICE_VIEW_UPDATE_PRODUCTS, serviceViewUpdateProducts);
  yield takeLatest(actionTypes.SERVICE_VIEW_UPDATE_TICKETS, serviceViewUpdateTickets);
}

export default watchServiceView;
