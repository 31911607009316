import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {FastField, Form, Formik} from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Button from '@mui/material/Button';

import validationSchema from './validationSchema';
import {productFormFetch, productFormReset, productFormSave} from './_store/actions';
import {selectProductForm} from './_store/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import Loading from '../../_components/Loading/Loading';
import FileType from '../../_components/_form/FileType/FileType';
import TimeType from '../../_components/_form/TimeType/TimeType';
import FormSection from '../../_components/_form/FormSection/FormSection';
import useCheckId from '../../_hooks/useCheckId';
import {initDocumentForm} from '../../_helpers/form';

function ProductForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: paramsId} = useParams();
  // Vérification de l'id présent dans l'url avec redirection vers la page d'erreur si invalide
  const id = useCheckId(paramsId);
  const {isSubmitting, isSubmitted, product} = useSelector(selectProductForm);
  const [initialValues, setInitialValues] = useState(null);

  // Récupération du produit dès qu'on a un id valide et réinitialisation du formulaire en quittant la page
  useEffect(() => {
    if (id) {
      dispatch(productFormFetch(id));
    }
    return () => dispatch(productFormReset());
  }, [id]);

  // Initialisation du formulaire
  useEffect(() => {
    if (product) {
      setInitialValues({
        name: product.get("name"),
        photo: initDocumentForm(product.get("featuredImage")),
        // startTime: product.getIn(["configurations", "start_time", "value"]) || null,
        // endTime: product.getIn(["configurations", "end_time", "value"]) || null
      });
    }
  }, [product]);

  // Soumission du formulaire
  const handleSubmit = values => {
    dispatch(productFormSave(values));
  };

  // Redirection vers la page vue du produit après l'édition
  useEffect(() => {
    if (isSubmitted) {
      navigate(`/equipements/${id}`);
    }
  }, [isSubmitted, id, navigate]);

  return (
    <>
      <Loading isLoading={Boolean(!(product && initialValues))} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {Boolean(product && initialValues) && (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{mb: 2}}>
              {`Édition de l'équipement : ${product.get("name")}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
            >
              <Typography
                key="2"
                color="inherit"
                sx={{color: "#1976d2", "&:hover": {cursor: "pointer", textDecoration: "underline"}}}
                onClick={() => navigate("/equipements")}
              >
                Équipements
              </Typography>
              <Typography key="3" color="text.primary">
                {`Édition de l'équipement n°${id}`}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: {xs: 1, sm: 2}}}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({dirty}) => {
                    return (
                      <Form style={{width: "100%", display: "flex"}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FastField
                              name="name"
                              component={TextType}
                              label="Nom *"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FastField
                              name="photo"
                              component={FileType}
                              label={<span>Photo <span style={{fontSize: "0.875rem", fontStyle: "italic"}}>(5 Mo maximum)</span></span>}
                              maxSize={5242880} // 5Mo max
                            />
                          </Grid>
                          {/*<FormSection>Écran</FormSection>*/}
                          {/*<Grid item xs={12} sm="auto">*/}
                          {/*  <FastField*/}
                          {/*    component={TimeType}*/}
                          {/*    name="startTime"*/}
                          {/*    label="Heure de démarrage"*/}
                          {/*  />*/}
                          {/*</Grid>*/}
                          {/*<Grid item xs={12} sm>*/}
                          {/*  <FastField*/}
                          {/*    component={TimeType}*/}
                          {/*    name="endTime"*/}
                          {/*    label="Heure d'extinction"*/}
                          {/*  />*/}
                          {/*</Grid>*/}
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<SaveOutlinedIcon/>}
                                variant="contained"
                                color="success"
                                type="submit"
                                disabled={!dirty}
                              >
                                <div style={{marginTop: "1px", marginBottom: "-1px"}}>Sauvegarder</div>
                              </LoadingButton>
                              <Button
                                variant="contained"
                                color="cancel"
                                onClick={() => navigate(-1)}
                              >
                                <div style={{marginTop: "1px", marginBottom: "-1px"}}>Annuler</div>
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ProductForm;