import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

// Permet de vérifier qu'un id présent dans l'url est valide
function useCheckId(id) {
  const navigate = useNavigate();
  const [idChecked, setIdChecked] = useState(null);

  useEffect(() => {
    const idParsed = parseInt(id);
    if (idParsed && idParsed.toString().length === id.length) {
      setIdChecked(idParsed);
    } else {
      navigate("/erreur", {state: {}, replace: true});
    }
  }, [id]);

  return idChecked;
}

export default useCheckId;