import React from 'react';
import TextField from '@mui/material/TextField';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function DateType({field, form, icon = null, required, resetButton = true, ...rest}) {
  const [value, setValue] = React.useState(null);
  return <MobileDatePicker
      label={rest.label}
      name={field.name}
      value={value || field.value}
      onChange={(newValue) => {
        setValue(newValue)
      }}
      renderInput={params => <TextField fullWidth size="small" required={required} {...params}/>}
      components={{
        ActionBar: ({onAccept, onCancel, onClear}) => (
          <DialogActions>
            {resetButton && (
              <Button onClick={() => {
                form.setFieldValue(field.name, null);
                onClear();
              }} sx={{marginRight: "auto"}}>Réinitialiser</Button>
            )}
            <Button onClick={onCancel}>Annuler</Button>
            <Button onClick={() => {
              form.setFieldValue(field.name, value);
              onAccept();
            }}>Valider</Button>
          </DialogActions>
        )
      }}
      {...rest}
    />

}

export default DateType;
