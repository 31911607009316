import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {DialogContentText, DialogTitle} from '@mui/material';

function DeleteDialog({open, onClose, onConfirm, title, content, mode = "normal"}) {
  return (
    <Dialog
      maxWidth={"sm"}
      onClose={onClose}
      fullWidth
      open={open}
      aria-labelledby="delete-dialog-title"
    >
      <DialogTitle id="delete-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="cancel">Annuler</Button>
        {mode === "archive" && (
          <Button variant="contained" color="warning"
                  onClick={() => onConfirm("archive")}>
            Archiver
          </Button>
        )}
        <Button variant="contained" color="error"
                onClick={onConfirm}>
          Supprimer
        </Button>
      </DialogActions>

    </Dialog>
  );
}

export default DeleteDialog;