import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, FastField} from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';

import {productListFetch, productListReset, productListUpdate} from './_store/actions';
import {selectProductList} from './_store/selectors';
import Loading from '../../_components/Loading/Loading';
import ProductCard from './_components/ProductCard';
import AutoSave from '../../_components/_form/AutoSave/AutoSave';
import TextType from '../../_components/_form/TextType/TextType';
import SelectType from '../../_components/_form/SelectType/SelectType';

function ProductList() {
  const dispatch = useDispatch();
  const {
    pageLoaded,
    productsLoaded,
    products
  } = useSelector(selectProductList);

  useEffect(() => {
    dispatch(productListFetch());
    return () => dispatch(productListReset());
  }, []);

  const productCards = useMemo(() => {
    return products && products.get("members").size > 0
      ? products.get("members").map((product, index) => <ProductCard key={product.get("reference")} product={product}/>)
      : (
        <Grid item xs={12}>
          <Alert severity="info">
            Aucun produit trouvé
          </Alert>
        </Grid>
      );
  }, [products]);

  const handleSubmit = useCallback(values => {
    dispatch(productListUpdate({
      name: values.search || null,
      ...(values.isOnline !== "" ? {isOnline: values.isOnline} : {})
    }));
  }, []);

  return (
    <>
      <Loading isLoading={!pageLoaded} backgroundColor="#f1f4f9" color="#673ab7" wholeScene/>
      {pageLoaded && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{mb: 2}}>Liste des équipements</Typography>
            <Alert severity={"warning"} sx={{mb: 2}}>Nouvelle fonctionnalité sur votre manager Cartelmatic : la gestion des droits. Une documentation est à votre disposition dans l'onglet Besoin d'aide " ? " en haut à droite -> bouton Voir la documentation -> Manager, Baliz & Stats.</Alert>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: "100%"}}>
              <Paper variant="outlined" elevation={0} sx={{width: "100%", p: 2}}>
                <Grid container spacing={2} sx={{mb: 2}}>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{
                        search: "",
                        isOnline: ""
                      }}
                      onSubmit={(values, {setSubmitting}) => {
                        return new Promise(resolve =>
                          setTimeout(() => {
                            handleSubmit(values);
                            setSubmitting(false);
                            resolve();
                          }, 200)
                        );
                      }}
                    >
                      {formikProps => {
                        return (
                          <Form>
                            <Grid container spacing={2}>
                              <AutoSave debounceMs={500} dirty={formikProps.dirty || formikProps.submitCount > 0}/>
                              <Grid item xs={8}>
                                <FastField
                                  component={TextType}
                                  name="search"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon sx={{color: "action.active"}}/>
                                      </InputAdornment>
                                    )
                                  }}
                                  placeholder="Rechercher par nom..."
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FastField
                                  component={SelectType}
                                  name="isOnline"
                                  displayEmpty
                                  clearable
                                  options={[
                                    {label: "Statut de connexion", value: "", placeholder: true},
                                    {label: "Connecté", value: true},
                                    {label: "Déconnecté", value: false}
                                  ]}
                                  renderValue={selected => {
                                    if (selected === "") {
                                      return <span style={{opacity: 0.38}}>Statut de connexion</span>;
                                    } else {
                                      return selected ? "Connecté" : "Déconnecté";
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Form>
                        )
                      }}
                    </Formik>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{position: "relative"}}>
                  <Loading isLoading={!productsLoaded} backgroundColor="rgba(250, 250, 250, 0.4)" color="transparent" containerStyle={{left: "16px", top: "16px !important"}}/>
                  {products.get("count") > 0 ? (
                    <Grid item xs={12}>
                      <Alert severity="info" icon={false}>
                        {`${products.get("count")} équipement${products.get("count") > 1 ? "s" : ""} trouvé${products.get("count") > 1 ? "s" : ""}`}
                      </Alert>
                    </Grid>
                  ) : null}
                  {productCards}
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ProductList;