import {useCallback, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import debounce from 'lodash/debounce';

function AutoSave({debounceMs, dirty}) {
  const formik = useFormikContext();
  const [lastSaved, setLastSaved] = useState(null);

  const debouncedSave = useCallback(
    debounce(() =>
      formik.submitForm().then(() => {
        setLastSaved(new Date().toISOString())
      }),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  );

  useEffect(() => {
    if (dirty || formik.submitCount > 1) {
      debouncedSave();
    }
  }, [debouncedSave, formik.values, dirty]);
}

export default AutoSave;