import {call, put, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {handleError} from '../../../_helpers/api';
import {getItemById, postItem, putItem} from '../../../_store/resources/sagas';
import {rightsGroupFormFetchSuccess} from './actions';
import {saveRequestSuccess} from '../../../_store/resources/actions';

const emitter = "RIGHTS_GROUP_FORM";

function* rightsGroupFormFetch(action) {
  try {
    const {payload} = action;
    yield call(getItemById, {
      payload: {
        name: "groups",
        id: payload.id,
        emitter,
        apiName: "auth"
      }
    });
    yield put(rightsGroupFormFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération du groupe de droits", true);
  }
}

function* rightsGroupFormSave(action) {
  try {
    const {values} = action.payload;

    if (values["@id"]) {
      yield call(putItem, {
        payload: {
          name: "groups",
          item: values,
          emitter,
          apiName: "auth"
        }
      });
    } else {
      yield call(postItem, {
        payload: {
          name: "groups",
          item: values,
          emitter,
          apiName: "auth"
        }
      });
    }
    yield put(saveRequestSuccess(emitter));
  } catch (e) {
    yield call(handleError, 500, "Erreur durant la sauvegarde du groupe de droits", true);
  }
}

export function* watchRightsGroupForm() {
  yield takeLatest(actionTypes.RIGHTS_GROUP_FORM_FETCH, rightsGroupFormFetch);
  yield takeLatest(actionTypes.RIGHTS_GROUP_FORM_SAVE, rightsGroupFormSave);
}

export default watchRightsGroupForm;