import {call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import serviceAuth from '../../_services/_auth/service';
import {getCookie, setCookie} from '../../_helpers/cookie';
import {getItemById} from '../resources/sagas';
import {
  accountActivationCheckConfirmationTokenFailure,
  accountActivationCheckConfirmationTokenSuccess,
  accountActivationSaveFailure,
  accountActivationSaveSuccess,
  forgetPasswordAskResetFailure,
  forgetPasswordAskResetSuccess,
  resetPasswordCheckRecoverTokenFailure,
  resetPasswordCheckRecoverTokenSuccess,
  resetPasswordSaveFailure,
  resetPasswordSaveSuccess,
  signInFailure,
  signInSuccess,
  tryAutoSignInFailure,
  tryAutoSignInSuccess
} from './actions';
import {handleError} from '../../_helpers/api';
import {setAllToTrue} from '../../_helpers/utils';

function* signIn(action) {
  try {
    const {values} = action.payload;
    const apiAuth = serviceAuth();
    const data = yield call(apiAuth["loginCheck"].create, values);
    yield setCookie("token-v2", data.token, data.user.exp);
    yield call(tryAutoSignIn, data.user.exp);
  } catch (e) {
    yield put(signInFailure(e));
    yield call(handleError, 401, e.response.data?.message || "Une erreur est survenue");
  }
}

function handleUserRights(user) {
  let rights = {
    isSuperAdminOrCartel: false,
    baliz: {
      view: false,
      edit: false
    },
    data: {
      view: false,
      edit: false
    },
    analytics: {
      view: false
    },
    funbox: {
      view: false,
      edit: false
    },
    products: {
      view: false,
      edit: false
    },
    users: {
      add: false,
      view: false,
      edit: false,
      delete: false
    }
  };

  const roles = user["roles"];
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === "ROLE_CARTEL" || roles[i] === "ROLE_SUPER_ADMIN") {
      // Si l'utilisateur est un employé Cartelmatic ou un super administrateur
      setAllToTrue(rights);
      break;
    } else if (roles[i].startsWith("ROLE_MANAGER")) {
      // Si le droit concerne le Manager
      const splitted = roles[i].split("_");
      if (splitted.length > 3) {
        const action = splitted[splitted.length - 1].toLowerCase();
        if (action !== "all") {
          let parent = "";
          for (let i = 2; i < splitted.length - 1; i++) {
            if (i === 2) {
              parent = splitted[i].toLowerCase();
            } else {
              parent += splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1).toLowerCase();
            }
          }
          rights = {
            ...rights,
            [parent]: {
              ...rights[parent],
              [action]: true
            }
          }
        }
      }
    }
  }
  user.rights = rights;
}

function* tryAutoSignIn(expiration) {
  try {
    const token = getCookie("token-v2");
    const apiAuth = serviceAuth();
    if (token) {
      let user = yield call(apiAuth["accessToken"].get);
      if (user && user.organization) {
        // user = {
        //   ...user,
        //   organization: 2240
        // }
        handleUserRights(user);
        const {member: organization} = yield call(getItemById, {
          payload: {
            name: "organizations",
            id: user.organization,
            emitter: "AUTH"
          }
        });
        yield put(signInSuccess({
          user,
          expiration
        }));
        yield put(tryAutoSignInSuccess());
      } else {
        yield put(tryAutoSignInFailure());
      }
    } else {
      yield put(tryAutoSignInFailure());
    }
  } catch (e) {
    console.log(e);
    yield put(tryAutoSignInFailure());
  }
}

function* forgetPasswordAskReset(action) {
  try {
    const {payload} = action;
    const apiAuth = serviceAuth();
    yield call(apiAuth["askResetPassword"].update, payload);
    yield put(forgetPasswordAskResetSuccess());
  } catch (e) {
    yield put(forgetPasswordAskResetFailure());
    yield call(handleError, e.code, "Impossible de répondre à la demande de réinitialisation du mot de passe");
  }
}

function* resetPasswordCheckRecoverToken(action) {
  try {
    const apiAuth = serviceAuth();
    const {token, email} = action.payload;
    if (!token || !email) {
      yield put(resetPasswordCheckRecoverTokenFailure())
    }
    const data = yield call(apiAuth["accessTokenRecover"].get, token);
    yield put(resetPasswordCheckRecoverTokenSuccess(data));
  } catch (e) {
    yield put(resetPasswordCheckRecoverTokenFailure());
  }
}

function* resetPasswordSave(action) {
  try {
    const apiAuth = serviceAuth();
    const {token, values} = action.payload;
    const user = yield select(state => state.getIn(["auth", "user"]));
    const data = {
      id: user.get("id"),
      plainPassword: values.password
    };
    yield call(apiAuth["changePasswordWithRecoverToken"].update, token, data);
    yield put(resetPasswordSaveSuccess());
  } catch (e) {
    yield put(resetPasswordSaveFailure());
    yield call(handleError, 500, "Une erreur est survenue pendant l'enregistrement du mot de passe");
  }
}

function* accountActivationCheckConfirmationToken(action) {
  try {
    const apiAuth = serviceAuth();
    const {token, email} = action.payload;
    if (!token || !email) {
      yield put(accountActivationCheckConfirmationTokenFailure());
    }
    const data = yield call(apiAuth["accessTokenRecover"].get, token);
    yield put(accountActivationCheckConfirmationTokenSuccess(data));
  } catch (e) {
    yield put(resetPasswordCheckRecoverTokenFailure());
  }
}

function* accountActivationSave(action) {
  try {
    const apiAuth = serviceAuth();
    const {token, values, navigate} = action.payload;
    const user = yield select(state => state.getIn(["auth", "user"]));
    const data = {
      id: user.get("id"),
      plainPassword: values.password,
      activeOn: new Date()
    };
    yield call(apiAuth["changePasswordWithRecoverToken"].update, token, data);
    yield put(accountActivationSaveSuccess());
    navigate("/connexion");
  } catch (e) {
    yield put(accountActivationSaveFailure());
    yield call(handleError, 500, "Une erreur est survenue pendant l'enregistrement du mot de passe");
  }
}

export function* watchAuth() {
  yield takeLatest(actionTypes.SIGN_IN, signIn);
  yield takeLatest(actionTypes.TRY_AUTO_SIGN_IN, tryAutoSignIn);
  yield takeLatest(actionTypes.FORGET_PASSWORD_ASK_RESET, forgetPasswordAskReset);
  yield takeLatest(actionTypes.RESET_PASSWORD_CHECK_RECOVER_TOKEN, resetPasswordCheckRecoverToken);
  yield takeLatest(actionTypes.RESET_PASSWORD_SAVE, resetPasswordSave);
  yield takeLatest(actionTypes.ACCOUNT_ACTIVATION_CHECK_CONFIRMATION_TOKEN, accountActivationCheckConfirmationToken);
  yield takeLatest(actionTypes.ACCOUNT_ACTIVATION_SAVE, accountActivationSave);
}

export default watchAuth;