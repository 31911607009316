import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import actionTypes from './actionTypes';
import {documentManager, handleError} from '../../../_helpers/api';
import {deleteItem, getItemById, postItem, putItem} from '../../../_store/resources/sagas';
import {productFormFetchSuccess} from './actions';
import {saveRequestSuccess, saveRequestFailure} from '../../../_store/resources/actions';

const emitter = "PRODUCT_FORM";

function* productFormFetch(action) {
  try {
    const {payload} = action;
    yield call(getItemById, {
      payload: {
        name: "products",
        id: payload.id,
        emitter
      }
    });
    yield put(productFormFetchSuccess());
  } catch (e) {
    yield call(handleError, 500, "Erreur de récupération de l'équipement", true);
  }
}

function* productFormSave(action) {
  try {
    const {values} = action.payload;

    const currentProduct = yield select(state => state.getIn(["scenes", "productForm", "resources", "products", "member"]));

    let featuredImage = null;
    if (values.photo.length > 0) {
      featuredImage = yield call(documentManager, values.photo, emitter);
    }

    const handleConfiguration = configurationName => {
      let configurationReqArr = [];
      const configurationNameWithUnderscore = configurationName.split(/(?=[A-Z])/).map(word => word[0].toLowerCase() + word.slice(1)).join("_");

      if (!values[configurationName] && currentProduct.getIn(["configurations", configurationNameWithUnderscore, "value"])) {
        // On supprime la configuration
        configurationReqArr = [
          ...configurationReqArr,
          call(deleteItem, {
            payload: {
              name: "configurations",
              uri: currentProduct.getIn(["configurations", configurationNameWithUnderscore, "@id"]),
              emitter
            }
          })
        ];
      } else if (values[configurationName] && !currentProduct.getIn(["configurations", configurationNameWithUnderscore, "value"])) {
        // On crée la configuration
        configurationReqArr = [
          ...configurationReqArr,
          call(postItem, {
            payload: {
              name: "configurations",
              item: {
                name: configurationNameWithUnderscore,
                value: values[configurationName],
                product: currentProduct.get("@id")
              },
              emitter
            }
          })
        ];
      } else if (values[configurationName] && (values[configurationName] !== currentProduct.getIn(["configurations", configurationNameWithUnderscore, "value"]))) {
        // On édite la configuration
        configurationReqArr = [
          ...configurationReqArr,
          call(putItem, {
            payload: {
              name: "configurations",
              item: {
                "@id": currentProduct.getIn(["configurations", configurationNameWithUnderscore, "@id"]),
                value: values[configurationName]
              },
              emitter
            }
          })
        ];
      }

      return configurationReqArr;
    }

    // const reqStartTime = handleConfiguration("startTime");
    // const reqEndTime = handleConfiguration("endTime");

    yield all([
      // ...reqStartTime,
      // ...reqEndTime,
      call(putItem, {
        payload: {
          name: "products",
          item: {
            "@id": currentProduct.get("@id"),
            reference: currentProduct.get("reference"),
            name: values.name,
            featuredImage
          },
          emitter
        }
      })
    ]);

    yield put(saveRequestSuccess(emitter));
  } catch (e) {
    yield put(saveRequestFailure(emitter));
    yield call(handleError, 500, "Erreur durant la sauvegarde de l'équipement", true);
  }
}

export function* watchProductForm() {
  yield takeLatest(actionTypes.PRODUCT_FORM_FETCH, productFormFetch);
  yield takeLatest(actionTypes.PRODUCT_FORM_SAVE, productFormSave);
}

export default watchProductForm;