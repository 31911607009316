import {fromJS} from 'immutable';

import actionTypes from './actionTypes';

const initialState = fromJS({
  pageLoaded: false,
  tableConnexionLoading: true,
  tableLoading: {
    services: false,
    tickets: false,
    logs: false
  },
  filters: {
    services: {
      page: 1,
      itemsPerPage: 20
    },
    tickets: {
      page: 1,
      itemsPerPage: 20
    },
    logs: {
      page: 1,
      itemsPerPage: 20
    }
  }
});

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actionTypes.PRODUCT_VIEW_FETCH_SUCCESS:
      return state.set("pageLoaded", true);
    case actionTypes.PRODUCT_VIEW_RESET:
      return initialState;
    case actionTypes.PRODUCT_VIEW_FETCH_CONNECTION:
      return state.set("tableConnexionLoading", true);
    case actionTypes.PRODUCT_VIEW_FETCH_CONNECTION_SUCCESS:
      return state.set("tableConnexionLoading", false);
    case actionTypes.PRODUCT_VIEW_UPDATE_SERVICES:
      return state.setIn(["tableLoading", "services"], true);
    case actionTypes.PRODUCT_VIEW_UPDATE_SERVICES_SUCCESS:
      return state.setIn(["tableLoading", "services"], false);
    case actionTypes.PRODUCT_VIEW_UPDATE_TICKETS:
      return state.setIn(["tableLoading", "tickets"], true);
    case actionTypes.PRODUCT_VIEW_UPDATE_TICKETS_SUCCESS:
      return state.setIn(["tableLoading", "tickets"], false);
    case actionTypes.PRODUCT_VIEW_UPDATE_LOGS:
      return state.setIn(["tableLoading", "logs"], true);
    case actionTypes.PRODUCT_VIEW_UPDATE_LOGS_SUCCESS:
      return state.setIn(["tableLoading", "logs"], false);
    case actionTypes.PRODUCT_VIEW_SET_FILTERS:
      return state.setIn(["filters", payload.name], fromJS(payload.filters));
    case actionTypes.PRODUCT_VIEW_STORE_REQUEST:
      return state.setIn(["resources", payload.customName || payload.name], fromJS(payload.data));
    default:
      return state;
  }
};

export default reducer;