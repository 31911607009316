import {object, string} from 'yup';

const validationSchema = () => {
  return (
    object().shape({
      username: string().required(),
      password: string().required()
    })
  )
};

export default validationSchema;
