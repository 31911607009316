import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FastField, Form, Formik} from 'formik';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

import TableContainer from '../../../_components/TableContainer/TableContainer';
import {
  productViewResetLogs, productViewSetFilters,
  productViewUpdateLogs
} from '../_store/actions';
import {selectProductViewLogs} from '../_store/selectors';
import DateType from '../../../_components/_form/DateType/DateType';
import {getFormattedDate} from '../../../_helpers/date';
import Status from '../../../_components/Status/Status';

const currentDate = new Date();
const initialStart = new Date(currentDate);
const initialEnd = new Date(currentDate);
initialStart.setDate(initialStart.getDate() - 6);
initialStart.setHours(0, 0, 0, 0);
initialEnd.setHours(23, 59, 59, 59);

const labels = {
  "restart_thezia": "Redémarrage de l'application",
  "ping": "Test de connexion",
  "api_funbox_sync_file": "Synchronisation des fichiers Funbox",
  "api_funbox_dump_database": "Copie de la base de données Funbox",
  "funbox_api_publish": "Publication de l'application Funbox",
  "api_funbox_push_dump_database": "Importation de la base de données Funbox",
  "product_info": "Récupération des informations de l'équipement",
  "push_img_funtour": "Synchronisation des images de funtour",
  "product_funtour_info": "Récupération des informations de funtour",
  "update_funtour": "Mise à jour de funtour",
  "api_tourism_dump_database": "Copie de la base de données touristique",
  "api_tourism_push_dump_database": "Importation de la base de données touristique",
  "analytics_sync": "Synchronisation des statistiques",
  "get_data_analytics_from_product": "Récupération des statistiques du produit",
  "api_data_sync_file": "Synchronisation des fichiers de la base de données cliente",
  "api_data_dump_database": "Copie de la base de données cliente",
  "api_data_push_dump_database": "Importation de la base de données cliente",
  "data_publish": "Publication du gestionnaire de données",
  "baliz_api_push_dump_database": "Importation de la base de données Baliz",
  "baliz_api_dump_database": "Copie de la base de données Baliz",
  "baliz_api_sync_file": "Synchronisation des fichiers Baliz",
  "baliz_publish": "Publication de Baliz"
}

function Logs() {
  const dispatch = useDispatch();
  const [selectedStartDate, setSelectedStartDate] = useState(initialStart);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEnd);
  const {logs, taskQueues, lastTasks, filters, tableLoading} = useSelector(selectProductViewLogs);
  const [initialValues, setInitialValues] = useState({
    start: initialStart,
    end: initialEnd
  });
  const firstRender = useRef(true);

  useEffect(() => {
    return () => dispatch(productViewResetLogs());
  }, []);

  const columns = useMemo(() => {

    const getStatus = value => {
      switch (value) {
        case "success":
          return <Status bgColor="#2e7d32" color="#ffffff" label="Succès"/>;
        case "failed":
          return <Status bgColor="#e93b3b" color="#ffffff" label="Échec"/>;
        default:
          return value;
      }
    }
    return [
      {
        Header: "Activité",
        accessor: "label",
        Cell: cell => labels[cell.value.trim()] || cell.value.trim()
      },
      {
        Header: "Date",
        accessor: "createdAt",
        fixWidth: 300,
        sortable: true,
        sortableName: "createdAt",
        Cell: cell => getFormattedDate(cell.value, false)
      },
      {
        Header: "Statut",
        accessor: "state",
        fixWidth: 200,
        Cell: cell => getStatus(cell.value)
      }
    ];
  }, []);

  const fetchData = (filters, start, end) => {
    dispatch(productViewUpdateLogs(filters, start, end));
  }

  const handleSubmit = values => {
    setInitialValues(values);
    dispatch(productViewSetFilters("logs", {...filters.toJS(), start: values.start, end: values.end}));
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({values, dirty}) => {
        return (
          <>
            <Form>
              <Grid container spacing={3} sx={{mb: 2}}>
                <Grid item xs={5}>
                  <FastField
                    name="start"
                    component={DateType}
                    label="Début"
                    resetButton={false}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FastField
                    name="end"
                    component={DateType}
                    label="Fin"
                    resetButton={false}
                  />
                </Grid>
                <Grid item xs={2}>
                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!dirty}
                  >
                    <div style={{marginTop: "1px", marginBottom: "-1px"}}>Appliquer</div>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
            <TableContainer
              id="table-container-product-log-list"
              columns={columns}
              data={logs ? logs.get("members").toJS() : []}
              count={logs ? logs.get("count") : 0}
              pageCount={logs ? logs.get("pageCount") : 1}
              customPageSize={20}
              fetchData={filters => fetchData(filters, values.start, values.end)}
              filters={filters.toJS()}
              loading={tableLoading}
              defaultSort={{sortDirection: "desc", accessor: "createdAt"}}
            />
          </>
        )
      }}
    </Formik>
  );
}

export default Logs;