import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';

import {selectProfile} from '../_store/auth/selectors';

const ProtectedRoute = (props) => {
    const {isAuthenticated} = useSelector(selectProfile);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to={{pathname: "/connexion", state: {from: location}}}/>;
    }

    return <>{props.children}</>;
};

export default ProtectedRoute;