import React, {useState, Fragment, useEffect} from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import TableContainerMui from '@mui/material/TableContainer';
import {isEqual} from 'lodash';
import {isEmptyArray} from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';

import usePrevious from '../../_hooks/usePrevious';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import Loading from '../Loading/Loading';

const TableContainer = (
  {
    id = null,
    columns,
    data,
    pageCount: controlledPageCount,
    customPageSize,
    fetchData,
    filters,
    count,
    loading,
    isPagination = true,
    defaultSort = {sortDirection: "desc", accessor: "id"}
  }
) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize}
  } = useTable(
    {
      columns,
      data,
      loading,
      pageCount: controlledPageCount,
      manualPagination: true,
      manualSortBy: true,
      initialState: {
        pageIndex: 0, pageSize: customPageSize, sortBy: [
          {
            id: defaultSort.accessor,
            desc: defaultSort.sortDirection === "desc"
          }
        ]
      },
      autoResetPage: false
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const [sort, setSort] = useState({...defaultSort, step: 1});
  useEffect(() => {
    fetchData({
      ...filters,
      page: pageIndex + 1,
      itemsPerPage: pageSize,
      [`order[${sort.accessor}]`]: sort.sortDirection
    })
  }, [pageIndex, pageSize]);

  const prevFilters = usePrevious(filters);
  const prevSort = usePrevious(sort);
  useEffect(() => {
    if(filters){
      const tmpFilters = Object.keys(filters).reduce((o,c) => {
        if (c !== "values") {
          o[c] = filters[c]
        }
        return o;
      }, {});
      if (prevFilters && !isEqual(prevFilters, filters)) {
        fetchData(tmpFilters);
        if (tmpFilters.page === 1) {
          gotoPage(0);
        }
      } else if(prevSort && !isEqual(prevSort, sort)){
        //suppression de tous les tri précedent
        const newObj = Object.keys(tmpFilters).reduce((o,c) => {
          if (!c.includes('order')) {
            o[c] = tmpFilters[c]
          }
          return o;
        }, {});
        fetchData({[`order[${sort.accessor}]`]: sort.sortDirection, ...newObj});
        if (tmpFilters.page === 1) {
          gotoPage(0);
        }
      }
    }
  }, [filters, sort]);

  return (
    <TableContainerMui>
      <Table {...(id && {id})} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <TableCell
                    {...(column.id === "selection"
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps())
                    )}
                    title=""
                    sx={{p: 1, cursor: column.sortable ? "pointer !important" : "auto !important"}}
                  >
                    <span className="column-header">{column.render("Header")}</span>
                    {column.sortable ? (
                      <TableSortLabel
                        active={column.isSorted}
                        onClick={() => {
                          setSort(sort => {
                            if (sort.step === 2) {
                              return {...defaultSort, step: 0};
                            } else {
                              return {
                                sortDirection: sort.step === 0 ? "asc" : "desc",
                                accessor: column.sortableName,
                                step: sort.step + 1
                              }
                            }
                          });
                        }}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      />
                    ) : null}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody style={{
          ...(isEmptyArray(data) ? {
            height: 53
          } : null),
          position: "relative"
        }} {...getTableBodyProps()}>
          {!loading && isEmptyArray(data) && (
            <TableRow>
              <TableCell colSpan={headerGroups[0].headers.length} sx={{p: 1}}>Aucune donnée trouvée</TableCell>
            </TableRow>
          )}
          <Loading isLoading={loading} backgroundColor="rgba(250, 250, 250, 0.6)" color="#673ab7" isTable/>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <TableRow sx={index % 2 ? {} : {backgroundColor: "#f3f0f9"}}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell key={cell.index} {...cell.getCellProps()} style={{
                        ...(cell.column.fixWidth && {
                          width: cell.column.fixWidth,
                          maxWidth: cell.column.fixWidth,
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }),
                        ...cell.column.style,
                        padding: 8,
                        position: "relative"
                      }}>
                        {/*{row.original.roles && row.original.roles.includes("ROLE_ADMIN") && (*/}
                        {/*  <div style={{position: "absolute", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "#fff", zIndex: 1, opacity: 0.3}}/>*/}
                        {/*)}*/}
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      {isPagination && (
        <TablePagination
          rowsPerPageOptions={[
            20,
            30,
            50,
            100,
            // {label: "Toutes", value: count}
          ]}
          component="div"
          count={count}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: {"aria-label": "rows per page"},
            native: true,
          }}
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({from, to, count}) => {
            return `${from}–${to} sur ${count !== -1 ? count : `plus que ${to}`}`;
          }}
          onPageChange={(e, page) => gotoPage(page)}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </TableContainerMui>
  );
};

export default TableContainer;
