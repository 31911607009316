import {createSelector} from 'reselect';

export const selectProductListPageLoaded = state => state.getIn(["scenes", "productList", "pageLoaded"]);
export const selectProductListProductsLoaded = state => state.getIn(["scenes", "productList", "productsLoaded"]);
export const selectProductListProducts = state => state.getIn(["scenes", "productList", "resources", "products"]);

export const selectProductList = createSelector([
  selectProductListPageLoaded,
  selectProductListProductsLoaded,
  selectProductListProducts
], (pageLoaded, productsLoaded, products) => ({
  pageLoaded,
  productsLoaded,
  products
}));