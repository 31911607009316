import actionTypes from './actionTypes';

export const serviceListFetch = () => ({
  type: actionTypes.SERVICE_LIST_FETCH
});

export const serviceListFetchSuccess = () => ({
  type: actionTypes.SERVICE_LIST_FETCH_SUCCESS
});

export const serviceListReset = () => ({
  type: actionTypes.SERVICE_LIST_RESET
});

export const serviceListUpdate = params => ({
  type: actionTypes.SERVICE_LIST_UPDATE,
  payload: {
    params
  }
});

export const serviceListUpdateSuccess = payload => ({
  type: actionTypes.SERVICE_LIST_UPDATE_SUCCESS,
  payload
});