import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {FastField, Field, Form, Formik} from 'formik';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

import {productViewFetchConnection} from '../_store/actions';
import {selectProductView} from '../_store/selectors';
import DateType from '../../../_components/_form/DateType/DateType';
import Loading from '../../../_components/Loading/Loading';

const currentDate = new Date();
const initialStart = new Date(currentDate);
const initialEnd = new Date(currentDate);
initialStart.setDate(initialStart.getDate() - 7);
initialStart.setHours(0, 0, 0, 0);
initialEnd.setHours(23, 59, 59, 59);

function ConnectionTable() {
  const dispatch = useDispatch();
  const {id} = useParams();
  const [selectedStartDate, setSelectedStartDate] = useState(initialStart);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEnd);
  const {rangeConnexions, tableConnexionLoading} = useSelector(selectProductView);

  useEffect(() => {
    dispatch(productViewFetchConnection(id, initialStart, initialEnd));
  }, [id]);

  // Fonction pour obtenir la clé de date au format "DD/MM/YYYY"
  const currentDateKey = date => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Fonction pour obtenir la clé de temps au format "HH:mm"
  const currentTimeKey = date => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  // Fonction pour remplir les plages horaires non renseignées avec "na" pour chaque tranche de 30 minutes
  const initializeData = () => {
    const dateList = [];
    const currentDate = new Date(selectedStartDate);
    let data = {};
    while (currentDate <= selectedEndDate) {
      const dateKey = currentDateKey(currentDate);
      dateList.push(dateKey);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    dateList.forEach((dateKey) => {
      const hoursOfDay = Array.from({length: 24}, (_, i) => i.toString().padStart(2, '0'));
      data = {...data, [dateKey]: {}};
      for (const hour of hoursOfDay) {
        for (let minute = 0; minute < 60; minute += 30) {
          const timeKey = `${hour}:${minute.toString().padStart(2, '0')}`;
          data = {
            ...data,
            [dateKey]: {
              ...data[dateKey],
              [timeKey]: "na"
            }
          };
        }
      }
    })
    return data;
  };

  const transformedData = useMemo(() => {
    let transformedData = initializeData();
    // Parcourir les ranges de connexion
    rangeConnexions && rangeConnexions.get("members").forEach(rangeConnexion => {
      const startAt = new Date(rangeConnexion.get("startAt"));
      const endAt = new Date(rangeConnexion.get("endAt"));

      // Parcourir chaque tranche de 30 minutes dans la range
      let currentTime = new Date(startAt);
      currentTime.setSeconds(0);
      currentTime.setMilliseconds(0);
      if (currentTime.getMinutes() >= 30) {
        currentTime.setMinutes(30);
      } else {
        currentTime.setMinutes(0);
      }
      while (currentTime < endAt) {
        if (currentTime >= selectedStartDate) {
          if (currentTime > selectedEndDate) {
            break;
          }
          const dateKey = currentDateKey(currentTime);
          const timeKey = currentTimeKey(currentTime);
          const currentCase = transformedData[dateKey][timeKey];
          transformedData = {
            ...transformedData,
            [dateKey]: {
              ...transformedData[dateKey],
              [timeKey]: currentCase !== "na" ? "unstable" : rangeConnexion.get("isConnected") ? "connected" : "not-connected"
            }
          }
        }
        // Passer à la prochaine tranche de 30 minutes
        currentTime.setMinutes(currentTime.getMinutes() + 30);
      }
    });
    return transformedData;
  }, [rangeConnexions]);

  // Utilisation de la fonction avec vos données
  const getStatusColor = (status) => {
    switch (status) {
      case "unstable-not-connected":
        return "rgb(238, 206, 127)";
      case "unstable":
        return "rgb(134 220 113)";
      case "connected":
        return "#13c56b";
      case "not-connected":
        return "rgb(237, 94, 94)";
      case "na":
        return "rgb(200, 236, 247)";
      default:
        return "white";
    }
  };

  const renderTableRows = () => {
    const rows = [];

    for (const dateKey in transformedData) {
      const dateData = transformedData[dateKey];
      const row = [];

      for (const timeKey in dateData) {
        const status = dateData[timeKey];
        row.push(
          <div className={"row-table-connexion"} key={`${dateKey}-${timeKey}`} style={{
            backgroundColor: getStatusColor(status),
            width: 23,
            border: "1px solid #eee",
            marginRight: -1,
            marginBottom: -1,
            marginLeft: timeKey === "00:00" ? 10 : 0
          }}/>
        );
      }

      rows.push(
        <div style={{
          display: "flex"
        }} key={dateKey}>
          <div style={{
            width: 100,
            padding: 3,
            textAlign: "center",
            color: "rgb(135, 138, 153)",
            fontSize: "0.82rem"
          }}>{dateKey}</div>
          {row}
        </div>
      );
    }

    return rows;
  };

  return (
    <div>
      <Formik
        initialValues={{
          start: initialStart,
          end: initialEnd
        }}
        onSubmit={values => {
          const newStart = new Date(values.start);
          const newEnd = new Date(values.end);
          setSelectedStartDate(newStart);
          setSelectedEndDate(newEnd);
          dispatch(productViewFetchConnection(id, newStart, newEnd));
        }}
      >
        {({values, setFieldValue, dirty, submitCount}) => {
          return (
            <Form>
              <Grid container spacing={3} sx={{mb: 2}}>
                <Grid item xs={5}>
                  <FastField
                    name="start"
                    component={DateType}
                    label="Début"
                    resetButton={false}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="end"
                    component={DateType}
                    label="Fin"
                    resetButton={false}
                    minDate={values.start}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={2}>
                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={false}
                  >
                    <div style={{marginTop: "1px", marginBottom: "-1px"}}>Appliquer</div>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
      <div style={{overflowX: "auto", overflowY: "hidden", position: "relative", minHeight: 200}}>
        <Loading isLoading={tableConnexionLoading} color="#673ab7"/>
        <div className="table-connexion" style={{
          position: "relative",
          width: 1200
        }}>
          <div>
            <div style={{
              display: "flex",
              flexWrap: "wrap"
            }}>
              <div style={{
                width: 100,
                padding: 5
              }}/>
              {Array.from({length: 24}, (_, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      transform: "rotate(-45deg)",
                      width: 44,
                      padding: "13px 2px",
                      color: "rgb(135, 138, 153)",
                      fontSize: "0.82rem"
                    }}
                  >
                    {(i).toFixed(2).replace(".", ":")}
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            {renderTableRows()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectionTable;
