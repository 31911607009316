import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {FastField, Form, Formik} from 'formik';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import validationSchema from './validationSchema';
import TextType from '../../_components/_form/TextType/TextType';
import {signIn} from '../../_store/auth/actions';
import {selectSignIn} from '../../_store/auth/selectors';
import ConnexionLoading from '../../_components/ConnexionLoading/ConnexionLoading';
import usePrevious from '../../_hooks/usePrevious';

export function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.cartelmatic.com/">
        Cartelmatic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  // noinspection JSUnresolvedFunction
  const [isLoading, setIsLoading] = useState(false);
  const {isSubmitting, isAuthenticated} = useSelector(selectSignIn);

  const prevIsSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (isSubmitting) {
      setIsLoading(true);
    } else if (prevIsSubmitting && !isSubmitting && !isAuthenticated) {
      setIsLoading(false);
    }
  }, [isSubmitting, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      let {from} = (location.state && location.state.from && location.state.from.pathname !== "/connexion" && location.state.from.pathname !== "/deconnexion") ? location.state : {from: {pathname: "/equipements"}};
      navigate(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleSubmit = data => {
    dispatch(signIn(data));
  };

  return (
    <>
      {!isLoading ? (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                S'identifier
              </Typography>
              <Formik initialValues={{
                username: "",
                password: ""
              }} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({errors}) => {
                  return (
                    <Form id={"sign-in"} style={{width: "100%"}}>
                      <FastField
                        name="username"
                        fullWidth
                        required
                        margin="normal"
                        autoFocus
                        autoComplete="username"
                        label="Nom d'utilisateur"
                        size="normal"
                        component={TextType}
                      />
                      <FastField
                        name="password"
                        fullWidth
                        required
                        margin="normal"
                        size="normal"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        label="Mot de passe"
                        component={TextType}
                      />
                      <Button
                        type="submit"
                        form={"sign-in"}
                        fullWidth
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{mt: 3, mb: 2}}
                      >
                        Se connecter
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
              <Copyright sx={{mt: 8, mb: 4}}/>
            </Box>
          </Container>
        </ThemeProvider>
      ) : <ConnexionLoading/>}
    </>
  );
}