import {call, select, all} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {put} from 'redux-saga/effects';

import {getCookie} from "./cookie";
import {arrayDiff, isJson} from './utils';
import {signOut} from '../_store/auth/actions';
import {error} from '../_store/app/actions';
import {deleteItem, postItem} from '../_store/resources/sagas';

export const bearer = (config = {}, params = {}) => {
  const token = getCookie("token-v2");
  config.headers = {
    "Authorization": "Bearer " + token
  };
  config.params = params;
  config.paramsSerializer = params => {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      const param = params[key];
      if (Array.isArray(param)) {
        for (const p of param) {
          if (p) {
            searchParams.append(key, p);
          }
        }
      } else if (param !== null && params !== "") {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  };
  return config;
};

export const normalizeCollection = (response, params = {}) => {
  const count = parseInt(response.data["hydra:totalItems"], 10);

  return {
    members: response.data["hydra:member"],
    count,
    indexByUri: response.data["hydra:member"].reduce((i, element, key) => {
      i[element["@id"]] = key;
      return i;
    }, {}),
    pageCount: params.itemsPerPage && count > 0 ? Math.ceil(count / params.itemsPerPage) : 0,
    request: response.request.responseURL
  }
};

export function* handleError(code, message, useToast = false) {
  switch (code) {
    case 401:
      if (message === "Invalid credentials.") {
        toast.error("Les identifiants sont incorrects", {style: {background: "#ed5e5e"}});
      } else if (message === "Your account has been deactivated by the administrators.") {
        toast.error("Votre compte a été désactivé par un administrateur.", {style: {background: "#ed5e5e"}});
      }
      break;
    default:
      if (useToast) {
        toast.error(message, {style: {background: "#ed5e5e"}});
      } else {
        yield put(error(code, message));
      }
  }
}

export function handleSuccess(message) {
  toast.success(message, {style: {background: "#13c56b"}});
}

export const getFirstFromNormalized = (entitiesNormalized, name) => {
  if (entitiesNormalized.result[name] && entitiesNormalized.result[name].length > 0) {
    return entitiesNormalized.entities[name][entitiesNormalized.result[name][0]]
  } else {
    return null;
  }
};
export const getValue = (el, nullable = false, type = "string", defaultValue = null) => {
  if (nullable && type !== "int" && (el === null || el === undefined || el === "")) {
    return null;
  } else if (!nullable && el === undefined) {
    return "";
  } else if (el && nullable && typeof el === "object" && Object.prototype.hasOwnProperty.call(el, "value") && el.value === "") {
    return null;
  } else if (el && nullable && typeof el === "object" && Object.prototype.hasOwnProperty.call(el, "phoneNumber") && (el.phoneNumber === "" || el.phoneNumber === null)) {
    return null;
  } else if (typeof el === "boolean") {
    return el;
  } else if (type === "float") {
    return el ? parseFloat(el) : 0;
  } else if (type === "int") {
    if (Object.prototype.hasOwnProperty.call(el, "value")) {
      return parseInt(el.value, 10);
    } else {
      return el ? parseInt(el, 10) : defaultValue ? defaultValue : nullable ? null : 0;
    }
  } else if (type === "date") {
    return new Date(el);
  } else if (!nullable && !el) {
    return "";
  } else if (el.phoneNumber === "" || (el.phoneNumber && el.phoneNumber.length <= 3)) {
    return "";
  } else if (el.phoneNumber) {
    const phone = el.phoneNumber.replace(/[()-+/\s]/g, "");
    return `+${phone}`;
  } else if (!nullable && (el.value || el.value === "")) {
    return el.value
  } else if (el.value || el.value === "") {
    return el.value
  } else if (el["@id"]) {
    return el["@id"];
  } else if (Array.isArray(el)) {
    if (el[0]?.value) {
      return el.map(i => i.value)
    } else {
      return el.toString();
    }
  } else if (isJson(el)) {
    return JSON.stringify(el);
  } else {
    return el.toString();
  }
};

export function* documentManager(document, emitter, multiple) {
  if (multiple) {
    const documents = yield all(document.filter(item => !item["@id"]).map(item => {
      const form = new FormData();
      form.append("file", item);
      // noinspection JSCheckFunctionSignatures
      return call(postItem, {
        payload: {
          name: "documents",
          item: form,
          emitter
        }
      });
    }));
    return document.filter(item => item["@id"]).map(item => item["@id"]).concat(documents.map(document => document['@id']));
  } else {
    console.log("documentManager", document);
    let data;
    if (document && document[0] && document[0]['@id']) {
      data = document[0]['@id'];
    } else if (document && document[0] && !document[0]['@id']) {
      console.log("post document");
      const form = new FormData();
      form.append("file", document[0]);
      // noinspection JSCheckFunctionSignatures
      const result = yield call(postItem, {
        payload: {
          name: "documents",
          item: form,
          emitter
        }
      });
      data = result['@id'];
    } else {
      data = null;
    }
    return data;
  }
}

export function* replaceDocument(oldDocument, newDocument, emitter, multiple) {
  try {
    if (multiple) {
      const toDelete = arrayDiff(oldDocument.toJS(), newDocument);
      yield all(toDelete.map(uri => call(deleteItem, {
        payload: {
          name: "documents",
          uri,
          emitter
        }
      })));
    } else {
      if (oldDocument !== newDocument && oldDocument) {
        yield call(deleteItem, {
          payload: {
            name: "documents",
            uri: oldDocument,
            emitter
          }
        });
      }
    }

  } catch (e) {
    const error = new Error("Un problème s'est produit lors de la suppression de l'image");
    error.code = e.code;
    throw error;
  }
}

export function* deleteDocument(documentUri, emitter) {
  if (documentUri) {
    try {
      yield call(deleteItem, {
        payload: {
          name: "documents",
          uri: documentUri,
          emitter
        }
      });
    } catch (e) {
      const error = new Error("Un problème est survenue pendant la suppression du fichier");
      error.code = e.code;
      throw error;
    }
  }
}

export function* getTranslations(values, fields = []) {
  try {
    const languages = yield select(state => state.getIn(["app", "resources", "languages"]));
    let translations = {};
    languages.get('members').forEach(language => {
      translations[language.get('locale')] = {
        locale: language.get('locale')
      };
      fields.forEach(field => {
        translations[language.get('locale')][field] = values[`${field}-${language.get('locale')}`]
      })
    });

    return translations;

  } catch (e) {
    const error = new Error("Un problème s'est produit lors de la gestion des traductions");
    error.code = e.code;
    throw error;
  }
}

export const getAttributes = (categories, values) => {
  return categories.get('members').reduce((array, category) => {
    return values[category.get("@id")] ? array.concat(values[category.get("@id")].reduce((ids, obj) => {
      return ids.concat(obj);
    }, [])) : array;
  }, []);
};
export const getFurtherInformationValues = (payload, itemValues) => {
  switch (payload.type) {
    case "Hotel":
    case "hotel":
      itemValues = {
        ...itemValues,
        nbRoom: getValue(payload.values.nbRoom, true),
        nbRoomRM: getValue(payload.values.nbRoomRM, true),
        open24: getValue(payload.values.open24, false, "boolean"),
        animal: getValue(payload.values.animal),
        upAtNight: getValue(payload.values.upAtNight)
      }
      break;
    case "CulturalHeritage":
    case "patrimoine-culturel":
      itemValues = {
        ...itemValues,
        guidedTour: getValue(payload.values.guidedTour, true),
        teachingTour: getValue(payload.values.teachingTour, true),
        duration: getValue(payload.values.duration, true),
        audioTour: getValue(payload.values.audioTour, true)
      }
      break;
    case "NaturalHeritage":
    case "patrimoine-naturel":
      itemValues = {
        ...itemValues,
        guidedTour: getValue(payload.values.guidedTour, true),
        teachingTour: getValue(payload.values.teachingTour, true),
        duration: getValue(payload.values.duration, true),
        audioTour: getValue(payload.values.audioTour, true),
        trail: getValue(payload.values.trail, true)
      }
      break;
    case "EventCalendar":
    case "fete-et-manifestation":
      itemValues = {
        ...itemValues,
        place: getValue(payload.values.place, true),
        duration: getValue(payload.values.duration, true)
      }
      break;
    case "Itinerary":
    case "itineraire":
      itemValues = {
        ...itemValues,
        startPoint: getValue(payload.values.startPoint, true),
        endPoint: getValue(payload.values.endPoint, true),
        distance: getValue(payload.values.distance, true),
        duration: getValue(payload.values.duration, true),
        level: getValue(payload.values.level, true),
        elevation: getValue(payload.values.elevation, true)
      }
      break;
    case "Leisure":
    case "loisir":
      itemValues = {
        ...itemValues,
        duration: getValue(payload.values.duration, true)
      }
      break;
    case "OutdoorHotel":
    case "hebergement-plein-air":
      itemValues = {
        ...itemValues,
        light: getValue(payload.values.light, true),
        authorizedCaravan: getValue(payload.values.authorizedCaravan, true),
        authorizedNaturism: getValue(payload.values.authorizedNaturism, true),
        nbMobileHome: getValue(payload.values.nbMobileHome, true),
        nbLodge: getValue(payload.values.nbLodge, true),
        nbSite: getValue(payload.values.nbSite, true),
      }
      break;
    case "RentalAccommodation":
    case "hebergement-locatif":
      itemValues = {
        ...itemValues,
        capacity: getValue(payload.values.capacity, true),
        open24: !payload.values.open24 ? null : payload.values.open24.toString()
      }
      break;
    case "Restaurant":
    case "restaurant":
      itemValues = {
        ...itemValues,
        capacity: getValue(payload.values.capacity, true),
        chief: getValue(payload.values.chief, true)
      }
      break;
    case "SportiveAndCulturalActivity":
    case "activite-sportive-et-culturelle":
      itemValues = {
        ...itemValues,
        practicalPlace: getValue(payload.values.practicalPlace, true),
        duration: getValue(payload.values.duration, true)
      }
      break;
    case "Tasting":
    case "degustation":
      itemValues = {
        ...itemValues,
        aoc: getValue(payload.values.aoc, true),
        audioTour: getValue(payload.values.audioTour, false),
        guidedTour: getValue(payload.values.guidedTour, true),
        capacity: getValue(payload.values.capacity, true),
        duration: getValue(payload.values.duration, true)
      }
      break;
    default:
      break;
  }
  return itemValues;
};
export const getUrlByType = (type) => {
  switch (type) {
    case "hotel":
      return "hotels";
    case "restaurant":
      return "restaurants";
    case "hebergement-locatif":
      return "rentalAccommodations";
    case "fete-et-manifestation":
      return "eventCalendars";
    case "loisir":
      return "leisures";
    case "patrimoine-naturel":
      return "naturalHeritages";
    case "patrimoine-culturel":
      return "culturalHeritages";
    case "commerce":
      return "shops";
    case "degustation":
      return "tastings";
    case "hebergement-plein-air":
      return "outdoorHotels";
    case "activite-sportive-et-culturelle":
      return "sportiveAndCulturalActivities";
    case "itineraire":
      return "itineraries";
    case "pointeur":
      return "wayPoints";
    default:
      return null;
  }
};
