import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {FastField, Formik} from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import {
  userListDeleteUser,
  userListReset,
  userListSetFilters,
  userListUpdate
} from './_store/actions';
import {selectUserList} from './_store/selectors';
import TextType from '../../_components/_form/TextType/TextType';
import AutoSave from '../../_components/_form/AutoSave/AutoSave';
import TableContainer from '../../_components/TableContainer/TableContainer';
import useDialog from '../../_hooks/useDialog';
import DeleteDialog from '../../_components/DeleteDialog/DeleteDialog';
import Status from '../../_components/Status/Status';

function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    pageLoaded,
    tableLoading,
    users,
    filters,
    user
  } = useSelector(selectUserList);
  const deleteDialog = useDialog();

  useEffect(() => {
    return () => dispatch(userListReset());
  }, []);

  const handleSubmit = values => {
    const filters = {
      fullName: values.search || null
    };
    dispatch(userListSetFilters(filters));
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const handleDeleteItem = item => {
    setSelectedItem(item);
    deleteDialog.onOpen();
  };

  const handleConfirmDelete = () => {
    deleteDialog.onClose();
    dispatch(userListDeleteUser(selectedItem["@id"]));
    setSelectedItem(null);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Dénomination",
        accessor: "fullName",
        filterable: true,
        sortable: true,
        sortableName: "fullName",
        // fixWidth: 450,
        Cell: cell => {
          if (!user.getIn(["rights", "users", "edit"]) || ((cell.row.original.roles.includes("ROLE_SUPER_ADMIN") || cell.row.original.roles.includes("ROLE_CARTEL")) && !user.getIn(["rights", "isSuperAdminOrCartel"]))) {
            return (
              <span
                style={{
                  marginTop: "4px",
                  marginLeft: "4px"
                }}
              >
                {cell.value}
              </span>
            )
          } else {
            return (
              <Link
                component={RouterLink}
                to={`/comptes/utilisateurs/edition/${cell.row.original.id}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <span
                  style={{
                    marginTop: "4px",
                    marginLeft: "4px"
                  }}
                >
                  {cell.value}
                </span>
              </Link>
            )
          }
        }
      },
      {
        Header: "Identifiant",
        accessor: "username",
        Cell: cell => cell.value
      },
      {
        Header: "Groupes",
        accessor: "groups",
        filterable: false,
        sortable: false,
        Cell: cell => {
          const isSuperAdmin = cell.row.original.roles.includes("ROLE_SUPER_ADMIN");
          const groups = cell.value.map((group, index) => <Status key={index} bgColor={group.color} label={group.name} style={{
            width: "fit-content",
            marginLeft: index === 0 && !isSuperAdmin ? 0 : "5px"
          }}/>);
          return (
            <div style={{display: "flex"}}>
              {isSuperAdmin ? <Status bgColor="transparent" color="#ff1744" label="Super administrateur" style={{width: "fit-content", fontWeight: "bold"}}/> : null}
              {groups}
            </div>
          )
        }
      },
      {
        Header: "Actions",
        fixWidth: 80,
        Cell: cell => {
          return (
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              sx={{float: "right"}}
            >
              <Tooltip title="Éditer" arrow>
                <IconButton
                  aria-label="edit"
                  onClick={() => navigate(`/comptes/utilisateurs/edition/${cell.row.original.id}`)}
                  sx={{
                    color: "#ffa000"
                  }}
                  disabled={
                    !user.getIn(["rights", "users", "edit"]) ||
                    ((cell.row.original.roles.includes("ROLE_SUPER_ADMIN") || cell.row.original.roles.includes("ROLE_CARTEL")) && !user.getIn(["rights", "isSuperAdminOrCartel"]))
                  }
                >
                  <EditOutlinedIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Supprimer" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteItem(cell.row.original)}
                  sx={{
                    color: "#d32f2f"
                  }}
                  disabled={
                    !user.getIn(["rights", "users", "delete"]) ||
                    ((cell.row.original.roles.includes("ROLE_SUPER_ADMIN") || cell.row.original.roles.includes("ROLE_CARTEL")) && !user.getIn(["rights", "isSuperAdminOrCartel"]))
                  }
                >
                  <DeleteOutlinedIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          );
        }
      }
    ];
  }, [pageLoaded, user]);

  const fetchData = useCallback(params => {
    dispatch(userListUpdate(params));
  }, [dispatch]);

  return (
    <>
      <DeleteDialog
        onConfirm={handleConfirmDelete}
        onClose={deleteDialog.onClose}
        open={deleteDialog.open}
        title="Suppression de l'utilisateur"
        content={selectedItem ? `Êtes-vous sûr de vouloir supprimer l'utilisateur "${selectedItem.name}" ?` : ""}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{mb: 2}}>Liste des utilisateurs</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{marginTop: "4px", display: "flex", justifyContent: "flex-end"}}
          >
            <Typography key="1" color="inherit">
              Comptes
            </Typography>,
            <Typography key="2" color="text.primary">
              Utilisateurs
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{width: "100%"}}>
            <Paper variant="outlined" elevation={0} sx={{width: "100%"}}>
              <Grid container>
                <Grid item xs={12} sm="auto" order={{xs: 1, sm: 2}}
                      sx={{display: "flex", alignItems: "center", p: 2, pl: {sm: 0}, pb: {xs: 0, sm: 2}}}>
                  <Button
                    variant="contained"
                    color="add"
                    startIcon={user.getIn(["rights", "users", "add"]) ? <AddIcon/> : <LockOutlined/>}
                    onClick={() => navigate("/comptes/utilisateurs/ajout")}
                    disabled={!user.getIn(["rights", "users", "add"])}
                  >
                    <div style={{marginTop: "1px", marginBottom: "-1px"}}>Nouveau</div>
                  </Button>
                </Grid>
                <Grid item xs={12} sm sx={{p: 2}} order={{xs: 2, sm: 1}}>
                  <Formik
                    initialValues={{
                      search: ""
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                      return new Promise(resolve =>
                        setTimeout(() => {
                          handleSubmit(values);
                          setSubmitting(false);
                          resolve();
                        }, 200)
                      );
                    }}
                  >
                    {formikProps => (
                      <form>
                        <Grid container>
                          <AutoSave debounceMs={300} dirty={formikProps.dirty}/>
                          <Grid item xs={12}>
                            <FastField
                              component={TextType}
                              name="search"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon sx={{color: "action.active"}}/>
                                  </InputAdornment>)
                              }}
                              placeholder="Rechercher par nom..."
                            />
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Grid>
                <Grid item xs={12} order={{xs: 3}}>
                  <TableContainer
                    id="table-container-user-group-list"
                    columns={columns}
                    data={users ? users.get('members').toJS() : []}
                    pageCount={users ? users.get("pageCount") : 1}
                    count={users ? users.get("count") : 0}
                    customPageSize={20}
                    fetchData={fetchData}
                    filters={filters.toJS()}
                    loading={tableLoading}
                    defaultSort={{sortDirection: "desc", accessor: "updatedAt"}}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default UserList;
