import {combineReducers} from 'redux-immutable';

import app from './app/reducer';
import auth from './auth/reducer';
import rightsGroupList from '../_scenes/RightsGroupList/_store/reducer';
import rightsGroupForm from '../_scenes/RightsGroupForm/_store/reducer';
import userList from '../_scenes/UserList/_store/reducer';
import userForm from '../_scenes/UserForm/_store/reducer';
import productList from '../_scenes/ProductList/_store/reducer';
import productForm from '../_scenes/ProductForm/_store/reducer';
import productView from '../_scenes/ProductView/_store/reducer';
import serviceList from '../_scenes/ServiceList/_store/reducer';
import serviceForm from '../_scenes/ServiceForm/_store/reducer';
import serviceView from '../_scenes/ServiceView/_store/reducer';
import ticketList from '../_scenes/TicketList/_store/reducer';
import ticketForm from '../_scenes/TicketForm/_store/reducer';

export default combineReducers({
  app,
  auth,
  scenes: combineReducers({
    rightsGroupList,
    rightsGroupForm,
    userList,
    userForm,
    productList,
    productForm,
    productView,
    serviceList,
    serviceForm,
    serviceView,
    ticketList,
    ticketForm
  })
});