import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import Immutable from 'immutable';
import {ToastContainer} from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import yellow from '@mui/material/colors/yellow';
import red from '@mui/material/colors/red';
import 'moment/locale/fr';
import 'react-toastify/dist/ReactToastify.css';

import {tryAutoSignIn} from './_store/auth/actions';
import Routes from './_routes';
import './_locales/index';
import {appTrackLocation} from './_store/app/actions';
import {selectAuthIsTried, selectAuthWebService} from './_store/auth/selectors';
import {selectAppLoaded} from './_store/app/selectors';
import ConnexionLoading from './_components/ConnexionLoading/ConnexionLoading';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#673ab7"
    },
    add: {
      light: indigo[300],
      main: indigo[500],
      dark: indigo[700],
      contrastText: "#fff"
    },
    export: {
      light: deepPurple[300],
      main: deepPurple[500],
      dark: deepPurple[700],
      contrastText: "#fff"
    },
    delete: {
      light: red[500],
      main: red[700],
      dark: red[900],
      contrastText: "#fff"
    },
    action: {
      light: yellow[500],
      main: yellow[600],
      dark: yellow[700],
      contrastText: "rgba(0, 0, 0, 0.75)"
    },
    success: {
      main: "#2E7D32",
      contrastText: "#fff"
    },
    failed: {
      main: "#D32F2F",
      contrastText: "#fff"
    },
    waiting: {
      main: "#17a2b8",
      contrastText: "#fff"
    },
    processing: {
      main: "#ED6C02",
      contrastText: "#fff"
    },
    grey: {
      main: "#616161"
    },
    cancel: {
      light: "#fff",
      main: "#f2f2f2",
      dark: "#dfdfdf"
    }
  }
});

Immutable.Map.prototype.findIn = function(uri) {
  if (this.getIn(["members"])) {
    return this.getIn(["members", this.getIn(["indexByUri", uri])]);
  } else {
    return this.get("member");
  }
};

Immutable.Map.prototype.findInId = function(id) {
  if (this.getIn(["members"])) {
    return this.getIn(["members", this.getIn(["indexById", id])]);
  } else {
    return this.get("member");
  }
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTried = useSelector(selectAuthIsTried);
  const appLoaded = useSelector(selectAppLoaded);
  const webService = useSelector(selectAuthWebService);

  useEffect(() => {
    dispatch(appTrackLocation(location.pathname));
  }, [location]);

  useEffect(() => {
    dispatch(tryAutoSignIn());
    yup.setLocale({
      mixed: {
        default: 'Ce champs n\'est pas valide',
        required: 'Ce champs est requis',
        notNull: "Ce champs est requis"
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if(appLoaded && webService){
      document.title = webService.get("label")
    }
  }, [appLoaded, webService]);

  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
        {isTried ? (
          <>
            <ToastContainer theme="colored"/>
            <CssBaseline/>
            <Routes/>
          </>
        ) : null}
        {location.pathname !== "/connexion" && !isTried ? <ConnexionLoading/> : null}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
