import {createSelector} from 'reselect';

export const selectServiceListPageLoaded = state => state.getIn(["scenes", "serviceList", "pageLoaded"]);
export const selectServiceListServicesLoaded = state => state.getIn(["scenes", "serviceList", "servicesLoaded"]);
export const selectServiceListServices = state => state.getIn(["scenes", "serviceList", "resources", "services"]);

export const selectServiceList = createSelector([
  selectServiceListPageLoaded,
  selectServiceListServicesLoaded,
  selectServiceListServices
], (pageLoaded, servicesLoaded, services) => ({
  pageLoaded,
  servicesLoaded,
  services
}));