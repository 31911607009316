export const rolesManager = [{
  name: "Application Baliz",
  roles: [
    "ROLE_MANAGER_BALIZ_ALL",
    "ROLE_MANAGER_BALIZ_VIEW",
    "",
    "ROLE_MANAGER_BALIZ_EDIT",
    "",
    ""
  ]
}, {
  name: "Gestionnaire de données (DATA)",
  roles: [
    "ROLE_MANAGER_DATA_ALL",
    "ROLE_MANAGER_DATA_VIEW",
    "",
    "ROLE_MANAGER_DATA_EDIT",
    "",
    ""
  ]
}, {
  name: "Statistiques",
  roles: [
    "ROLE_MANAGER_ANALYTICS_ALL",
    "ROLE_MANAGER_ANALYTICS_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Application Funbox",
  roles: [
    "ROLE_MANAGER_FUNBOX_ALL",
    "ROLE_MANAGER_FUNBOX_VIEW",
    "",
    "ROLE_MANAGER_FUNBOX_EDIT",
    "",
    ""
  ]
}, {
  name: "Équipements",
  roles: [
    "ROLE_MANAGER_PRODUCTS_ALL",
    "ROLE_MANAGER_PRODUCTS_VIEW",
    "",
    "ROLE_MANAGER_PRODUCTS_EDIT",
    "",
    ""
  ]
}, {
  name: "Gestion des utilisateurs",
  roles: [
    "ROLE_MANAGER_USERS_ALL",
    "ROLE_MANAGER_USERS_VIEW",
    "ROLE_MANAGER_USERS_ADD",
    "ROLE_MANAGER_USERS_EDIT",
    "",
    "ROLE_MANAGER_USERS_DELETE"
  ]
}];

export const rolesBaliz = [{
  name: "Arborescence",
  roles: [
    "ROLE_BALIZ_TREE_STRUCTURE_ALL",
    "ROLE_BALIZ_TREE_STRUCTURE_VIEW",
    "ROLE_BALIZ_TREE_STRUCTURE_ADD",
    "ROLE_BALIZ_TREE_STRUCTURE_EDIT",
    "ROLE_BALIZ_TREE_STRUCTURE_MOVE",
    "ROLE_BALIZ_TREE_STRUCTURE_DELETE"
  ]
}, {
  name: "Écrans principaux",
  roles: [
    "ROLE_BALIZ_MAIN_SCREEN_SAVER_ALL",
    "ROLE_BALIZ_MAIN_SCREEN_SAVER_VIEW",
    "ROLE_BALIZ_MAIN_SCREEN_SAVER_ADD",
    "ROLE_BALIZ_MAIN_SCREEN_SAVER_EDIT",
    "",
    "ROLE_BALIZ_MAIN_SCREEN_SAVER_DELETE"
  ]
}, {
  name: "Écrans secondaires",
  roles: [
    "ROLE_BALIZ_SECOND_SCREEN_SAVER_ALL",
    "ROLE_BALIZ_SECOND_SCREEN_SAVER_VIEW",
    "ROLE_BALIZ_SECOND_SCREEN_SAVER_ADD",
    "ROLE_BALIZ_SECOND_SCREEN_SAVER_EDIT",
    "",
    "ROLE_BALIZ_SECOND_SCREEN_SAVER_DELETE"
  ]
}, {
  name: "Liens internet",
  roles: [
    "ROLE_BALIZ_WEB_LINKS_ALL",
    "ROLE_BALIZ_WEB_LINKS_VIEW",
    "ROLE_BALIZ_WEB_LINKS_ADD",
    "ROLE_BALIZ_WEB_LINKS_EDIT",
    "",
    "ROLE_BALIZ_WEB_LINKS_DELETE"
  ]
}, {
  name: "Personnalisation",
  roles: [
    "ROLE_BALIZ_CUSTOMIZATION_ALL",
    "ROLE_BALIZ_CUSTOMIZATION_VIEW",
    "ROLE_BALIZ_CUSTOMIZATION_ADD",
    "ROLE_BALIZ_CUSTOMIZATION_EDIT",
    "ROLE_BALIZ_CUSTOMIZATION_MOVE",
    "ROLE_BALIZ_CUSTOMIZATION_DELETE"
  ]
}, {
  name: "Traduction",
  roles: [
    "ROLE_BALIZ_TRANSLATION_ALL",
    "ROLE_BALIZ_TRANSLATION_VIEW",
    "ROLE_BALIZ_TRANSLATION_ADD",
    "ROLE_BALIZ_TRANSLATION_EDIT",
    "",
    "ROLE_BALIZ_TRANSLATION_DELETE"
  ]
}, {
  name: "Modules",
  roles: [
    "ROLE_BALIZ_MODULES_ALL",
    "ROLE_BALIZ_MODULES_VIEW",
    "ROLE_BALIZ_MODULES_ADD",
    "ROLE_BALIZ_MODULES_EDIT",
    "",
    "ROLE_BALIZ_MODULES_DELETE"
  ]
}, {
  name: "Équipements",
  group: true,
  arr: [{
    name: "Page d'accueil",
    roles: [
      "ROLE_BALIZ_PRODUCT_HOME_PAGE_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_HOME_PAGE_EDIT",
      "",
      ""
    ]
  }, {
    name: "Écran principal",
    roles: [
      "ROLE_BALIZ_PRODUCT_MAIN_SCREEN_SAVER_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_MAIN_SCREEN_SAVER_EDIT",
      "",
      ""
    ]
  }, {
    name: "Écran secondaire",
    roles: [
      "ROLE_BALIZ_PRODUCT_SECOND_SCREEN_SAVER_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_SECOND_SCREEN_SAVER_EDIT",
      "",
      ""
    ]
  }, {
    name: "Lien internet",
    roles: [
      "ROLE_BALIZ_PRODUCT_WEB_LINK_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_WEB_LINK_EDIT",
      "",
      ""
    ]
  }, {
    name: "Module d'impression",
    roles: [
      "ROLE_BALIZ_PRODUCT_PRINTING_MODULE_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_PRINTING_MODULE_EDIT",
      "",
      ""
    ]
  }, {
    name: "Territoire",
    roles: [
      "ROLE_BALIZ_PRODUCT_TERRITORY_ALL",
      "",
      "",
      "ROLE_BALIZ_PRODUCT_TERRITORY_EDIT",
      "",
      ""
    ]
  }]
}];

export const rolesFunbox = [{
  name: "Page \"Médiathèque\"",
  roles: [
    "ROLE_FUNBOX_MEDIALIBRARY_ALL",
    "ROLE_FUNBOX_MEDIALIBRARY_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Dossiers (Médiathèque)",
  roles: [
    "ROLE_FUNBOX_MEDIALIBRARY_FOLDERS_ALL",
    "",
    "ROLE_FUNBOX_MEDIALIBRARY_FOLDERS_ADD",
    "ROLE_FUNBOX_MEDIALIBRARY_FOLDERS_EDIT",
    "ROLE_FUNBOX_MEDIALIBRARY_FOLDERS_MOVE",
    "ROLE_FUNBOX_MEDIALIBRARY_FOLDERS_DELETE"
  ]
}, {
  name: "Fichiers",
  roles: [
    "ROLE_FUNBOX_MEDIALIBRARY_FILES_ALL",
    "",
    "ROLE_FUNBOX_MEDIALIBRARY_FILES_ADD",
    "ROLE_FUNBOX_MEDIALIBRARY_FILES_EDIT",
    "ROLE_FUNBOX_MEDIALIBRARY_FILES_MOVE",
    "ROLE_FUNBOX_MEDIALIBRARY_FILES_DELETE"
  ]
}, {
  name: "Page \"Modules\"",
  roles: [
    "ROLE_FUNBOX_MODULES_ALL",
    "ROLE_FUNBOX_MODULES_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Dossiers (Modules)",
  roles: [
    "ROLE_FUNBOX_MODULES_FOLDERS_ALL",
    "",
    "ROLE_FUNBOX_MODULES_FOLDERS_ADD",
    "ROLE_FUNBOX_MODULES_FOLDERS_EDIT",
    "ROLE_FUNBOX_MODULES_FOLDERS_MOVE",
    "ROLE_FUNBOX_MODULES_FOLDERS_DELETE"
  ]
}, {
  name: "Modules",
  roles: [
    "ROLE_FUNBOX_MODULES_MODULES_ALL",
    "",
    "ROLE_FUNBOX_MODULES_MODULES_ADD",
    "ROLE_FUNBOX_MODULES_MODULES_EDIT",
    "ROLE_FUNBOX_MODULES_MODULES_MOVE",
    "ROLE_FUNBOX_MODULES_MODULES_DELETE"
  ]
}, {
  name: "Page \"Mises en page\"",
  roles: [
    "ROLE_FUNBOX_PAGES_ALL",
    "ROLE_FUNBOX_PAGES_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Dossiers (Mises en page)",
  roles: [
    "ROLE_FUNBOX_PAGES_FOLDERS_ALL",
    "",
    "ROLE_FUNBOX_PAGES_FOLDERS_ADD",
    "ROLE_FUNBOX_PAGES_FOLDERS_EDIT",
    "ROLE_FUNBOX_PAGES_FOLDERS_MOVE",
    "ROLE_FUNBOX_PAGES_FOLDERS_DELETE"
  ]
}, {
  name: "Mises en page",
  roles: [
    "ROLE_FUNBOX_PAGES_PAGES_ALL",
    "",
    "ROLE_FUNBOX_PAGES_PAGES_ADD",
    "ROLE_FUNBOX_PAGES_PAGES_EDIT",
    "ROLE_FUNBOX_PAGES_PAGES_MOVE",
    "ROLE_FUNBOX_PAGES_PAGES_DELETE"
  ]
}, {
  name: "Page \"Programmes\"",
  roles: [
    "ROLE_FUNBOX_PROGRAMS_ALL",
    "ROLE_FUNBOX_PROGRAMS_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Dossiers (Programmes)",
  roles: [
    "ROLE_FUNBOX_PROGRAMS_FOLDERS_ALL",
    "",
    "ROLE_FUNBOX_PROGRAMS_FOLDERS_ADD",
    "ROLE_FUNBOX_PROGRAMS_FOLDERS_EDIT",
    "ROLE_FUNBOX_PROGRAMS_FOLDERS_MOVE",
    "ROLE_FUNBOX_PROGRAMS_FOLDERS_DELETE"
  ]
}, {
  name: "Programmes",
  roles: [
    "ROLE_FUNBOX_PROGRAMS_PROGRAMS_ALL",
    "",
    "ROLE_FUNBOX_PROGRAMS_PROGRAMS_ADD",
    "ROLE_FUNBOX_PROGRAMS_PROGRAMS_EDIT",
    "ROLE_FUNBOX_PROGRAMS_PROGRAMS_MOVE",
    "ROLE_FUNBOX_PROGRAMS_PROGRAMS_DELETE"
  ]
}, {
  name: "Page \"Écrans\"",
  roles: [
    "ROLE_FUNBOX_PRODUCTS_ALL",
    "ROLE_FUNBOX_PRODUCTS_VIEW",
    "",
    "ROLE_FUNBOX_PRODUCTS_EDIT",
    "",
    ""
  ]
}, {
  name: "Page \"Publication\"",
  roles: [
    "ROLE_FUNBOX_PUBLISH_ALL",
    "ROLE_FUNBOX_PUBLISH_VIEW",
    "",
    "",
    "",
    ""
  ]
}, {
  name: "Page \"Planification\"",
  roles: [
    "ROLE_FUNBOX_PLANNING_ALL",
    "ROLE_FUNBOX_PLANNING_VIEW",
    "ROLE_FUNBOX_PLANNING_ADD",
    "ROLE_FUNBOX_PLANNING_EDIT",
    "",
    "ROLE_FUNBOX_PLANNING_DELETE"
  ]
}, {
  name: "Page \"Menus interactifs\"",
  roles: [
    "ROLE_FUNBOX_INTERACTIVE_MENUS_ALL",
    "ROLE_FUNBOX_INTERACTIVE_MENUS_VIEW",
    "ROLE_FUNBOX_INTERACTIVE_MENUS_ADD",
    "ROLE_FUNBOX_INTERACTIVE_MENUS_EDIT",
    "",
    "ROLE_FUNBOX_INTERACTIVE_MENUS_DELETE"
  ]
}];

export const rolesData = [{
  name: "Points d'intérêt",
  roles: [
    "ROLE_DATA_POINT_OF_INTEREST_ALL",
    "ROLE_DATA_POINT_OF_INTEREST_VIEW",
    "ROLE_DATA_POINT_OF_INTEREST_ADD",
    "ROLE_DATA_POINT_OF_INTEREST_EDIT",
    "",
    "ROLE_DATA_POINT_OF_INTEREST_DELETE"
  ]
}, {
  name: "Disponibilités des hébergements",
  roles: [
    "ROLE_DATA_AVAILABILITIES_ALL",
    "",
    "",
    "ROLE_DATA_AVAILABILITIES_EDIT",
    "",
    ""
  ]
}, {
  name: "Documents",
  roles: [
    "ROLE_DATA_DOCUMENTS_ALL",
    "ROLE_DATA_DOCUMENTS_VIEW",
    "ROLE_DATA_DOCUMENTS_ADD",
    "ROLE_DATA_DOCUMENTS_EDIT",
    "",
    "ROLE_DATA_DOCUMENTS_DELETE"
  ]
}, {
  name: "Mes documents",
  roles: [
    "ROLE_DATA_MY_DOCUMENTS_ALL",
    "ROLE_DATA_MY_DOCUMENTS_VIEW",
    "",
    "ROLE_DATA_MY_DOCUMENTS_EDIT",
    "",
    "ROLE_DATA_MY_DOCUMENTS_DELETE"
  ]
}, {
  name: "Catégories",
  roles: [
    "ROLE_DATA_CATEGORIES_ALL",
    "ROLE_DATA_CATEGORIES_VIEW",
    "ROLE_DATA_CATEGORIES_ADD",
    "ROLE_DATA_CATEGORIES_EDIT",
    "",
    "ROLE_DATA_CATEGORIES_DELETE"
  ]
}, {
  name: "Types",
  roles: [
    "ROLE_DATA_TYPES_ALL",
    "ROLE_DATA_TYPES_VIEW",
    "ROLE_DATA_TYPES_ADD",
    "ROLE_DATA_TYPES_EDIT",
    "",
    "ROLE_DATA_TYPES_DELETE"
  ]
}, {
  name: "Sélections",
  roles: [
    "ROLE_DATA_SELECTIONS_ALL",
    "ROLE_DATA_SELECTIONS_VIEW",
    "ROLE_DATA_SELECTIONS_ADD",
    "ROLE_DATA_SELECTIONS_EDIT",
    "",
    "ROLE_DATA_SELECTIONS_DELETE"
  ]
}, {
  name: "Configurations",
  roles: [
    "ROLE_DATA_CONFIGURATIONS_ALL",
    "ROLE_DATA_CONFIGURATIONS_VIEW",
    "ROLE_DATA_CONFIGURATIONS_ADD",
    "ROLE_DATA_CONFIGURATIONS_EDIT",
    "",
    "ROLE_DATA_CONFIGURATIONS_DELETE"
  ]
}, {
  name: "Bouton \"Valider et publier\"",
  roles: [
    "ROLE_DATA_VALID_AND_PUBLISH_ALL",
    "ROLE_DATA_VALID_AND_PUBLISH_VIEW",
    "",
    "",
    "",
    ""
  ]
}];